import { Fragment, useEffect, useState } from 'react'

import { BatteryForm, Button, IconClose, IconPlus, Input } from '@/components'
import { Company, Product, Warranty } from '@/models'
import { uuid } from '@/services'

type BatteriesFormProps = {
    form: any
    errors?: any
    onChange: (e: any) => void
    company: Company
    warranty?: Warranty
}
const BatteriesForm = ({ form, errors = {}, company, warranty, ...props }: BatteriesFormProps) => {
    const [batteryProducts, setBatteryProducts] = useState<Product[]>([])
    const [batteriesCount, setBatteriesCount] = useState(form.batteries.length)

    const handleRemove = async (index: number) => {
        const batteries = [...form.batteries]
        batteries.splice(index, 1)
        await props.onChange({ target: { name: 'batteries', value: batteries } })
        setBatteriesCount(batteries.length)
    }

    const fetchProducts = async () => {
        const res = await Product.onlyBatteries()
        setBatteryProducts(res)
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    useEffect(() => {
        const batteries = [...form.batteries]
        const count = parseInt(batteriesCount)
        do {
            if (batteries.length > count) {
                batteries.pop()
            } else if (batteries.length < count) {
                batteries.push({
                    id: uuid(),
                    product_id: '',
                    size_kw: ''
                })
            }
        } while (batteries.length !== count)
        if (batteries.length !== form.batteries.length) {
            props.onChange({ target: { name: 'batteries', value: batteries } })
        }
    }, [batteriesCount])

    useEffect(() => {
        if (form.batteries.length !== batteriesCount) {
            setBatteriesCount(form.batteries.length)
        }
    }, [form.batteries.length])

    const handleChange = (e: any, index: number) => {
        const batteries = [...form.batteries]
        batteries[index] = { ...batteries[index] }
        batteries[index][e.target.name] = e.target.value
        batteries[index].product = batteryProducts
            .find((product: Product) => product.id?.toString() === batteries[index].product_id?.toString())
        props.onChange({ target: { name: 'batteries', value: batteries } })
    }

    const handleCountChange = (e: any) => {
        const value = parseInt(e.target.value)
        if (!isNaN(value) && value > 0) {
            setBatteriesCount(e.target.value)
        } else {
            setBatteriesCount(1)
        }
    }

    const getErrors = (index: number) => {
        const obj: any = {}
        const keyStart = `batteries.${index}.`
        Object.keys(errors).forEach(key => {
            const fieldName = key.replace(keyStart, '')
            if (key.startsWith(keyStart)) {
                obj[fieldName] = errors[key].replace(key, fieldName).replaceAll('_', ' ')
            }
        })
        return obj
    }

    return <>
        {form.batteries.length
            ? <div className="lg:col-span-2 flex items-center">
                <span className="font-semibold">
                    {company.batteryEligibility.title} Warranty
                </span>
                <Button design="btn-link" hasError className="ml-4" type="button" onClick={() => setBatteriesCount(0)} name="battery_qty">
                    Remove Battery Warranty Add-On
                </Button>
            </div>
            : <div className="lg:col-span-2 flex flex-col md:flex-row items-start md:items-center">
                Does this system have a battery?
                <Button className="md:ml-4 mt-4 md:mt-0 flex items-center gap-2" design="btn-secondary" type="button" onClick={() => setBatteriesCount(1)} name="battery_qty" data-test="add-battery-button">
                    <IconPlus/> Add a Battery Warranty
                </Button>
            </div>}

        {!!form.batteries.length && <div className="lg:col-span-2">
            <div className="grid lg:grid-cols-12 gap-6 grow">
                {form.batteries.map((item: any, index: number) => <Fragment key={item.id}>
                    {index
                        ? <div className="flex col-span-2 justify-end items-end">
                            <Button square design="btn-secondary" hasError type="button" onClick={() => handleRemove(index)}>
                                <IconClose/>
                            </Button>
                        </div>
                        : <Input
                            id="battery_qty"
                            name="battery_qty"
                            type="number"
                            label="Number of Batteries*"
                            placeholder="Number of Batteries"
                            className="col-span-2"
                            onChange={handleCountChange}
                            value={batteriesCount}
                        />}
                    {!!batteryProducts.length && <BatteryForm
                        form={item}
                        onChange={(e: any) => handleChange(e, index)}
                        inputClassName="lg:col-span-5"
                        labelPrefix={`Battery ${1 + index}:`}
                        products={batteryProducts}
                        errors={getErrors(index)}
                    />}
                </Fragment>)}
            </div>
        </div>}
    </>
}

export default BatteriesForm
