import { ReactEventHandler, ReactNode, useEffect, useRef } from 'react'

import { IconCheck, IconMinus } from '@/components'

type CheckBoxProps = {
    id?: string,
    label?: ReactNode,
    className?: string,
    disabled?: boolean,
    checked?: boolean,
    defaultChecked?: boolean,
    value?: string | number,
    defaultValue?: string | number,
    name?: string,
    indeterminate?: boolean,
    onChange?: ReactEventHandler,
    onClick?: ReactEventHandler,
    'data-test'?: string
}

const Checkbox = ({
    label,
    className,
    disabled = false,
    checked,
    indeterminate = false,
    'data-test': dataTest = 'checkbox',
    ...props
}: CheckBoxProps) => {
    const inputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.indeterminate = indeterminate && !checked
        }
    }, [indeterminate])

    return <label data-test={dataTest} className={`checkbox ${className} ${disabled ? 'disabled' : ''} ${checked ? 'checked' : ''} ${indeterminate ? 'indeterminate' : ''}`}>
        {label && <span data-test={`${dataTest}-label`} className="ml-2">{label}</span>}
        <input
            type="checkbox"
            disabled={disabled}
            checked={checked}
            ref={inputRef}
            data-test={`${dataTest}-checkbox`}
            {...props}
            onClick={e => {
                if (props.onClick) {
                    e.stopPropagation()
                    props.onClick(e)
                }
            }}
        />
        <IconCheck size="sm" className="checkbox-icon"/>
        <IconMinus size="sm" className="minus-icon"/>
    </label>
}

export default Checkbox
