import { ReactNode } from 'react'

type CardProps = {
    children: ReactNode,
    className?: string
    size?: string
    type?: 'warning' | 'error' | 'success'
}

const Card = ({ className, children, size, type, ...props }: CardProps) => <div className={`card ${className || ''} ${size || ''} ${type || ''}`} {...props}>
    {children}
</div>

export default Card
