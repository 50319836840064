import { useMemo, useState } from 'react'
import { createSearchParams, Link, useLocation } from 'react-router-dom'

import { Popup, StatusGuideExpansionSection, Button } from '@/components'
import { useQuery } from '@/hooks'
import { SystemStatusCategory, InverterManufacturer } from '@/models'

const StatusGuidePopup = () => {
    const [showPopup, setShowPopup] = useState(false)
    const location = useLocation()
    const query = useQuery()

    const getMonitoringIndexPage = (filters: any) => {
        if (location.pathname === '/monitoring') {
            return `/monitoring?${createSearchParams({ ...query, ...filters })}`
        }
        return `/monitoring?${createSearchParams(filters)}`
    }

    const systemCategories = useMemo(() => SystemStatusCategory.all, [])

    return <>
        <span role="button" tabIndex={-1}>
            <Button design="btn-link" onClick={() => setShowPopup(true)} data-test="monioring-index-status-guide-button">
                <span className="text-sm text-primary-800 font-normal p-4 md:p-0">
                    View Status Guide
                </span>
            </Button>
        </span>
        <Popup
            open={showPopup}
            onClose={() => setShowPopup(false)}
            className="min-w-[90%] lg:min-w-[718px]"
        >
            <div className="p-4 text-sm">
                <div className="flex items-center gap-3 mb-6">
                    <p className="font-semibold text-xl font-display" data-test="invoice-history-information-header">Site Status Guide</p>
                </div>
                <div className="text-sm font-body text-gray-600 text-xs">
                    <p>
                        Solar Insure reports system or component level errors based on the inverter manufacturer&apos;s
                        monitoring data. These issues help identify potential problems in your solar system.
                    </p>
                </div>
                <div className="flex items-center gap-4 mt-6">
                    <p className="font-semibold text-gray-600 text-xs">Supported Inverter Manufacturers</p>
                    <div className="self-end">
                        <div className="flex">
                            <Link to={getMonitoringIndexPage({ manufacturer: InverterManufacturer.enphase.key })} className="p-1.5 rounded-full border border-gray-300 bg-white translate-x-3 z-[1] hover:z-10">
                                {InverterManufacturer.enphase.getLogo()}
                            </Link>
                            <Link to={getMonitoringIndexPage({ manufacturer: InverterManufacturer.solaredge.key })} className="p-1.5 rounded-full border border-gray-300 bg-white translate-x-1.5 z-[2] hover:z-10">
                                {InverterManufacturer.solaredge.getLogo()}
                            </Link>
                            <Link to={getMonitoringIndexPage({ manufacturer: InverterManufacturer.generac.key })} className="p-1.5 rounded-full border border-gray-300 bg-white z-[3] hover:z-10">
                                {InverterManufacturer.generac.getLogo()}
                            </Link>
                        </div>
                    </div>
                </div>

                {systemCategories.map(item =>
                    <StatusGuideExpansionSection
                        key={item.value}
                        statusCategory={item}
                    />)}
            </div>
        </Popup></>
}

export default StatusGuidePopup
