import { ReactNode, useEffect, useState } from 'react'

import CompanyActivityItem from './CompanyActivityItem'
import { IconClose, IconTrophy02, IconPause, IconIntegrations, Button, Badge } from '@/components'
import { api } from '@/services'

type CompanyActivityProps = {
    className?: string
    onChange: any
    processing: boolean
}
const CompanyActivity = ({ className, processing, ...props }: CompanyActivityProps) => {
    const [data, setData] = useState<any>({
        inactive_companies: { total: 0, companies: [] },
        inactive_companies_last_month: { total: 0, companies: [] },
        top_companies: { total: 0, companies: [] },
        company_integrations: { total: 0, company_integrations: [] }
    })
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const fetchInactiveCompanies = async (controller: AbortController) => {
        const startDate = new Date().startOf('month').toISODate()
        const endDate = new Date().toISODate()
        const res = await api.dashboard.inactiveCompanies({
            limit: 3,
            start_date: startDate,
            end_date: endDate
        }, { signal: controller.signal })
        setData((data: any) => ({ ...data, inactive_companies: res }))
    }
    const fetchInactiveLast45Companies = async (controller: AbortController) => {
        const startDate = new Date()
            .subtract('month', 1)
            .startOf('month')
            .toISODate()
        const endDate = new Date()
            .subtract('month', 1)
            .endOf('month')
            .toISODate()
        const res = await api.dashboard.inactiveCompanies({
            limit: 3,
            start_date: startDate,
            end_date: endDate
        }, { signal: controller.signal })
        setData((data: any) => ({ ...data, inactive_companies_last_month: res }))
    }
    const fetchTopCompanies = async (controller: AbortController) => {
        const startDate = new Date().startOf('month').toISODate()
        const endDate = new Date().toISODate()
        const res = await api.dashboard.topCompanies({
            limit: 3,
            start_date: startDate,
            end_date: endDate
        }, { signal: controller.signal })
        setData((data: any) => ({ ...data, top_companies: res }))
    }
    const fetchCompanyIntegrations = async (controller: AbortController) => {
        const res = await api.dashboard.companyIntegrations({ limit: 3 }, { signal: controller.signal })
        setData((data: any) => ({ ...data, company_integrations: res }))
    }

    const fetchData = async () => {
        try {
            const controller = new AbortController
            await setAbortController(controller)
            await Promise.all([
                fetchInactiveCompanies(controller),
                fetchInactiveLast45Companies(controller),
                fetchTopCompanies(controller),
                fetchCompanyIntegrations(controller)
            ])
        } finally {
            props.onChange('adminBar')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    useEffect(() => () => {
        abortController?.abort('canceled')
    }, [abortController])

    const getIconBadge = (icon: ReactNode, color: string) => {
        let className1 = 'bg-gray-50'
        let className2 = 'bg-gray-10'
        if (color === 'primary') className1 = 'bg-primary-50'
        if (color === 'success') className1 = 'bg-success-50'
        if (color === 'orange') className1 = 'bg-orange-50'
        if (color === 'primary') className2 = 'bg-primary-100'
        if (color === 'success') className2 = 'bg-success-100'
        if (color === 'orange') className2 = 'bg-orange-100'
        return <div className={`w-12 h-12 p-2 rounded-full flex justify-center items-center fill-gray-600 stroke-gray-600 ${className1}`}>
            <div className={`w-full h-full rounded-full flex justify-center items-center ${className2}`}>
                {icon}
            </div>
        </div>
    }

    return <div className={`flex flex-col gap-5 ${className ? className : ''}`}>
        <h3 className="flex justify-between items-center text-xl" data-test="dashboard-company-activity-title">
            Contractors Activity
        </h3>

        <CompanyActivityItem
            title={<>
                <span className="font-semibold text-lg 2xl:text-xl">{data.inactive_companies.total}</span> Zero-Warranty Contractors This Month
            </>}
            icon={getIconBadge(<IconClose/>, 'gray')}
            className={processing ? 'animate-pulse' : ''}
            onDownload={() => api.dashboard.downloadInactiveCompanies({ start_date: new Date().startOf('month').toISODate(), end_date: new Date().toISODate() })}
            data-test="dashboard-company-activity-inactive-this-month"
            content={<ul className="flex flex-col gap-6">
                {data.inactive_companies.companies.map((item: any) => {
                    const [first, last] = item.name.split(' ')
                    return <li key={item.id} className="flex gap-3 items-center">
                        <Button square design="btn-secondary-gray">
                            <div className="uppercase w-5 h-5 rounded-full bg-primary-100 text-primary-600 flex justify-center items-center">
                                <span className="text-xs">{first.charAt()}{last?.charAt()}</span>
                            </div>
                        </Button>

                        <div className="flex flex-col text-xs">
                            <p className="flex gap-2 items-center">
                                <span className="text-primary-600 font-semibold">{item.name}</span>
                                <Badge className="bg-orange-50 text-orange-700">
                                    {item.last_submission && new Date(item.last_submission).isValid() ? `${new Date(item.last_submission).humanize()?.replace(/\bago\b/g, '')} inactive` : 'Never'}
                                </Badge>
                            </p>
                            <p className="text-gray-500">
                                Last Submission: {item.last_submission && new Date(item.last_submission).isValid() ? new Date(item.last_submission).format() : 'Never'}
                            </p>
                        </div>
                    </li>
                })}
            </ul>}
        />

        <CompanyActivityItem
            title={<>
                <span className="font-semibold text-xl">{data.inactive_companies_last_month.total}</span> Inactive Contractors Last Month
            </>}
            icon={getIconBadge(<IconPause/>, 'primary')}
            className={processing ? 'animate-pulse' : ''}
            onDownload={() => api.dashboard.downloadInactiveCompanies({
                start_date: new Date()
                    .subtract('month', 1)
                    .startOf('month')
                    .toISODate(),
                end_date: new Date()
                    .subtract('month', 1)
                    .endOf('month')
                    .toISODate()
            })}
            data-test="dashboard-company-activity-inactive-last-month"
            content={<ul className="flex flex-col gap-6">
                {data.inactive_companies_last_month.companies.map((item: any) => {
                    const [first, last] = item.name.split(' ')
                    return <li key={item.id} className="flex gap-3 items-center">
                        <Button square design="btn-secondary-gray">
                            <div className="uppercase w-5 h-5 rounded-full bg-primary-100 text-primary-600 flex justify-center items-center">
                                <span className="text-xs">{first.charAt()}{last?.charAt()}</span>
                            </div>
                        </Button>

                        <div className="flex flex-col text-xs">
                            <p className="flex gap-2 items-center">
                                <span className="text-primary-600 font-semibold">{item.name}</span>
                                <Badge className="bg-orange-50 text-orange-700">
                                    {item.last_submission && new Date(item.last_submission).isValid() ? `${new Date(item.last_submission).humanize()?.replace(/\bago\b/g, '')} inactive` : 'Never'}
                                </Badge>
                            </p>
                            <p className="text-gray-500">
                                Last Submission: {item.last_submission && new Date(item.last_submission).isValid() ? new Date(item.last_submission).format() : 'Never'}
                            </p>
                        </div>
                    </li>
                })}
            </ul>}
        />

        <CompanyActivityItem
            title={<><span className="font-semibold text-xl">{data.top_companies.total}</span> Top Contractors</>}
            icon={getIconBadge(<IconTrophy02/>, 'success')}
            className={processing ? 'animate-pulse' : ''}
            onDownload={() => api.dashboard.downloadTopCompanies({ limit: 10000 })}
            data-test="dashboard-company-activity-top-companies"
            content={<ul className="flex flex-col gap-6">
                {data.top_companies.companies.map((item: any) => {
                    const [first, last] = item.name.split(' ')
                    return <li key={item.id} className="flex gap-3 items-center">
                        <Button square design="btn-secondary-gray">
                            <div className="uppercase w-5 h-5 rounded-full bg-primary-100 text-primary-600 flex justify-center items-center">
                                <span className="text-xs">{first.charAt()}{last?.charAt()}</span>
                            </div>
                        </Button>

                        <div className="flex flex-col text-xs">
                            <p className="flex gap-2 items-center">
                                <span className="text-primary-600 font-semibold">{item.name}</span>
                                <Badge className="bg-success-50 text-success-700">
                                    {(item.sales || 0).money()}
                                </Badge>
                            </p>
                            <p className="text-gray-500">
                                Warranties Sold: {(item.warranties_sold || 0).format()}
                            </p>
                        </div>
                    </li>
                })}
            </ul>}
        />

        <CompanyActivityItem
            title={<>
                <span className="font-semibold text-xl">{data.company_integrations.total}</span> Contractors Integrations</>}
            icon={getIconBadge(<IconIntegrations/>, 'orange')}
            className={processing ? 'animate-pulse' : ''}
            onDownload={() => api.dashboard.downloadCompanyIntegrations()}
            data-test="dashboard-company-activity-company-integrations"
            content={<ul className="flex flex-col gap-6">
                {data.company_integrations.company_integrations.map((item: any) => {
                    const [first, last] = item.name.split(' ')
                    return <li key={item.id} className="flex gap-3 items-center">
                        <Button square design="btn-secondary-gray">
                            <div className="uppercase w-5 h-5 rounded-full bg-primary-100 text-primary-600 flex justify-center items-center">
                                <span className="text-xs">{first.charAt()}{last?.charAt()}</span>
                            </div>
                        </Button>

                        <div className="flex flex-col text-xs">
                            <p className="flex gap-2 items-center">
                                <span className="text-primary-600 font-semibold">{item.name}</span>
                                <Badge className="bg-success-50 text-success-700">
                                    {new Date(item.last_added_integration).isValid() ? new Date(item.last_added_integration).format() : '-'}
                                </Badge>
                            </p>
                            <p className="text-gray-500 gap-1 flex flex-col">
                                {item.has_enphase_integration && <span>Enlighten Verified!</span>}
                                {item.has_solaredge_integration && <span>SolarEdge Verified!</span>}
                            </p>
                        </div>
                    </li>
                })}
            </ul>}
        />
    </div>
}

export default CompanyActivity
