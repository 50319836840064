export const WARRANTY_COLUMNS = [
    {
        label: 'First name',
        value: 'first_name',
        index: 0,
        show: true
    },
    {
        label: 'Last name',
        value: 'last_name',
        index: 1,
        show: true
    },
    {
        label: 'Full name',
        value: 'full_name',
        index: 0,
        show: false
    },
    {
        label: 'Phone',
        value: 'phone',
        index: 2,
        show: false
    },
    {
        label: 'Email',
        value: 'email',
        index: 3,
        show: true,
        readonly: true
    },
    {
        label: 'Street address',
        value: 'street_address',
        index: 4,
        show: true,
        readonly: true
    },
    {
        label: 'City',
        value: 'city',
        index: 5,
        show: true,
        readonly: true
    },
    {
        label: 'State',
        value: 'state',
        index: 6,
        show: true,
        readonly: true
    },
    {
        label: 'ZIP',
        value: 'zip',
        index: 7,
        show: true,
        readonly: true
    },
    {
        label: 'Panel Model',
        value: 'panel_name',
        index: 8,
        show: true,
        readonly: true
    },
    {
        label: 'Inverter Model',
        value: 'inverter_name',
        index: 9,
        show: true,
        readonly: true
    },
    {
        label: 'Number of Panels',
        value: 'num_panels',
        index: 10,
        show: true,
        readonly: true
    },
    {
        label: 'System Size (kW)',
        value: 'size_kw',
        index: 11,
        show: true,
        readonly: true
    },
    {
        label: 'Installation Date',
        value: 'install_date',
        index: 12,
        show: true,
        readonly: true
    },
    {
        label: 'Submission Date',
        value: 'submission_date',
        index: 0,
        show: false,
        admin: true
    },
    {
        label: 'Number of Microinverters/Optimizers',
        value: 'num_microinverters',
        index: 13,
        show: true,
        admin: false
    },
    {
        label: 'System ID',
        value: 'system_id',
        index: 14,
        show: true,
        admin: false
    }
]
