import { ReactElement } from 'react'

import { IconBatteryCharging, IconSun } from '@/components'
import { IconProps } from '@/components/icons/Icon'

export enum WarrantyProductTypeEnum {
    POWER_PRODUCTION_ONLY = 'power_production_only',
    PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE = 'production_with_20_year_separate_storage',
    PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE = 'production_with_30_year_separate_storage'
}

export const WARRANTY_PRODUCT_TYPES: {
    key: WarrantyProductTypeEnum
    title: string
    color: 'warning' | 'purple' | 'success'
    IconComponent: (props: IconProps) => ReactElement
}[] = [
    {
        key: WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY,
        title: '30-Year Solar Warranty',
        color: 'warning',
        IconComponent: IconSun
    },
    {
        key: WarrantyProductTypeEnum.PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE,
        title: '20-Year Battery Warranty',
        color: 'purple',
        IconComponent: IconBatteryCharging
    },
    {
        key: WarrantyProductTypeEnum.PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE,
        title: '30-Year Battery Warranty',
        color: 'success',
        IconComponent: IconBatteryCharging
    }
]
