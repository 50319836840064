import { useEffect, useMemo, useRef, useState } from 'react'

import {
    Button,
    Dropdown,
    IconChevronDown,
    IconDownload,
    IconFileDownload02,
    IconMail,
    Tooltip,
    IconClose,
    WarrantyIndexReinstatementButtons,
    WarrantyIndexCancellationButtons,
    IconFlipBackward,
    WarrantyBulkCancelReinstatePopup
} from '@/components'
import { Filters } from '@/containers'
import { WarrantyStatusEnum } from '@/enums'
import { useAuth, useQuery, useAnalytics } from '@/hooks'
import { Warranty } from '@/models'
import { api } from '@/services'
import { JobsBatchType } from '@/types'

type WarrantyIndexButtonsProps = {
    warranties: Warranty[]
    selectedRows: any[] | 'all'
    excludedRows: any[]
    meta?: any
    onChange: () => void
    filters: Filters
    warrantiesByContractors?: boolean
    'data-test'?: string
}

const WarrantyIndexButtons = ({ warranties, selectedRows, excludedRows, warrantiesByContractors = false, 'data-test': dataTest, ...props }: WarrantyIndexButtonsProps) => {
    const { trackEvent } = useAnalytics()

    const query = useQuery()
    const [processing, setProcessing] = useState<boolean>(false)
    const auth = useAuth()
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
    const jobBatchRef = useRef<JobsBatchType | null>(null)

    const trackHoverEvent = (action: string, label: string) => {
        trackEvent(`hover_${action}`, 'User Interaction', `${label} Hover`)
    }

    const trackClickEvent = (action: string, label: string) => {
        trackEvent(`click_${action}`, 'User Interaction', `${label} Click`)
    }

    const handleExport = async (format: 'csv' | 'json') => {
        trackClickEvent('export_warranty', 'Export Warranty')

        return Warranty.export(selectedRows === 'all'
            ? {
                select_all: true,
                warranties_by_contractors: warrantiesByContractors,
                excluded_ids: excludedRows.map(({ id }) => id),
                ...query,
                format
            }
            : {
                ids: selectedRows.map(({ id }) => id),
                warranties_by_contractors: warrantiesByContractors,
                format
            }
        )
    }

    const isDownloadButtonDisabled = useMemo(() =>
        !selectedRows.length ||
        selectedRows.length > 20 ||
        (selectedRows === 'all' && (props.meta?.total - excludedRows?.length > 20)), [selectedRows, props.meta, excludedRows])

    const selectedWarranties = useMemo(() => selectedRows === 'all'
        ? warranties
        : selectedRows.reduce((acc: Warranty[], item) => {
            const warranty = warranties.find(({ id }) => id === item.id)
            return warranty ? [...acc, warranty] : acc
        }, []), [selectedRows, warranties])

    const isFiltersApplied = useMemo(() => !!(
        props.filters.company_id ||
        props.filters?.end_date ||
        props.filters?.start_date ||
        props.filters?.state ||
        props.filters?.type ||
        query.search
    ), [props.filters, query.search])

    const handleSend = async () => {
        trackClickEvent('email_warranty', 'Email Warranty')
        setProcessing(true)
        try {
            await Warranty.send(selectedWarranties.map(({ id }) => id))
        } finally {
            setProcessing(false)
        }
    }

    const handleDownload = () => {
        trackClickEvent('download_warranties', 'Download Warranty')

        return Warranty.downloadMany(selectedRows === 'all'
            ? {
                ids: warranties.filter(({ id }) => !excludedRows.some(row => row.id === id)).map(({ id }) => id),
                warranties_by_contractors: warrantiesByContractors
            }
            : { ids: selectedRows.map(({ id }) => id), warranties_by_contractors: warrantiesByContractors }
        )
    }

    const pingJobBatch = async () => {
        if (jobBatchRef.current) {
            const { data } = await api.jobs.progress(jobBatchRef.current.id)
            if (data.percentage > jobBatchRef.current.percentage) {
                props.onChange()
            }
            if (data.percentage === 100 && intervalRef.current) {
                clearInterval(intervalRef.current)
            } else {
                jobBatchRef.current = data
            }
        }
    }

    const handlePing = (data: JobsBatchType) => {
        jobBatchRef.current = data
        intervalRef.current = setInterval(() => pingJobBatch(), 3000)
    }

    useEffect(() => () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
    }, [])

    const getCancelReinstateButtons = () => {
        let isAllCancelledOrReinstateRequested = false
        let isAllNotCancelled = false

        if (selectedRows === 'all') {
            const { statuses } = query

            const activeStatuses = [
                WarrantyStatusEnum.EDIT_REQUESTED,
                WarrantyStatusEnum.ACTIVE,
                WarrantyStatusEnum.POTENTIAL_DUPLICATE,
                WarrantyStatusEnum.CANCELLATION_REQUESTED
            ]
            const inactiveStatuses = [
                WarrantyStatusEnum.REINSTATEMENT_REQUESTED,
                WarrantyStatusEnum.CANCELED
            ]

            isAllNotCancelled = !statuses?.length ? true
                : statuses.every((status: WarrantyStatusEnum) => activeStatuses.includes(status))
            isAllCancelledOrReinstateRequested = !statuses?.length ? false : statuses.every(
                (status: WarrantyStatusEnum) => inactiveStatuses.includes(status))
        } else {
            const selectedWarranties = warranties.filter((item: Warranty) =>
                selectedRows.map(row => row.id).includes(item.id)
            )

            isAllCancelledOrReinstateRequested = selectedWarranties
                .every((item: Warranty) => item?.isCanceled || item?.isReinstatementRequested)

            isAllNotCancelled = selectedWarranties
                .every((item: Warranty) => !item?.isCanceled)
        }

        const showSingleActionButton = (selectedRows !== 'all' && selectedRows.length === 1) || (selectedRows === 'all' && props.meta?.total === 1)
        const showBulkActionButtons = warranties.length && (selectedRows === 'all' ? isFiltersApplied : true) && auth.user?.isAdminOrStaff && (isAllCancelledOrReinstateRequested || isAllNotCancelled) && (selectedRows.length || selectedRows === 'all')

        if (showSingleActionButton) {
            const selectedWarranty = warranties.find(
                (warranty: Warranty) => warranty.id === selectedRows[0].id) || warranties[0]

            return selectedWarranty.isReinstatementRequested || selectedWarranty.isCanceled
                ? <WarrantyIndexReinstatementButtons
                    warranty={selectedWarranty}
                    onChange={props.onChange}
                />
                : <WarrantyIndexCancellationButtons
                    warranty={selectedWarranty}
                    onChange={props.onChange}
                />
        } else if (showBulkActionButtons) {
            return <WarrantyBulkCancelReinstatePopup
                selectedWarranties={selectedWarranties}
                excludedRows={excludedRows}
                selectedRows={selectedRows}
                onChange={props.onChange}
                onPingJob={handlePing}
                filters={props.filters}
                warrantiesByContractors={warrantiesByContractors}
            >
                <Tooltip content={isAllCancelledOrReinstateRequested ? 'Reinstate Warranties' : 'Cancel Warranties'}>
                    {isAllCancelledOrReinstateRequested
                        ? <Button square hasError design="btn-secondary-gray" size="btn-lg"><IconFlipBackward/></Button>
                        : <Button square hasError design="btn-secondary-gray" size="btn-lg"><IconClose/></Button>}
                </Tooltip>
            </WarrantyBulkCancelReinstatePopup>
        }
        return <Button square hasError design="btn-secondary-gray" size="btn-lg" disabled>
            <IconClose/>
        </Button>
    }

    return <>
        {(auth.user?.isAdminOrStaff || auth.user?.isContractor) && getCancelReinstateButtons()}
        <Dropdown
            disabled={!selectedRows.length}
            button={<Tooltip content="Export" disabled={!selectedRows.length}>
                <Button design="btn-secondary" size="btn-lg" disabled={!selectedRows.length} data-test={`${dataTest}-export-button`}>
                    <IconFileDownload02/>
                    <IconChevronDown className="ml-2"/>
                </Button>
            </Tooltip>}
        >
            <div className="p-2">
                <Button design="btn-link-gray" className="w-full whitespace-nowrap" onClick={() => handleExport('csv')} onMouseOver={() => trackHoverEvent('export_warranty', 'Export Warranty')} data-test={`${dataTest}-export-csv`}>
                    Export CSV
                </Button>
                <Button design="btn-link-gray" className="w-full whitespace-nowrap mt-2" onClick={() => handleExport('json')} data-test={`${dataTest}-export-json`}>
                    Export JSON
                </Button>
            </div>
        </Dropdown>

        {(auth.user?.isAdminOrStaff || auth.user?.isContractor) &&
            <Tooltip content="Email Warranty" disabled={!selectedRows.length}>
                <Button square design="btn-secondary-gray" size="btn-lg" disabled={!selectedRows.length || processing} onClick={handleSend} onMouseOver={() => trackHoverEvent('email_warranty', 'Email Warranty')} data-test={`${dataTest}-send-button`}>
                    <IconMail/>
                </Button>
            </Tooltip>}

        <Tooltip content="Download Warranty" disabled={isDownloadButtonDisabled}>
            <Button
                square
                design="btn-secondary-gray"
                size="btn-lg"
                disabled={isDownloadButtonDisabled}
                onClick={handleDownload}
                onMouseOver={() => trackHoverEvent('download_warranties', 'Download Warranty')}
                data-test={`${dataTest}-download-button`}
            >
                <IconDownload/>
            </Button>
        </Tooltip>

        {auth.user?.isAdminOrStaff && <Button size="btn-lg" href="/warranties/create" className="text-center whitespace-nowrap" data-test={`${dataTest}-create-button`}>
            Create Warranty
        </Button>}
    </>
}

export default WarrantyIndexButtons
