import Cleave from 'cleave.js'
import 'cleave.js/dist/addons/cleave-phone.us'
import { ReactNode, useEffect, useRef, useState } from 'react'

import InputError from './InputError'
import InputWarning from './InputWarning'
import { Button, Dropdown, IconChevronDown, IconChevronUp, IconEye, IconEyeOff } from '@/components'

type InputProps = {
    label?: string | ReactNode
    id: string
    name?: string
    placeholder?: string
    className?: string
    value?: string | number
    defaultValue?: string | number
    errors?: string | string[]
    warnings?: string | string[]
    hint?: ReactNode
    suggestions?: string[]
    preIcon?: ReactNode
    postIcon?: ReactNode
    required?: boolean
    onChange?: any
    onFocus?: any
    onBlur?: any
    onSuggestionSelect?: any
    size?: 'input-md' | 'input-sm'
    type?: string
    disabled?: boolean
    autoComplete?: string
    mask?: any
    trim?: boolean
    'data-test'?: string
}

const Input = ({
    label,
    id,
    errors = [],
    warnings = [],
    hint,
    className,
    suggestions,
    preIcon,
    postIcon,
    mask,
    trim,
    onSuggestionSelect,
    type: propType,
    size = 'input-md',
    'data-test': dataTest,
    ...props
}: InputProps) => {
    const [type, setType] = useState(propType)
    const [open, setOpen] = useState(false)
    const [focused, setFocused] = useState(false)
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const inputRef = useRef<HTMLInputElement>(null)
    const dropdownRef = useRef<HTMLUListElement>(null)
    const classNames = () => {
        const arr = ['input']
        if (errors.length) arr.push('has-error')
        if (warnings.length) arr.push('has-warning')
        if (className) arr.push(className)
        if (size) arr.push(size)
        if (open) arr.push('open')
        return arr.join(' ')
    }

    useEffect(() => {
        if (mask && inputRef.current) {
            const cleave = new Cleave(inputRef.current, {
                ...mask,
                onValueChanged(e) {
                    if (props.value?.toString() !== e.target.value?.toString()) {
                        props.onChange(e)
                    }
                }
            })

            return () => {
                cleave.destroy()
            }
        }
    }, [props, mask])

    useEffect(() => {
        if (suggestions?.length && focused) {
            setOpen(true)
        }
    }, [suggestions])

    const handleDocumentKeyDown = (e: any) => {
        if (e.key === 'Escape') {
            setOpen(false)
        }
    }

    const handleDocumentClick = (e: any) => {
        if (inputRef.current !== e.target && !inputRef.current?.contains(e.target)) {
            setOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick)
        document.addEventListener('keydown', handleDocumentKeyDown)
        return () => {
            document.removeEventListener('click', handleDocumentClick)
            document.removeEventListener('keydown', handleDocumentKeyDown)
        }
    }, [])

    const handleKeyDown = (e: any) => {
        if (!suggestions?.length) return
        const lastIndex = suggestions.length - 1
        if (e.code === 'ArrowUp') {
            e.preventDefault()
            const index = activeIndex > 0 ? activeIndex - 1 : lastIndex
            setActiveIndex(index)
            if (dropdownRef.current) {
                dropdownRef.current.children[index].scrollIntoView()
            }
        }
        if (e.code === 'ArrowDown') {
            e.preventDefault()
            const index = activeIndex < lastIndex ? activeIndex + 1 : 0
            setActiveIndex(index)
            if (dropdownRef.current) {
                dropdownRef.current.children[index].scrollIntoView({ block: 'end' })
            }
        }
        if (e.code === 'Enter') {
            e.preventDefault()
            if (open) {
                onSuggestionSelect(activeIndex)
                setOpen(false)
            } else {
                setOpen(true)
            }
        }
    }

    const handleFocus = () => {
        setOpen(true)
        setFocused(true)
        if (props.onFocus) props.onFocus()
    }

    const handleBlur = () => {
        setOpen(false)
        setFocused(false)
        if (trim) {
            props.onChange({
                target: {
                    name: props.name,
                    value: props.value?.toString().trim()
                }
            })
        }
        if (props.onBlur) props.onBlur()
    }

    dataTest = dataTest || id

    return <div className={classNames()} data-test={dataTest}>
        {label && <label data-test={`${dataTest}-label`} htmlFor={id}>{label}</label>}

        <div className={`input-container ${preIcon ? 'has-pre-icon' : ''} ${postIcon ? 'has-post-icon' : ''}`}>
            <Dropdown
                trigger="manual"
                show={open && !!suggestions?.length}
                button={<>
                    {preIcon && <label className="input-icon input-pre-icon" data-test={`${dataTest}-pre-icon`} htmlFor={id}>{preIcon}</label>}
                    <input
                        id={id}
                        type={type}
                        ref={inputRef}
                        onKeyDown={handleKeyDown}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        data-test={`${dataTest}-input`}
                        {...props}
                    />
                    {postIcon && <label
                        className="input-icon input-post-icon"
                        data-test={`${dataTest}-post-icon`}
                        htmlFor={id}
                    >{postIcon}</label>}
                    {propType === 'password' && !postIcon && (type === 'password'
                        ? <Button
                            type="button"
                            design="btn-link"
                            className="input-icon input-post-icon"
                            data-test={`${dataTest}-post-icon-eye`}
                            onClick={() => setType('text')}
                        >
                            <IconEye className="stroke-gray-400"/>
                        </Button>
                        : <Button
                            type="button"
                            design="btn-link"
                            className="input-icon input-post-icon"
                            data-test={`${dataTest}-post-icon-eye-off`}
                            onClick={() => setType('password')}
                        >
                            <IconEyeOff className="stroke-gray-400"/>
                        </Button>)}
                    {propType === 'number' && !postIcon &&
                        <div className="input-icon input-post-icon flex flex-col" data-test={`${dataTest}-post-icon`}>
                            <button
                                type="button"
                                data-test={`${dataTest}-post-icon-plus`}
                                onClick={() => props.onChange({
                                    target: {
                                        name: props.name,
                                        value: parseFloat(props.value?.toString() || '') + 1
                                    }
                                })}
                            >
                                <IconChevronUp className="stroke-black" size="sm"/>
                            </button>
                            <button
                                type="button"
                                data-test={`${dataTest}-post-icon-minus`}
                                onClick={() => props.onChange({
                                    target: {
                                        name: props.name,
                                        value: parseFloat(props.value?.toString() || '') - 1
                                    }
                                })}
                            >
                                <IconChevronDown className="stroke-black" size="sm"/>
                            </button>
                        </div>}
                </>}
            >
                {!!suggestions?.length && <ul className="input-suggestions-list" ref={dropdownRef} data-test={`${dataTest}-suggestions-list`}>
                    {suggestions.map((item, index) => {
                        let title = item
                        const value = props.value?.toString()
                        if (value && typeof value === 'string') {
                            const indices = item.toLowerCase().indicesOf(value.toLowerCase())

                            title = indices.reduce((acc: any, index) => {
                                const lastItem = acc[acc.length - 1]
                                const start = lastItem.toLowerCase().indexOf(value)
                                const end = start + value.length
                                return [
                                    ...acc.slice(0, -1),
                                    lastItem.slice(0, start),
                                    <span key={index} className="font-bold">{lastItem.slice(start, end)}</span>,
                                    lastItem.slice(end)
                                ]
                            }, [item])
                        }
                        return <li key={index}>
                            <button
                                onMouseDown={() => onSuggestionSelect(index)}
                                name={props.name}
                                type="button"
                                className={props.value === item || index === activeIndex ? 'active' : ''}>
                                <span>{title}</span>
                            </button>
                        </li>
                    })}
                </ul>}
            </Dropdown>
        </div>

        {hint && <div className="text-primary-700 text-sm mt-1.5" data-test={`${dataTest}-hint`}>{hint}</div>}

        <InputError data-test={`${dataTest}-error`} errors={errors}/>
        <InputWarning data-test={`${dataTest}-warning`} errors={warnings}/>

    </div>
}
export default Input
