import http, { Options, Params } from '../http'

export type WarrantySalesItemType = {
    date: string
    power_production_only: number
    production_with_20_year_separate_storage: number
    production_with_30_year_separate_storage: number
    production_with_separate_storage: number
    warranties_sold: number
}

export type TotalWarrantyCountsType = {
    power_production_only: number
    production_with_20_year_separate_storage: number
    production_with_30_year_separate_storage: number
    production_with_separate_storage: number
    total: number
}

export default {
    async inactiveCompanies(params: Params, options: Options) {
        const { data } = await http.get('api/v1/dashboard/widgets/inactive-accounts', params, options)
        return data
    },
    downloadInactiveCompanies(params: Params) {
        return http.get('api/v1/dashboard/widgets/inactive-accounts/download', params)
    },

    async companyIntegrations(params: Params, options: Options) {
        const { data } = await http.get('api/v1/dashboard/widgets/company-integrations', params, options)
        return data
    },
    downloadCompanyIntegrations() {
        return http.get('api/v1/dashboard/widgets/company-integrations/download')
    },

    async topCompanies(params: Params, options: Options) {
        const { data } = await http.get('api/v1/dashboard/widgets/top-companies', params, options)
        return data
    },
    downloadTopCompanies(params: Params) {
        return http.get('api/v1/dashboard/widgets/top-companies/download', params)
    },

    async warrantySales(params: Params, options: Options): Promise<Array<WarrantySalesItemType>> {
        const { data } = await http.get('api/v1/dashboard/widgets/warranty-sales', params, options)
        return data
    },
    async salesByContractor(params: {
        period: 'week' | 'month' | 'quarter' | 'year',
        sort_by: 'top' | 'bottom'
    }, options: Options) {
        const { data } = await http.get('api/v1/dashboard/widgets/sales-by-contractor', params, options)
        return data
    },
    async revenueByState(params: { period: 'week' | 'month' | 'quarter' | 'year' }, options: Options) {
        const { data } = await http.get('api/v1/dashboard/widgets/revenue-by-state', params, options)
        return data
    },

    async totalWarrantyCounts(params: Params, options: Options): Promise<TotalWarrantyCountsType> {
        const { data } = await http.get('api/v1/dashboard/widgets/total-warranty-counts', params, options)
        return data
    },


    eligibleCommissionTracking(params: Params, options: Options) {
        return http.get('api/v1/dashboard/widgets/eligible-commission-tracking', params, options)
    },
    async warrantySalesTracking(params: Params, options: Options) {
        const { data } = await http.get('api/v1/dashboard/widgets/warranty-sales-tracking', params, options)
        return data
    },

    linkedInInsights(params: Params, options: Options) {
        return http.get('api/v1/dashboard/widgets/linkedin-latest-post', params, options)
    },

    downloadWarrantyUploadStats() {
        return http.get('api/v1/warranty/export/upload_stats')
    },
    async totalSolarWarranties(params: Params, options: Options) {
        const { data } = await http.get('api/v1/dashboard/widgets/total-solar-warranties', params, options)
        return data.total
    }
}
