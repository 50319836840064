import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'

import { Button, Card, TextArea, IconLifeBuoy, Input, PageHeader, Select } from '@/components'
import { AuthLayout } from '@/containers'
import { useToastNotifications, useQuery } from '@/hooks'

const topics = [
    {
        value: '',
        title: 'Topic'
    },
    {
        value: 'Bug Report',
        title: 'Bug Report'
    },
    {
        value: 'Help',
        title: 'Help'
    },
    {
        value: 'Invoice Issue',
        title: 'Invoice Issue'
    },
    {
        value: 'Security Issue',
        title: 'Security Issue'
    },
    {
        value: 'Suggestion',
        title: 'Suggestion'
    },
    {
        value: 'Other',
        title: 'Other'
    }
]

const Contact = () => {
    const query = useQuery()
    const [errors, setErrors] = useState<any>({})
    const [form, setForm] = useState({
        '00N4x00000Qv0RM': '',
        '00N4x00000bbblG': '',
        email: '',
        phone: '',
        description: ''
    })
    const { success } = useToastNotifications()
    const breadcrumb = [
        <IconLifeBuoy key={1} className="stroke-gray-500"/>,
        <Link key={2} to="/support">Support</Link>,
        'Contact Support'
    ]

    useEffect(() => {
        if (query.success) {
            success('Your message successfully sent.')
        }
    }, [])

    const handleChange = (e: any) => {
        setForm((form: any) => ({ ...form, [e.target.name]: e.target.value }))
    }

    const handleSubmit = (e: any) => {
        const errors: any = {}
        if (!form['00N4x00000Qv0RM'].trim().length) {
            errors['00N4x00000Qv0RM'] = 'The name field is required.'
        }
        if (!form['00N4x00000bbblG'].trim().length) {
            errors['00N4x00000bbblG'] = 'The topic field is required.'
        }
        if (!form.email.trim().length) {
            errors.email = 'The email field is required.'
        }
        if (!isEmail(form.email)) {
            errors.email = 'The email must be a valid email address.'
        }
        if (!form.phone.trim().length) {
            errors.phone = 'The phone field is required.'
        }
        if (!form.description.trim().length) {
            errors.description = 'The message field is required.'
        }
        setErrors(errors)
        if (Object.keys(errors).length) e.preventDefault()
    }

    return <AuthLayout heading={<PageHeader title="Contact Support" backUrl="/support"/>} breadcrumb={breadcrumb}>
        <Card>
            <h2 className="text-2xl font-display">Get in Touch</h2>
            <h3 className="text-lg text-gray-500 mt-6 font-light">Our friendly team would love to hear from you.</h3>
            <form className="lg:w-1/2 mt-12" onSubmit={handleSubmit} action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
                <input type="hidden" name="orgid" value="00D4x000003yWAK"/>
                <input type="hidden" name="retURL" value={`${location.href}?success=true`}/>
                <input type="hidden" name="recordType" id="recordType" value="0124x000000Vo4gAAC"/>
                <input type="hidden" name="priority" id="priority" value="Medium"/>
                <input type="hidden" name="subject" id="subject" value="New Platform Helpdesk Form Submission"/>
                <input type="hidden" name="status" id="status" value="New"/>
                <input type="hidden" name="origin" id="origin" value="Solar Insure Platform"/>
                <input type="hidden" name="ownerid" id="ownerid" value="00G4x000001581AEAQ"/>

                <Input
                    id="name"
                    name="00N4x00000Qv0RM"
                    label="Name*"
                    className="mt-6"
                    placeholder="Name"
                    errors={errors['00N4x00000Qv0RM']}
                    value={form['00N4x00000Qv0RM']}
                    onChange={handleChange}
                />
                <Select
                    id="topic"
                    name="00N4x00000bbblG"
                    label="Topic*"
                    className="mt-6"
                    options={topics}
                    errors={errors['00N4x00000bbblG']}
                    value={form['00N4x00000bbblG']}
                    onChange={handleChange}
                />
                <Input
                    id="email"
                    name="email"
                    label="Email*"
                    className="mt-6"
                    placeholder="email@email.com"
                    errors={errors.email}
                    value={form.email}
                    onChange={handleChange}
                />
                <Input
                    id="phone"
                    name="phone"
                    label="Phone*"
                    className="mt-6"
                    placeholder="(###) ### - ####"
                    mask={{
                        numericOnly: true,
                        blocks: [0, 3, 3, 4],
                        delimiters: ['(', ') ', '-']
                    }}
                    errors={errors.phone}
                    value={form.phone}
                    onChange={handleChange}
                />
                <TextArea
                    id="description"
                    name="description"
                    label="Message*"
                    className="mt-6"
                    placeholder="Leave us a message..."
                    errors={errors.description}
                    value={form.description}
                    onChange={handleChange}
                />

                <div className="flex justify-end mt-8">
                    <Button size="btn-lg">
                        Send message
                    </Button>
                </div>
            </form>
        </Card>
    </AuthLayout>
}

export default Contact
