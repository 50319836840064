import { Chart as ChartJs } from 'chart.js'
import { useEffect, useState } from 'react'

import { DoughnutChart, Tabs } from '@/components'
import { useAnalytics } from '@/hooks'
import { api } from '@/services'

type SalesTrackingProps = {
    className?: string
    onChange: any
    processing: boolean
}

const SalesTracking = ({ className, processing, ...props }: SalesTrackingProps) => {
    const { trackEvent } = useAnalytics()
    const [abortController, setAbortController] = useState<AbortController | null>(null)

    const [data, setData] = useState({
        monthly: {
            current: 0,
            percentage: 0,
            previous: 0
        },
        quarterly: {
            current: 0,
            percentage: 0,
            previous: 0
        },
        yearly: {
            current: 0,
            percentage: 0,
            previous: 0
        }
    })

    type SalesOption = 'quarterly' | 'monthly' | 'yearly'
    const [period, setPeriod] = useState<SalesOption>('quarterly')

    const getPreviousPeriod = (short = false) => {
        if (period === 'quarterly') {
            const year = `${new Date().subtract('quarter', 1).format({ day: null, month: null, year: '2-digit' })}`
            const quarter = `Q${Math.ceil((new Date().subtract('quarter', 1).getMonth() + 1) / 3)}`
            const formattedDate = `${quarter} ${new Date().subtract('quarter', 1).format({ day: null, month: null, year: 'numeric' })}`
            return short ? `${quarter} ${year}` : formattedDate
        }
        if (period === 'monthly') {
            return new Date().subtract('month', 1).format({ day: null, month: 'long', year: 'numeric' })
        }
        return new Date().subtract('year', 1).format({ day: null, month: null, year: 'numeric' })
    }

    const fetchData = async () => {
        try {
            const controller = new AbortController
            setAbortController(controller)
            const res = await api.dashboard.warrantySalesTracking({}, { signal: controller.signal })
            setData(res)
        } finally {
            props.onChange('salesTracking')
        }
    }

    const handleTabChange = (value: SalesOption) => {
        setPeriod(value)
        trackEvent(`click_dashboard_${value}_toggle`, 'User Interaction', `Tab ${value}`)
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    useEffect(() => () => {
        abortController?.abort('canceled')
    }, [abortController])

    return <div className={`h-full flex flex-col justify-between ${className || ''}`}>
        <div>
            <h3 className="text-lg" data-test="dashboard-sales-tracking-title">Warranty Sales Tracking</h3>
            <h4 className="text-gray-500" data-test="dashboard-sales-tracking-subtitle">
                Q{(Math.floor((new Date().getMonth() / 3) + 1))} {new Date().format({ day: null, month: 'long' })}
            </h4>
        </div>
        <div className="relative mx-auto w-32">
            <DoughnutChart
                datasets={[{
                    data: [data[period].percentage, 100 - data[period].percentage],
                    backgroundColor: ['#007a96', 'transparent'],
                    hoverBackgroundColor: ['#00647a', 'transparent'],
                    borderWidth: 2,
                    borderColor: 'transparent',
                    cutout: 48,
                    radius: 64,
                    borderRadius: [data[period].percentage < 100 ? 30 : 0, 0]
                }]}
                plugins={[{
                    beforeDraw(chart: ChartJs) { // Adding background to chart
                        const { ctx, chartArea: { width, height } } = chart
                        ctx.save()
                        const centerX = width / 2
                        const centerY = height / 2
                        const radius = 56
                        ctx.beginPath()
                        ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false)
                        ctx.lineWidth = 16
                        ctx.strokeStyle = '#edebe7'
                        ctx.stroke()
                    }
                }]}
            />
            <div className="absolute font-display top-1/2 left-1/2 -translate-x-1/2 flex flex-col items-center justify-center -translate-y-1/2">
                <span className="text-xs text-gray-500" data-test="dashboard-sales-tracking-value">
                    {data[period].current.money()}
                </span>
                <span className="text-2xl" data-test="dashboard-sales-tracking-percentage">
                    {Math.round(data[period].percentage)}%
                </span>
            </div>
        </div>
        <div className="flex flex-col justify-center gap-8 mt-5">
            <div className="font-body">
                <h6 className="text-xs text-gray-700 font-semibold" data-test="dashboard-sales-tracking-desc-line-1">
                    Compared to {getPreviousPeriod()}
                </h6>
                <h5 className="text-gray-500 text-sm" data-test="dashboard-sales-tracking-desc-line-2">
                    <span className="capitalize">{period.slice(0, -2)} </span>
                    To Date: {data[period].current.money()}
                </h5>
                <h5 className="text-gray-500 text-sm border-b pb-3 mb-3" data-test="dashboard-sales-tracking-desc-line-3">
                    {getPreviousPeriod(true)}: {data[period].previous.money()}
                </h5>
                <div className="flex justify-center">
                    <Tabs
                        value={period}
                        onChange={handleTabChange}
                        design="buttons"
                        data-test="dashboard-sales-tracking-tabs"
                        tabs={[
                            {
                                id: 'quarterly',
                                title: 'By Quarter'
                            },
                            {
                                id: 'monthly',
                                title: 'By Month'
                            },
                            {
                                id: 'yearly',
                                title: 'By Year'
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default SalesTracking
