import { api, Options, Params, Payload } from '@/services'

export default class Model {
    [key: string]: any

    id: number | string = ''

    raw: any

    static endpoint = 'api/v1/models'

    constructor(object: any = {}) {
        this.raw = object
        Object.keys(object).forEach(key => {
            if (key && object[key] && typeof object[key] === 'string' && new Date(object[key]).isValid() && (key.endsWith('_at') || key.endsWith('_date'))) {
                this[`${key}_raw`] = object[key]
                if (object[key].endsWith('Z')) {
                    this[key] = new Date(object[key]).clearTimeZone()
                } else if (object[key].match(/^\d{4}-\d{2}-\d{2}$/)) { // Ignore timezone on dates with this format 2022-01-01
                    this[key] = new Date(`${object[key]} 00:00`)
                } else {
                    this[key] = new Date(object[key])
                }
            } else {
                this[key] = object[key]
            }
        })
    }

    static async index<T extends typeof Model>(
        this: T,
        params?: Params,
        options?: Options
    ): Promise<{ data: InstanceType<T>[], [key: string]: any }> {
        const res = await api.http.get(this.endpoint, params, options)
        res.data = res.data?.map((item: any) => new this(item)) || []
        return res
    }

    static async show<T extends typeof Model>(
        this: T,
        id: number | string,
        params?: Params,
        options?: Options
    ): Promise<InstanceType<T> | null> {
        const res = await api.http.get(`${this.endpoint}/${id}`, params, options)
        if (!id || res.status !== 'Ok') return null
        return new this(res.data) as InstanceType<T>
    }

    static store<T extends typeof Model>(
        this: T,
        payload: Payload,
        params?: Params,
        options?: Options
    ) {
        return api.http.post(this.endpoint, payload, params, options)
    }

    update(payload: object, params?: Params, options?: Options) {
        if (payload instanceof FormData) {
            payload.append('_method', 'PATCH')
            return api.http.post(`${(this.constructor as typeof Model).endpoint}/${this.id}`, payload, params, options) // eslint-disable-line
        }
        return api.http.put(`${(this.constructor as typeof Model).endpoint}/${this.id}`, payload, params, options) // eslint-disable-line
    }

    destroy(params?: Params, options?: Options) {
        return api.http.delete(`${(this.constructor as typeof Model).endpoint}/${this.id}`, params, options) // eslint-disable-line
    }
}
