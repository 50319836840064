import { ReactNode, useState, useEffect, useMemo } from 'react'

import { Button, Popup, IconXCircle, IconFlipBackward, Table, TextArea, Input, RowProps } from '@/components'
import { Filters, Meta } from '@/containers'
import { WarrantyStatusEnum } from '@/enums'
import { useQuery, useAppDispatch, useToastNotifications } from '@/hooks'
import { Warranty } from '@/models'
import { fetchNotifications, showNotifications } from '@/store'
import { JobsBatchType } from '@/types'

interface BulkUploadPopupFilters extends Filters {
    page?: number | string;
    per_page?: number | string;
}

interface BulkUploadPopupMeta extends Meta {
    total: number
    current_page: number
    per_page: number
}

type WarrantyBulkCancelReinstatePopupProps = {
    children: ReactNode
    selectedWarranties: Warranty[]
    excludedRows: RowProps[]
    selectedRows: RowProps[] | 'all'
    disabled?: boolean
    className?: string
    selectAll?: boolean
    filters?: BulkUploadPopupFilters
    warrantiesByContractors?: boolean
    onChange: () => void
    onPingJob: (jobData: JobsBatchType) => void
}

const WarrantyBulkCancelReinstatePopup = ({
    children,
    selectedWarranties,
    excludedRows,
    selectedRows,
    filters,
    warrantiesByContractors = false,
    className,
    disabled = false,
    ...props
}: WarrantyBulkCancelReinstatePopupProps) => {
    const query = useQuery()
    const getDefaultMeta = (): BulkUploadPopupMeta => ({
        total: 0,
        current_page: 1,
        per_page: 10
    })

    const [isOpen, setIsOpen] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<any>({})
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [warranties, setWarranties] = useState<Warranty[]>(selectedWarranties)
    const dispatch = useAppDispatch()
    const { success } = useToastNotifications()
    const [form, setForm] = useState({
        reason: '',
        contact_email: ''
    })
    const [meta, setMeta] = useState<BulkUploadPopupMeta>(getDefaultMeta())

    const isAllInactive = useMemo<boolean>(() => {
        if (warrantiesByContractors) return false

        if (selectedRows === 'all') {
            return query.statuses?.length && query.statuses.every((status: WarrantyStatusEnum) => [
                WarrantyStatusEnum.REINSTATEMENT_REQUESTED,
                WarrantyStatusEnum.CANCELED
            ].includes(status))
        }

        return warranties.every(item => item?.isCanceled || item?.isReinstatementRequested)
    }, [selectedRows])

    const selectedWarrantiesCount = useMemo(() => selectedRows === 'all'
        ? meta.total
        : selectedRows.length,
    [meta, selectedRows])

    const rows = useMemo(() => (selectedRows === 'all' ? warranties : selectedWarranties).map((item, index) => ({
        id: item.id,
        index: `${(selectedRows === 'all' ? index + 1 + ((meta.current_page - 1) * meta.per_page) : index + 1).format()}.`,
        customer_info: item.customer_info?.full_name,
        policy_num: item.policy_num
    })),
    [warranties, selectedWarranties, selectedRows])

    const fetchWarranties = async (tableFilters?: BulkUploadPopupFilters) => {
        try {
            abortController?.abort()
            const controller = new AbortController()
            setAbortController(controller)

            const perPage = tableFilters?.per_page && tableFilters.per_page !== '' ? Number(tableFilters.per_page) : 10
            const page = tableFilters?.page && tableFilters.page !== '' ? Number(tableFilters.page) : 1

            const { data, meta } = await Warranty.index({
                ...filters,
                per_page: perPage ?? 10,
                page: page ?? 1,
                search: query.search,
                excluded_ids: excludedRows.map(({ id }) => id)
            }, { signal: controller.signal })

            const newData = data.map((item: any) => new Warranty({ ...item, page: meta.current_page }))

            setWarranties(newData)
            setMeta(meta)
        } catch (err) {
            if (err instanceof Error && err.name !== 'AbortError') {
                setErrors(true)
            }
            throw err
        } finally {
            setProcessing(false)
        }
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setProcessing(true)
        setErrors({})

        try {
            const { data } = await Warranty.bulkCancel(selectedRows === 'all' ? {
                ids: [],
                select_all: true,
                reason: form.reason,
                contact_email: form.contact_email,
                excluded_ids: excludedRows.map(({ id }) => id),
                ...filters,
                ...query
            } : {
                ids: selectedRows.map(item => item.id),
                reason: form.reason,
                contact_email: form.contact_email
            })
            dispatch(fetchNotifications)
            const toast = success(`Bulk ${isAllInactive ? 'reinstatement' : 'cancellation'} is being processed. You can track the progress in the notifications center.`)
            toast.onClose = () => dispatch(showNotifications())
            setIsOpen(false)
            props.onChange()
            props.onPingJob(data)
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [name]: value })
    }

    const handleClickOpen = () => {
        if (disabled) return
        setIsOpen(true)
    }

    useEffect(() => {
        if (isOpen) {
            if (selectedRows === 'all') {
                fetchWarranties()
            }
        } else {
            setMeta(getDefaultMeta())
        }
    }, [isOpen])

    return <>
        <span role="button" tabIndex={-1} onKeyDown={handleClickOpen} className={className} onClick={handleClickOpen}>
            {children}
        </span>
        <Popup open={isOpen} onClose={() => setIsOpen(false)} className="lg:min-w-[718px]">
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 text-gray-900" noValidate>
                <p className="flex items-center gap-2 text-gray-900 text-lg font-semibold">
                    {isAllInactive
                        ? <IconFlipBackward className="stroke-error-600 w-6 h-6"/>
                        : <IconXCircle className="stroke-error-600 w-6 h-6"/>}
                    Warranty Bulk  {isAllInactive ? 'Reinstatement' : 'Cancellation'}
                </p>
                <span className="text-gray-500 mb-4">
                    Do you want to {isAllInactive ? 'reinstate' : 'cancel'} {selectedWarrantiesCount.format()} warranties?
                </span>

                <Table
                    className="mb-4 emphasized"
                    pagination={true}
                    sortable={false}
                    searchable={false}
                    useQueryParams={false}
                    processing={processing}
                    dataType="Warranties"
                    onChange={fetchWarranties}
                    data-test="warranties-bulk-cancel-reinstate-table"
                    meta={meta}
                    columns={[
                        {
                            title: '',
                            field: 'index'
                        },
                        {
                            field: 'customer_info',
                            title: 'Homeowner Name'
                        },
                        {
                            field: 'policy_num',
                            title: 'Plan ID'
                        }
                    ]}
                    rows={rows}
                />

                <TextArea
                    id="email"
                    name="reason"
                    label={isAllInactive ? 'Reinstatement Reason' : 'Cancellation Reason'}
                    onChange={handleChange}
                    value={form.reason}
                    placeholder={isAllInactive ? 'Enter reinstatement reason' : 'Enter cancellation reason'}
                    errors={errors.reason}
                />

                <Input
                    id="contact-email"
                    name="contact_email"
                    label="Contact Email"
                    type="email"
                    placeholder="you@company.com"
                    onChange={handleChange}
                    value={form.contact_email}
                    errors={errors.contact_email}
                />

                <div className="flex flex-col lg:flex-row gap-2 justify-center mt-2">
                    <Button className="whitespace-nowrap" design="btn-secondary-gray" hasError onClick={() => setIsOpen(false)} type="button" processing={processing}>
                        No, Exit without {isAllInactive ? 'Reinstating' : 'Cancelling'}
                    </Button>
                    <Button className="whitespace-nowrap" processing={processing}>
                        Yes, {isAllInactive ? 'Reinstate' : 'Cancel'} {selectedWarrantiesCount.format()} Warranties
                    </Button>
                </div>
            </form>
        </Popup>
    </>
}

export default WarrantyBulkCancelReinstatePopup
