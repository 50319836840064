type Feature = {
    name: string,
    active: string | boolean
}

const features: Array<Feature> = [
    {
        name: 'onboarding',
        active: import.meta.env.VITE_FEATURE_ONBOARDING || false
    },
    {
        name: 'monitoring-mapview',
        active: import.meta.env.VITE_FEATURE_MONITORING_MAPVIEW || false
    },
    {
        name: 'company-status',
        active: import.meta.env.VITE_FEATURE_COMPANY_STATUS || false
    },
    {
        name: 'company-verification',
        active: import.meta.env.VITE_FEATURE_COMPANY_VERIFICATION || false
    },
    {
        name: 'claims',
        active: import.meta.env.VITE_FEATURE_CLAIMS || false
    },
    {
        name: 'system-history',
        active: import.meta.env.VITE_FEATURE_SYSTEM_HISTORY || false
    },
    {
        name: 'warranty-history',
        active: import.meta.env.VITE_FEATURE_WARRANTY_HISTORY || false
    },
    {
        name: 'battery-eligibility',
        active: import.meta.env.VITE_FEATURE_BATTERY_ELIGIBILITY || false
    },
    {
        name: 'contractors-30-year-battery',
        active: import.meta.env.VITE_FEATURE_CONTRACTORS_30_YEAR_BATTERY || false
    }
]

export default features
