import { useCallback, useMemo } from 'react'

import { Checkbox, CustomSelect } from '@/components'
import { OptionProps } from '@/components/ui/CustomSelect'
import { SystemStatus, SystemStatusCategory, SystemTierCategory } from '@/models'

type MonitoringStatusDropdownProps = {
    onChange: (e: { target: { value: string[], name: string } }) => void
    value: string[]
    name: string
    id: string
    'data-test': string
    summary?: any
}

const MonitoringStatusDropdown = ({
    value,
    name,
    id,
    'data-test': dataTest,
    summary,
    ...props
}: MonitoringStatusDropdownProps) => {
    const handleChange = useCallback((e: any) => {
        let selected = value
        if (e.target.value) {
            const category = SystemStatusCategory.find(e.target.value)
            const tier = SystemTierCategory.find(e.target.value)
            const status = SystemStatus.find(e.target.value)
            if (tier.isKnown) {
                // If tier selected or deselected remove all tier statuses
                selected = selected.filter(item => !tier.hasStatus(item))
            }
            if (category.isKnown) {
                // If category selected or deselected remove all category statuses and category tiers
                selected = selected.filter(item => !category.hasStatus(item) && !category.hasTier(item))
            }
            // If value already selected remove it
            if (selected.includes(e.target.value.toString())) {
                selected = selected.filter(item => item !== e.target.value)
            // If status's tier is selected remove tier and select rest of tier statuses
            } else if (selected.includes(status.tier.value)) {
                selected = [
                    ...selected.filter(item => item !== status.tier.value),
                    ...status.tier.statuses
                        .map((item: SystemStatus) => item.value)
                        .filter((item: string) => item !== e.target.value)
                ]
            } else if (selected.includes(status.category.value)) {
                // If status's category is selected remove category and select rest of category tiers and statuses
                selected = [
                    ...selected.filter(item => item !== status.category.value),
                    ...status.category.tiers
                        .filter(item => !item.hasStatus(status.value))
                        .map(item => item.value),
                    ...status.tier.statuses
                        .filter(item => item.value !== status.value)
                        .map(item => item.value)
                ]
            } else if (selected.includes(tier.category.value)) {
                // If tier's category is selected remove category and select rest of category tiers
                selected = [
                    ...selected.filter(item => item !== tier.category.value),
                    ...tier.category.tiers
                        .filter(item => item.value !== tier.value)
                        .map(item => item.value)
                ]
            } else {
                // If new status or tier selected
                selected = [...selected, e.target.value]

                // If new status selected
                if (status.isKnown) {
                    // If all tier statuses are selected, select tier and remove statuses
                    if (status.tier.statuses.every((item: SystemStatus) => selected.includes(item.value))) {
                        selected = [
                            ...selected.filter(item => !status.tier.hasStatus(item)),
                            status.tier.value
                        ]
                    }
                    // If all category statuses are selected, select category and remove statuses and tiers
                    if (status.category.tiers.every((item: SystemTierCategory) => selected.includes(item.value))) {
                        selected = [
                            ...selected
                                .filter(item => !status.category.hasStatus(item) && !status.category.hasTier(item)),
                            status.category.value
                        ]
                    }
                }
                // If new tier selected
                if (tier.isKnown) {
                    // If all category tiers are selected select category and remove tiers
                    if (tier.category.tiers.every((item: SystemTierCategory) => selected.includes(item.value))) {
                        selected = [
                            ...selected.filter(item => !tier.category.hasTier(item)),
                            tier.category.value
                        ]
                    }
                }
            }
        } else {
            selected = []
        }
        props.onChange({ target: { value: selected, name } })
    }, [value])

    const getLabel = () => {
        const count = value.reduce((acc, item) => {
            const tier = SystemTierCategory.find(item)
            if (tier.isKnown) {
                return acc + tier.statuses.length
            }
            const category = SystemStatusCategory.find(item)
            if (category.isKnown) {
                return acc + category.statuses.length
            }
            return acc + 1
        }, 0)

        return count ? `Selected (${count})` : 'Any Status'
    }

    const getStatusCount = (statusKey: string, isStyled = false) => {
        if (summary) {
            const count = summary.statuses_count?.[statusKey] || summary.groups?.[statusKey] || 0

            if (isStyled) {
                return <div className="text-purple-600 bg-purple-100 w-8 h-8 flex justify-center items-center rounded-full text-2xs">
                    {count.format()}
                </div>
            }
            return <span className="text-sm">{count.format()}</span>
        }

        return ''
    }

    const statusOptions = useMemo(() => [
        {
            value: '',
            title: 'Any Status'
        },
        ...SystemStatusCategory.all.reduce((acc: OptionProps[], category, index) =>
            [
                ...acc,
                {
                    value: category.value,
                    title: <div className="font-semibold flex w-full gap-3 items-center justify-between">
                        <div className="flex gap-2 items-center">
                            <Checkbox
                                name="statuses[]"
                                value={category.value}
                                checked={value.includes(category.value)}
                                indeterminate={category.statuses.some(item => value.includes(item.value)) ||
                                    category.tiers.some(item => value.includes(item.value))}
                                onChange={() => {}}
                                onClick={() => {}}
                            />
                            {category.title}
                        </div>
                        {getStatusCount(category.value, true)}
                    </div>,
                    group: index > 0
                },
                ...category.tiers.reduce((acc: OptionProps[], tier, _, all) =>
                    [
                        ...acc,
                        ...all.length > 1 ? [{
                            value: tier.value,
                            title: <div className="flex flex-col w-full gap-4 font-semibold text-sm">
                                <div className="flex  gap-3 items-center justify-between">
                                    • {tier.title} {getStatusCount(tier.value)}
                                </div>
                                <div className="flex gap-2 items-center">
                                    <Checkbox
                                        name="statuses[]"
                                        value={tier.value}
                                        checked={value.includes(tier.value) || value.includes(category.value)}
                                        indeterminate={tier.statuses.some(item => value.includes(item.value))}
                                        onChange={() => {}}
                                        onClick={() => {}}
                                    />
                                    <div className="flex gap-2 items-center">
                                        {value.includes(tier.value) ? 'Deselect' : 'Select'} {tier.title}
                                    </div>
                                </div>
                            </div>
                        }] : [],
                        ...tier.statuses.map((status: SystemStatus) => ({
                            value: status.value,
                            title:
                                <div className="flex w-full gap-4 items-center justify-between font-body text-gray-900 text-sm">
                                    <div className="flex gap-2 items-center">
                                        <Checkbox
                                            name="statuses[]"
                                            value={status.value}
                                            checked={
                                                value.includes(status.value) ||
                                                value.includes(tier.value) ||
                                                value.includes(category.value)
                                            }
                                            onChange={() => {}}
                                            onClick={() => {}}
                                        />
                                        <div className="flex gap-2 items-center">
                                            {status.iconBadge('xs')} {status.title}
                                        </div>
                                    </div>
                                    {getStatusCount(status.value)}
                                </div>
                        }))
                    ], [])
            ], [])
    ], [value, summary])

    return <CustomSelect
        id={id}
        name={name}
        data-test={dataTest}
        persistent
        options={statusOptions}
        value={value.length ? 'multiple' : ''}
        onChange={handleChange}
        selectedOptionOverride={getLabel}
    />
}

export default MonitoringStatusDropdown
