import { useEffect, useState } from 'react'

import { Input, Checkbox, CustomSelect, Tooltip, IconHelpCircle } from '@/components'
import { Warranty } from '@/models'
import { api } from '@/services'

type AddressFormProps = {
    form: any
    warranty?: Warranty
    inline?: boolean
    onChange: any
    errors?: any
    inputClassName?: string
}
const AddressForm = ({ form, errors = {}, inputClassName, warranty, inline, ...props }: AddressFormProps) => {
    const [addresses, setAddresses] = useState<any[]>([])
    const [states, setStates] = useState<any>({})
    const [abortController, setAbortController] = useState<AbortController | null>()
    const handleChange = async (e: any) => {
        abortController?.abort('canceled')
        const controller = new AbortController
        setAbortController(controller)
        props.onChange(e)
        if (e.target.value) {
            const res = await api.address.autocomplete(e.target.value, { signal: controller.signal })
            setAddresses(res)
        } else {
            setAddresses([])
        }
    }

    const fetchStates = async () => {
        if (inline) return
        const res = await api.address.states()
        setStates(res)
    }

    useEffect(() => {
        fetchStates()
    }, [])

    useEffect(() => {
        if (typeof form.duplicate_override === 'undefined' && ((typeof errors === 'string' && errors.includes('The address you supplied is already registered in our system.')) || errors.street_address?.includes('Potential Duplicate Warning.'))) {
            props.onChange({ target: { name: 'duplicate_override', value: 0 } })
        }
        if (typeof form.allow_invalid === 'undefined' && errors.street_address?.includes('The address you supplied is not able to be verified.')) {
            props.onChange({ target: { name: 'allow_invalid', value: 0 } })
        }
    }, [errors])

    const handleSuggestionSelect = (index: number) => {
        const address = addresses[index]
        if (inline) {
            props.onChange({
                target: {
                    name: 'address',
                    value: `
${address.street_address} ${address.secondary_line || ''}
${address.city}, ${address.state} ${address.zip}
`
                }
            })
        } else {
            props.onChange({ target: { name: 'street_address', value: address.street_address } })
            props.onChange({ target: { name: 'city', value: address.city } })
            props.onChange({ target: { name: 'state', value: address.state } })
            props.onChange({ target: { name: 'zip', value: address.zip } })
        }
    }

    if (inline) return <Input
        id="address"
        name="address"
        errors={errors.address || []}
        label="Address*"
        placeholder="Address"
        onChange={handleChange}
        onSuggestionSelect={handleSuggestionSelect}
        value={form.address}
        suggestions={addresses.map((item: any) => `${item.street_address} ${item.secondary_line || ''}${item.city}, ${item.state} ${item.zip}`)}
    />
    return <>
        <Input
            id="street_address"
            name="street_address"
            errors={errors.street_address || []}
            className={inputClassName}
            label="Street Address Line 1*"
            placeholder="Street Address Line 1"
            onChange={handleChange}
            onSuggestionSelect={handleSuggestionSelect}
            value={form.street_address}
            suggestions={addresses.map((item: any) => `${item.street_address} ${item.secondary_line || ''}${item.city}, ${item.state} ${item.zip}`)}
        />
        <Input
            id="city"
            name="city"
            errors={errors.city || []}
            className={inputClassName}
            label="City*"
            placeholder="City"
            onChange={props.onChange}
            value={form.city}
        />
        <CustomSelect
            id="state"
            name="state"
            errors={errors.state || []}
            className={inputClassName}
            label="State*"
            options={[{
                value: '',
                title: 'State'
            }, ...Object.keys(states).map(key => ({ value: key, title: states[key as keyof typeof states] }))]}
            disabled={!Object.keys(states).length}
            value={form.state}
            onChange={props.onChange}
        />
        <Input
            id="zip"
            name="zip"
            errors={errors.zip || []}
            className={inputClassName}
            label="Zip Code*"
            placeholder="Zip Code"
            onChange={props.onChange}
            value={form.zip}
        />
        {typeof form.duplicate_override !== 'undefined' && <Checkbox
            id="allow-duplicate"
            name="duplicate_override"
            label={<span className="flex items-center gap-1">
                Allow Duplicate Address
                <Tooltip content="Use this checkbox only when a duplicate address error signals a possible mistake warranty registration. Sometimes, multiple warranties at the same address are legitimate, like when installing systems on different structures within the same property. If the warranty is valid and not a mistaken duplicate, select this option to mark it as unique.">
                    <IconHelpCircle className="stroke-gray-400"/>
                </Tooltip>
            </span>}
            checked={form.duplicate_override}
            onChange={props.onChange}
        />}
        {typeof form.allow_invalid !== 'undefined' && <Checkbox
            id="allow-invalid"
            name="allow_invalid"
            label={<span className="flex items-center gap-1">
                Allow Invalid Address
                <Tooltip content="Use this checkbox only when the address verification system is unable to confirm an entered address, which often happens with new-build houses not yet in the postal database. When registering a warranty for a new construction, double-check the accuracy of the address first. If it’s a confirmed valid address, then proceed by checking this box.">
                    <IconHelpCircle className="stroke-gray-400"/>
                </Tooltip>
            </span>}
            checked={form.allow_invalid}
            onChange={props.onChange}
        />}

        {errors && errors === 'The address you supplied is already registered in our system.' && !form.duplicate_override &&
            <div className="col-span-2">
                <p className="text-error-700 font-semibold">Another warranty already exists at this address.</p>
                <p className="text-error-700">
                    If this is a unique registration, select &apos;Allow Duplicate Address&apos; checkbox.
                </p>
            </div>}
    </>
}

export default AddressForm
