import { useEffect, useState } from 'react'

import { Button, Checkbox } from '@/components'
import { IntegrationCredentialStatusEnum } from '@/enums'
import { Company, Integration, InverterManufacturer } from '@/models'

type EditEnphaseMaintainerFormProps = {
    company: Company
    integration?: Integration
    onChange: () => void
    onAdded: () => void
    onModeChange: (mode: 'view' | 'edit') => void
}

const EditEnphaseMaintainerForm = ({ integration, company, ...props }: EditEnphaseMaintainerFormProps) => {
    const [mode, setMode] = useState<'view' | 'edit'>(integration?.isUsesMaintainer ? 'edit' : 'view')
    const [processing, setProcessing] = useState(false)

    const getForm = () => ({
        company_id: company.id,
        provider: InverterManufacturer.enphase.key,
        status: integration?.isUsesMaintainer ? IntegrationCredentialStatusEnum.USES_MAINTAINER : '',
        contact_name: integration?.contact_name || 'default',
        contact_email:  integration?.contact_email || 'default'
    })

    const [form, setForm] = useState(getForm())

    useEffect(() => {
        setForm(getForm())
        setMode(integration?.isUsesMaintainer ? 'view' : 'edit')
    }, [company])

    const handleChange = ({ target: { name, value } }: any) => setForm({ ...form, [name]: value })

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)

        try {
            if (integration) {
                await integration.update(form)
            } else {
                await Integration.store(form)
            }
            await props.onChange()
            props.onAdded()
            setMode('view')
            props.onModeChange('view')
        } finally {
            setProcessing(false)
        }
    }

    const handleModeChange = (mode: 'view' | 'edit') => {
        setMode(mode)
        props.onModeChange(mode)
    }

    const isUsesMaintainer = form.status === IntegrationCredentialStatusEnum.USES_MAINTAINER

    const isDisabled = () => !isUsesMaintainer || !form.contact_email || !form.contact_name

    return <div>
        {mode === 'edit'
            ? <form onSubmit={handleSubmit} className="flex flex-col gap-4 items-start">

                <div className="flex flex-wrap gap-x-6 gap-y-2">
                    <Checkbox
                        className="w-full"
                        label="I have associated my Enphase monitoring portal with SolarInsure."
                        name="status"
                        checked={isUsesMaintainer}
                        value={isUsesMaintainer ? '' : IntegrationCredentialStatusEnum.USES_MAINTAINER}
                        onChange={handleChange}
                    />
                </div>
                <div className="flex gap-3">
                    <Button type="button" disabled={!isUsesMaintainer} design={!isUsesMaintainer ? 'btn-tertiary' : 'btn-secondary-gray'} hasError={isUsesMaintainer} onClick={() => handleModeChange('view')}>
                        Cancel
                    </Button>
                    <Button disabled={isDisabled()} design={!isUsesMaintainer ? 'btn-tertiary' : 'btn-primary'} processing={processing}>
                        Submit Confirmation & E-Sign
                    </Button>
                </div>
            </form>
            : <div className="flex justify-between items-end">
                <Checkbox
                    className="w-full"
                    label={<div>
                        I have associated my Enphase monitoring portal with SolarInsure.
                        <p className="text-sm text-gray-500">
                            {integration?.status_change_detected_at?.format()}
                        </p>
                    </div>}
                    name="status"
                    checked
                    disabled
                />

                <Button design="btn-link" className="!text-primary-800 whitespace-nowrap" onClick={() => handleModeChange('edit')}>
                    Edit Credentials & Signature
                </Button>
            </div>}
    </div>
}

export default EditEnphaseMaintainerForm
