import { Image } from '@/components'


export enum InverterManufacturerEnum {
    // supported
    ENPHASE = 'enphase',
    SOLAREDGE_ALTERNATIVE = 'solaredge_alternative',
    SOLAREDGE = 'solaredge',
    GENERAC = 'generac',
    GENERAC_ALTERNATIVE = 'generac_alternative',

    // unsupported
    AP_SYSTEMS = 'ap_systems',
    SOL_ARK = 'sol_ark',
    HOYMILES = 'hoymiles',
    NEP = 'nep',
    PANASONIC = 'panasonic',
    FRANKLINWH = 'franklinwh',
    SONNEN = 'sonnen',
    TESLA = 'tesla',
    TIGO = 'tigo',
    SMA = 'sma',

    // unknown
    AMBIGUOUS = 'ambiguous',
    OTHER = 'other'
}

export const INVERTER_MANUFACTURERS = [
    {
        key: InverterManufacturerEnum.ENPHASE,
        logo: InverterManufacturerEnum.ENPHASE,
        title: 'Enphase',
        useMicroInverters: true,
        isSupported: true,
        systemIdGuide: <div>
            <div className="bg-primary-25 px-5 py-4 flex flex-col gap-4">
                <h4 className="font-semibold">The short video below will guide you step-by-step on how to find the System ID</h4>
                <p>Follow these simple steps to seamlessly locate the System ID for Enphase:</p>
                <ul className="list-disc ml-8">
                    <li>
                        <span className="font-semibold">Log into</span> the <a href="https://enlighten.enphaseenergy.com" target="_blank" rel="noreferrer" className="font-semibold underline">Enphase Enlighten Portal</a>.
                    </li>
                    <li>
                        <span className="font-semibold">Select</span> the homeowner&apos;s site <span className="font-semibold">from the systems list</span>.
                    </li>
                    <li>
                        <span className="font-semibold">Click</span> on the <span className="font-semibold">settings icon</span> in the top right corner.
                    </li>
                    <li>In the overview section, <span className="font-semibold">locate the System ID</span>.</li>
                </ul>
            </div>

            <Image src="/assets/images/gifs/enphase-system-id-guide.gif"/>
        </div>
    },
    {
        key: InverterManufacturerEnum.SOLAREDGE,
        logo: InverterManufacturerEnum.SOLAREDGE,
        title: 'SolarEdge',
        useOptimizers: true,
        isSupported: true,
        systemIdGuide: <div>
            <div className="bg-primary-25 px-5 py-4 flex flex-col gap-4">
                <h4 className="font-semibold">The short video below will guide you step-by-step on how to find the System ID</h4>
                <p>Follow these simple steps to seamlessly locate the System ID for SolarEdge:</p>
                <ul className="list-disc ml-8">
                    <li>
                        <span className="font-semibold">Log into</span> the <a href="https://monitoring.solaredge.com" target="_blank" rel="noreferrer" className="font-semibold underline">SolarEdge Monitoring Portal</a>.
                    </li>
                    <li>
                        <span className="font-semibold">Select</span> the homeowner&apos;s site <span className="font-semibold">from the Site Name</span>.
                    </li>
                    <li>
                        In the Site Details section, <span className="font-semibold">locate the System ID</span>.
                    </li>
                </ul>
            </div>

            <Image src="/assets/images/gifs/solaredge-system-id-guide.gif"/>
        </div>
    },
    {
        key: InverterManufacturerEnum.SOLAREDGE_ALTERNATIVE,
        logo: InverterManufacturerEnum.SOLAREDGE,
        title: 'SolarEdge',
        isAlternative: true,
        isUnsupported: true
    },
    {
        key: InverterManufacturerEnum.AP_SYSTEMS,
        title: 'AP Systems',
        isUnsupported: true
    },
    {
        key: InverterManufacturerEnum.GENERAC,
        logo: InverterManufacturerEnum.GENERAC,
        title: 'Generac',
        useMicroInverters: true,
        isSupported: true,
        systemIdGuide: <div>
            <div className="bg-primary-25 px-5 py-4 flex flex-col gap-4">
                <h4 className="font-semibold">The short video below will guide you step-by-step on how to find the System ID</h4>
                <p>Follow these simple steps to seamlessly locate the System ID for Generac:</p>
                <ul className="list-disc ml-8">
                    <li>
                        <span className="font-semibold">Log into</span> the <a href="https://pwrfleet.generac.com" target="_blank" rel="noreferrer" className="font-semibold underline">Generac portal</a>.
                    </li>
                    <li>
                        <span className="font-semibold">Navigate</span> to the <span className="font-semibold">homeowner’s account</span> in PWRcell
                    </li>
                    <li>
                        The Site ID can be found in <span className="font-semibold">the URL of the site</span>. It is the unique identifier <span className="font-semibold">after /sites/ in the URL</span>.
                    </li>
                </ul>
            </div>

            <Image src="/assets/images/gifs/generac-system-id-guide.gif"/>
        </div>
    },
    {
        key: InverterManufacturerEnum.GENERAC_ALTERNATIVE,
        logo: InverterManufacturerEnum.GENERAC,
        title: 'Generac',
        isAlternative: true,
        isUnsupported: true
    },
    {
        key: InverterManufacturerEnum.SOL_ARK,
        title: 'Sol-Ark',
        isUnsupported: true
    },
    {
        key: InverterManufacturerEnum.HOYMILES,
        title: 'Hoymiles',
        isUnsupported: true
    },
    {
        key: InverterManufacturerEnum.NEP,
        title: 'NEP',
        isUnsupported: true
    },
    {
        key: InverterManufacturerEnum.PANASONIC,
        title: 'Panasonic',
        isUnsupported: true
    },
    {
        key: InverterManufacturerEnum.FRANKLINWH,
        title: 'FranklinWH',
        isUnsupported: true
    },
    {
        key: InverterManufacturerEnum.SONNEN,
        title: 'Sonnen',
        isUnsupported: true
    },
    {
        key: InverterManufacturerEnum.TESLA,
        logo: InverterManufacturerEnum.TESLA,
        title: 'Tesla',
        isUnsupported: true,
        systemIdGuide: <div>
            <div className="bg-primary-25 px-5 py-4 flex flex-col gap-4">
                <h4 className="font-semibold">The short video below will guide you step-by-step on how to find the System ID</h4>
                <p>Follow these simple steps to seamlessly locate the System ID for Tesla:</p>
                <ul className="list-disc ml-8">
                    <li>
                        <span className="font-semibold">Log into</span> the <a href="https://auth.tesla.com/" target="_blank" rel="noreferrer" className="font-semibold underline">Tesla portal</a>.
                    </li>
                    <li>
                        Locate <span className="font-semibold">the DIN</span> in the third column of your company site list.
                    </li>
                    <li>
                        If the DIN is not available, <span className="font-semibold">copy the Site Name</span> from the first column instead.
                    </li>
                </ul>
            </div>

            <Image src="/assets/images/gifs/tesla-system-id-guide.gif"/>
        </div>
    },
    {
        key: InverterManufacturerEnum.TIGO,
        title: 'Tigo',
        isUnsupported: true
    },
    {
        key: InverterManufacturerEnum.SMA,
        title: 'SMA',
        isUnsupported: true
    },
    {
        key: InverterManufacturerEnum.AMBIGUOUS,
        title: 'Ambiguous',
        isUnknown: true
    },
    {
        key: InverterManufacturerEnum.OTHER,
        title: 'Other',
        isUnknown: true
    }
]
