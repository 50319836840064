enum WarrantyStatusEnum {
    EDIT_REQUESTED = 'edit_requested',
    ACTIVE = 'active',
    POTENTIAL_DUPLICATE = 'potential_duplicate',
    CANCELLATION_REQUESTED = 'cancellation_requested',
    REINSTATEMENT_REQUESTED = 'reinstatement_requested',
    CANCELED = 'canceled'
}

export default WarrantyStatusEnum
