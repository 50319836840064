import { MouseEventHandler, ReactNode } from 'react'
import { Link } from 'react-router-dom'

type ButtonProps = {
    children: ReactNode | Iterable<ReactNode>,
    className?: string,
    value?: string,
    name?: string,
    hasError?: boolean,
    processing?: boolean,
    square?: boolean,
    disabled?: boolean,
    design?: 'btn-link' | 'btn-link-gray' | 'btn-tertiary' | 'btn-tertiary-gray' | 'btn-secondary' | 'btn-secondary-gray' | 'btn-primary',
    size?: 'btn-sm' | 'btn-md' | 'btn-lg' | 'btn-xl' | 'btn-2xl',
    href?: string,
    target?: string,
    onClick?: MouseEventHandler,
    onMouseOver?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
    type?: 'button' | 'submit' | 'reset' | undefined
    replace?: boolean
    state?: any
}

const Button = ({
    children,
    className = '',
    hasError = false,
    disabled = false,
    processing = false,
    design = 'btn-primary',
    size = 'btn-md',
    href = '',
    state,
    target,
    square = false,
    replace,
    ...props
}: ButtonProps) => {
    const classNames = () => {
        const arr = ['btn']
        if (className) arr.push(className)
        if (design) arr.push(design)
        if (hasError) arr.push('btn-error')
        if (size) arr.push(size)
        if (square) arr.push('btn-square')
        if (disabled || processing) arr.push('disabled')
        return arr.join(' ')
    }

    const isExternalURL = (url: string) => url.startsWith('http')

    if (href && !disabled) {
        return isExternalURL(href)
            ? <a href={href} className={classNames()} {...props} target={target}>
                {children}
            </a>
            : <Link to={href} className={classNames()} {...props} target={target} state={state} replace={replace}>
                {children}
            </Link>
    }

    return <button className={classNames()} disabled={disabled || processing} {...props}>
        {children}
    </button>
}

export default Button
