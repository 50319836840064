import { ReactNode, useEffect } from 'react'

import { Button, Card, IconClose, Tooltip } from '@/components'

type PopupProps = {
    children: ReactNode
    open: boolean
    className?: string
    onClose?: any
    'data-test'?: string
}
const Popup = ({ children, className, open, 'data-test': dataTest, ...props }: PopupProps) => {
    const handleClose = () => {
        if (props.onClose) {
            props.onClose()
        }
    }
    const handleKeyDown = (e: any) => {
        if (e.key === 'Escape') {
            handleClose()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    if (!open) return null

    return <div className="popup-container" data-test={dataTest}>
        <style>
            {`body {
                overflow: hidden;
            }`}
        </style>
        <div role="button" tabIndex={-1} className="popup-backdrop" onClick={handleClose} onKeyDown={handleKeyDown}/>
        <Card className={`popup ${className || ''}`}>
            {props.onClose &&
                <Button type="button" design="btn-link" className="popup-close-button" onClick={handleClose}>
                    <Tooltip content="Close Pop-up">
                        <IconClose className="stroke-gray-500"/>
                    </Tooltip>
                </Button>}
            <div className="popup-body">
                {children}
            </div>
        </Card>
    </div>
}

export default Popup
