import * as ChartGeo from 'chartjs-chart-geo'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { Button, CustomSelect, IconExpand01, IconMinimize01, USMapChart } from '@/components'
import { api } from '@/services'

type RevenueByStateProps = {
    className?: string
    onChange: any
    isExpanded: boolean
    onExpand: any
    processing: boolean
}

const RevenueByState = ({ className, processing, isExpanded, ...props }: RevenueByStateProps) => {
    const didMount = useRef(false)
    const [us, setUs] = useState<any>(null)
    const [data, setData] = useState<any[]>([])
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [period, setPeriod] = useState<'month' | 'week' | 'quarter' | 'year'>('month')

    const fetchData = async () => {
        try {
            const controller = new AbortController
            setAbortController(controller)
            const res = await api.dashboard.revenueByState({ period }, { signal: controller.signal })
            setData(res)
        } finally {
            props.onChange('revenueByState')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true
            return
        }
        fetchData()
    }, [period])

    useEffect(() => () => abortController?.abort('canceled'), [abortController])

    const fetchUs = async () => {
        const res = await fetch('https://cdn.jsdelivr.net/npm/us-atlas/states-10m.json').then(r => r.json())
        setUs(res)
    }

    useEffect(() => {
        fetchUs()
    }, [])
    // if (!us) return null
    let nation
    let states

    if (us) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [nation] = ChartGeo.topojson.feature(us, us.objects.nation).features
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        states = ChartGeo.topojson.feature(us, us.objects.states).features
    }

    const maxValue = Object.values(data).reduce((acc, item) => item.revenue > acc ? item.revenue : acc, 0)
    const minValue = Object.values(data).reduce((acc, item) => item.revenue < acc ? item.revenue : acc, 0)

    const maxValueShort = maxValue / 1000

    return <div className={className}>
        <div className="mb-10 lg:mb-16 flex items-center justify-between max-h-10.5">
            <h3 className="text-lg font-display">Revenue by State</h3>

            <CustomSelect
                id="revenue-period"
                options={[
                    { value: 'week', title: 'This Week' },
                    { value: 'month', title: 'This Month' },
                    { value: 'quarter', title: 'This Quarter' },
                    { value: 'year', title: 'This Year' }
                ]}
                value={period}
                onChange={(e: any) => {
                    props.onChange('salesReport', true)
                    setPeriod(e.target.value)
                }}
                size="select-sm"
                selectedOptionOverride={(title: any) => isExpanded ? <span>Time Period: {title}</span> : title}
            />

            <div className="hidden md:block">
                <Button design="btn-link-gray" onClick={() => props.onExpand('revenueByState', !isExpanded)}>
                    {isExpanded ? <IconMinimize01 size="lg"/> : <IconExpand01 size="lg"/>}
                </Button>
            </div>
        </div>

        <div className={`grid grid-cols-5 ${isExpanded ? '' : 'grid-rows-2'}`}>
            <div className={`w-full ${isExpanded ? 'order-2 col-span-3 h-[352px]' : 'mb-4 lg:mb-0 col-span-5 lg:col-span-4 row-span-2'}`}>
                {!!us && <USMapChart
                    labels={states.map((d: any) => d.properties.name)}
                    datasets={[{
                        label: 'States',
                        outline: nation,
                        borderColor: '#fff',
                        borderWidth: '1',
                        data: states.map((d: any) => ({
                            feature: d,
                            value: data[api.address.statesStatic[d.properties.name]]?.revenue || 0
                        }))
                    }]}
                    formatTooltip={(name: string) => {
                        const stateKey = api.address.statesStatic[name]
                        return <>
                            <div className="whitespace-nowrap text-xs text-gray-700">{name}</div>
                            <div className="whitespace-nowrap text-sm text-gray-500">
                                Revenue:
                                {(data[stateKey]?.revenue || 0).money()} ({data[stateKey]?.revenuePercentage || 0}%)
                            </div>
                            <Link
                                to={`/warranties-by-contractors?state=${stateKey}&start_date=${new Date().startOf(period).toISODate()}&end_date=${new Date().toISODate()}`}
                                className="whitespace-nowrap text-sm text-orange-700"
                            >{(data[stateKey]?.contractorsCount || 0).format()} Contractors</Link>
                        </>
                    }}
                />}
            </div>

            <div className={`col-span-3 lg:col-span-1 ${isExpanded ? 'order-1 self-center justify-self-center text-lg' : 'text-sm'}`}>
                <h4 className={`mb-4.5 ${isExpanded ? 'text-xl' : ''}`}>Revenue</h4>
                <div className="flex gap-2">
                    <div className={`w-2 bg-gradient-to-b to-indigo-300 from-indigo-700 ${isExpanded ? 'h-[10rem]' : 'h-[6.25rem]'}`}/>
                    <div className="flex flex-col justify-between text-gray-500">
                        <div>{maxValue.money()}</div>
                        <div>{minValue.money()}</div>
                    </div>
                </div>
            </div>

            <ul className={`text-gray-500 col-span-2 lg:col-span-1 ${isExpanded ? 'order-3 self-center justify-self-center text-lg' : 'self-end text-xs'}`}>
                <li className="flex gap-2 mb-1.5 items-center">
                    <div className="w-5 h-3 bg-indigo-300"/>
                    {(0).money()}k - {(maxValueShort * 0.2).money()}k
                </li>
                <li className="flex gap-2 mb-1.5 items-center">
                    <div className="w-5 h-3 bg-indigo-400"/>
                    {(maxValueShort * 0.2).money()}k - {(maxValueShort * 0.4).money()}k
                </li>
                <li className="flex gap-2 mb-1.5 items-center">
                    <div className="w-5 h-3 bg-indigo-500"/>
                    {(maxValueShort * 0.4).money()}k - {(maxValueShort * 0.6).money()}k
                </li>
                <li className="flex gap-2 mb-1.5 items-center">
                    <div className="w-5 h-3 bg-indigo-600"/>
                    {(maxValueShort * 0.6).money()}k - {(maxValueShort * 0.8).money()}k
                </li>
                <li className="flex gap-2 items-center">
                    <div className="w-5 h-3 bg-indigo-700"/>
                    {(maxValueShort * 0.8).money()}k - {maxValueShort.money()}k
                </li>
            </ul>
        </div>
    </div>
}

export default RevenueByState
