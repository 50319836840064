import { useEffect } from 'react'

import { Select } from '@/components'
import { Product, Warranty } from '@/models'

type BatteryFormProps = {
    form: any
    products?: Product[]
    onChange: any
    inputClassName?: string
    labelPrefix?: string
    errors?: any
}
const BatteryForm = ({ form, inputClassName, products, errors, labelPrefix = '', ...props }: BatteryFormProps) => {
    const getBatteryCapacityOptions = () => {
        const battery = products?.find(({ id }) => id === parseInt(form.product_id))
        const availableSizes = battery?.properties.find(({ property }: any) => property.name === 'available_sizes').value || []
        return [{ value: '', name: '' }, ...availableSizes.map((item: string) => ({
            value: parseFloat(item),
            title: Warranty.getField('size_kw').format(item)
        }))]
    }

    useEffect(() => {
        if (getBatteryCapacityOptions().every(({ value }) => value?.toString() !== form.size_kw?.toString())) {
            props.onChange({ target: { value: '', name: 'size_kw' } })
        }
    }, [form.product_id])

    return <>
        <Select
            id="product_id"
            name="product_id"
            label={`${labelPrefix} Make & Model`}
            options={[{ title: '', value: '' }, ...products?.map(({ id, model_name: title }) => ({
                value: id,
                title
            })) || []]}
            onChange={props.onChange}
            value={form.product_id}
            errors={errors.product_id}
            className={inputClassName}
            data-test="battery-product-id"
        />
        <Select
            id="size_kw"
            name="size_kw"
            label={`${labelPrefix} Capacity (kWh)`}
            options={getBatteryCapacityOptions()}
            onChange={props.onChange}
            value={form.size_kw}
            errors={errors.size_kw}
            disabled={!form.product_id}
            className={inputClassName}
            data-test="battery-size-kw"
        />
    </>
}

export default BatteryForm
