import Model from './Model'
import { WarrantyProductTypeEnum } from '@/enums'
import { InverterManufacturer, WarrantyProductType } from '@/models'
import Company from '@/models/Company'
import Field from '@/models/Field'
import Homeowner from '@/models/Homeowner'
import WarrantyHistory from '@/models/WarrantyHistory'
import WarrantyStatus from '@/models/WarrantyStatus'
import { api } from '@/services'

export default class Warranty extends Model {
    static endpoint = 'api/v1/warranty'

    homeowner: Homeowner

    company?: Company

    histories: WarrantyHistory[]

    affiliate?: Company

    productType: WarrantyProductType

    constructor(props?: any) {
        super(props)
        this.homeowner = new Homeowner({ ...props?.homeowner, warranty: this })
        this.company = new Company(typeof props?.company === 'string' ? { name: props?.company } : props?.company)
        this.histories = props?.histories?.map((item: any) => new WarrantyHistory(item)) || []
        this.all_duplicates = props?.all_duplicates?.map((item: any) => new Warranty(item)) || []
        this.productType = WarrantyProductType.find(props.type) as WarrantyProductType
        if (props?.affiliate) {
            this.affiliate = new Company(props?.affiliate)
        }
    }

    static _fields = [
        { key: 'policy_num', title: 'Warranty Plan ID' },
        { key: 'affiliate_id', title: 'Affiliate ID' },
        { key: 'affiliate_name', title: 'Affiliate' },
        { key: 'company', title: 'Contractor' },
        { key: 'panel_name', title: 'Panel Model' },
        { key: 'inverter_name', title: 'Inverter Model' },
        { key: 'inverter_manufacturer', title: 'Inverter Manufacturer', format: (value: any) => InverterManufacturer.find(value).title },
        { key: 'num_panels', title: 'Number of Panels', format: 'number' },
        { key: 'size_kw', title: 'System Size (kW)', format: 'float' },
        { key: 'panel_wattage', title: 'Panel Wattage (W)', format: 'number' },
        { key: 'battery_size_kw', title: 'Battery Size (kW)', format: 'float' },
        { key: 'num_microinverters', title: 'Number of Microinverters/Optimizers ', format: 'number' },
        { key: 'install_date', title: 'Date of Installation', format: 'date' },
        { key: 'end_date', title: 'Warranty End Date', format: 'date' },
        { key: 'created_at', title: 'Submission Date', format: 'date' },
        { key: 'price', title: 'Total Price', format: 'money' },
        { key: 'solar_price', title: 'Solar Warranty Price', format: 'money' },
        { key: 'battery_price', title: 'Battery Warranty Price', format: 'money' },
        { key: 'status', title: 'Status', format: (value: any) => WarrantyStatus.show(value)?.title },
        { key: 'eligible_commission', title: 'Eligible Commission' },
        { key: 'battery_rate', title: 'Battery Rate', format: 'money' },
        { key: 'policy_rate', title: 'Policy Rate', format: 'money' },
        { key: 'full_name', title: 'Homeowner Name' },
        { key: 'first_name', title: 'First Name' },
        { key: 'last_name', title: 'Last Name' },
        { key: 'email', title: 'Homeowner Email' },
        { key: 'phone', title: 'Homeowner Phone', format: 'phone' },
        { key: 'system_id', title: 'Manufacturer System Identifier' },
        { key: 'address', title: 'Installation Address' },
        { key: 'street_address', title: 'Street Address Line 1' },
        { key: 'city', title: 'City' },
        { key: 'state', title: 'State' },
        { key: 'zip', title: 'Zip Code' },
        { key: 'sent_at', title: 'Sent At', format: 'datetime' },
        { key: 'cancellation_contact_email', title: 'Cancellation Contact Email' },
        { key: 'cancellation_reason', title: 'Cancellation Reason' },
        { key: 'cancellation_requested_at', title: 'Cancellation Requested at', format: 'datetime' },
        { key: 'edit_contact_email', title: 'Edit Contact Email' },
        { key: 'edit_reason', title: 'Edit Reason' },
        { key: 'edit_requested_at', title: 'Edit Requested at', format: 'datetime' },
        { key: 'num_batteries', title: 'Number of Batteries', format: (value: any) => value ? value.toString() : '0' },
        { key: 'battery_make', title: 'Battery: Make & Model' },
        { key: 'battery_size_kw', title: 'Battery: Capacity (kWh)', format: 'kW' },
        {
            key: 'batteries',
            title: 'Batteries',
            format: (value: any[]) => value?.map(item => `${item.product?.model_name}: ${parseFloat(item.size_kw).format({ minimumFractionDigits: 2 })}kW`)
        }
    ]

    static get fields() {
        return Warranty._fields.map(item => new Field(item))
    }

    static get priceSensitiveFields() {
        return [
            'size_kw',
            'num_panels',
            'panel_wattage',
            'batteries',
            'battery_rate',
            'policy_rate'
        ].map(Warranty.getField)
    }

    get statusObject() {
        return WarrantyStatus.show(this.status)
    }

    get typeIcons() {
        const types = []
        types.push(WarrantyProductType.solar.icon('xs', true)) // All warranties are solar type

        if (this.productType.key !== WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY) {
            types.push(this.productType.icon('xs', true))
        }
        return <div className="flex">
            {types}
        </div>
    }

    get statusTitle() {
        return WarrantyStatus.show(this.status)?.title
    }

    get isActive() {
        return this.status === 'active'
    }

    get isDuplicate() {
        return this.status === 'potential_duplicate'
    }

    get isCanceled() {
        return this.status === 'canceled'
    }

    get isCancellationRequested() {
        return this.status === 'cancellation_requested'
    }

    get isReinstatementRequested() {
        return this.status === 'reinstatement_requested'
    }

    get isEditRequested() {
        return this.status === 'edit_requested'
    }

    static getField(key: string) {
        return Warranty.fields.find(item => item.key === key) || Field.defaultField
    }

    static validateHomeowner(payload: any) {
        return api.warranties.validateHomeowner(payload)
    }

    static async getDuplicates(payload: any) {
        const res = await api.warranties.getDuplicates(payload)
        res.data = res.data.duplicates.map((item: any) => new this(item))
        return res
    }

    static validateSystem(payload: any) {
        return api.warranties.validateSystem(payload)
    }

    send(ids: (string | number)[] = [this.id]) {
        return Warranty.send(ids)
    }

    static send(ids: (string | number)[]) {
        return api.warranties.send(ids)
    }

    markUnique(reason: string) {
        return api.warranties.markUnique(this.id, reason)
    }

    static upload(payload: any) {
        return api.warranties.upload(payload)
    }

    static match(payload: any) {
        return api.warranties.match({ data: payload })
    }

    static validateMatch(payload: any) {
        return api.warranties.match({ data: payload }, { validation_only: true })
    }

    refreshEnergy() {
        return api.warranties.refreshEnergy(this.id)
    }

    static export(params: any) {
        return api.warranties.export({
            ids: params.ids,
            select_all: params.select_all,
            excluded_ids: params.excluded_ids,
            end_date: params.end_date,
            start_date: params.start_date,
            company_id: params.company_id,
            search: params.search,
            format: params.format,
            state: params.state,
            type: params.type,
            statuses: params.statuses,
            warranties_by_contractors: params.warranties_by_contractors
        })
    }

    download() {
        return api.warranties.download(this.id)
    }

    submitCancellationRequest(payload: any) {
        return api.warranties.cancellationRequest({ warranty_id: this.id, ...payload })
    }

    static revertCancellationRequest(id: string | number) {
        return api.warranties.cancelRequest(id)
    }

    static revertEditRequest(id: string | number) {
        return api.warranties.cancelEditRequest(id)
    }

    refresh() {
        return api.warranties.refresh(this.id)
    }

    stream() {
        return api.warranties.stream(this.id)
    }

    validate(payload: any) {
        return api.warranties.validate(this.id, payload)
    }

    static downloadMany(params: {
        ids?: string[] | number[]
        select_all?: boolean
        start_date?: string
        end_date?: string
        company_id?: string | number
        search?: string
        state?: string
        type?: string
        statuses?: string[]
        warranties_by_contractors?: boolean
    }) {
        return api.warranties.downloadMany({
            ids: params.ids,
            select_all: params.select_all,
            end_date: params.end_date,
            start_date: params.start_date,
            company_id: params.company_id,
            search: params.search,
            state: params.state,
            type: params.type,
            statuses: params.statuses,
            warranties_by_contractors: params.warranties_by_contractors
        })
    }

    static approveCancellationRequest(id: string | number) {
        return api.warranties.approveCancellationRequest(id)
    }

    static denyCancellationRequest(id: string | number) {
        return api.warranties.denyCancellationRequest(id)
    }

    static updateRequest(id: string | number, action: 'approve' | 'deny') {
        return api.warranties.updateRequest(id, action)
    }

    getCalculatePrices(payload: any) {
        return api.warranties.getCalculatePrices(this.id, payload)
    }

    static bulkCancel(params: any) {
        return api.warranties.bulkCancel({
            ids: params.ids,
            select_all: params.select_all,
            excluded_ids: params.excluded_ids,
            reason: params.reason,
            contact_email: params.contact_email,
            end_date: params.end_date,
            start_date: params.start_date,
            company_id: params.company_id,
            search: params.search,
            state: params.state,
            type: params.type,
            statuses: params.statuses
        })
    }

    static downloadExampleCsv() {
        return api.warranties.downloadExampleCsv()
    }
}
