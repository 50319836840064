import { Button } from '@/components'

type WarrantyCreatePreviewStepProps = {
    form: any
    onPrevious: any
    processing: boolean
    pdf: any
}
const WarrantyCreatePreviewStep = ({ form, processing, pdf, ...props }: WarrantyCreatePreviewStepProps) => {
    const getPreviewUrl = () => `${URL.createObjectURL(pdf)}#toolbar=0`

    return <div className="mt-12.5" data-test="warranty-create-step-3">
        <div className="flex justify-end gap-3">
            <Button design="btn-secondary-gray" type="button" onClick={props.onPrevious} processing={processing}>
                Back
            </Button>
            <Button processing={processing} data-test="warranty-create-step-3-continue-button">
                Finalize Warranty
            </Button>
        </div>

        <div className="my-8">
            <embed src={getPreviewUrl()} type="application/pdf" className="h-screen" width="100%"/>
        </div>

        <div className="flex justify-end gap-3">
            <Button design="btn-secondary-gray" type="button" onClick={props.onPrevious} processing={processing}>
                Back
            </Button>
            <Button processing={processing} data-test="warranty-create-step-3-continue-button-2">
                Finalize Warranty
            </Button>
        </div>
    </div>
}

export default WarrantyCreatePreviewStep
