import {
    Button,
    Tooltip,
    IconClose,
    WarrantyReviewCancellationReinstatementRequestPopup,
    WarrantyCancelPopup
} from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyIndexCancellationButtonsProps = {
    warranty: Warranty
    onChange: () => void
}

const WarrantyIndexCancellationButtons = ({ warranty, ...props }: WarrantyIndexCancellationButtonsProps) => {
    const auth = useAuth()

    if (auth.user?.isAdminOrStaff && warranty.isCancellationRequested) {
        return <WarrantyReviewCancellationReinstatementRequestPopup warranty={warranty} onChange={props.onChange}>
            <Tooltip content="Delete Cancellation Request">
                <Button square design="btn-secondary-gray" size="btn-lg" hasError>
                    <IconClose/>
                </Button>
            </Tooltip>
        </WarrantyReviewCancellationReinstatementRequestPopup>
    }
    if (!warranty.isCancellationRequested && !warranty.isCanceled && !warranty.isReinstatementRequested) {
        return <WarrantyCancelPopup warranty={warranty} onChange={props.onChange}>
            <Tooltip content="Cancel Warranty">
                <Button
                    square
                    size="btn-lg"
                    design="btn-secondary-gray"
                    hasError
                >
                    <IconClose/>
                </Button>
            </Tooltip>
        </WarrantyCancelPopup>
    }
    return null
}

export default WarrantyIndexCancellationButtons
