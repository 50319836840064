import Litepicker from 'litepicker'
import { ReactNode, useEffect, useRef, useState } from 'react'

import InputError from './InputError'
import { IconCalendar, IconCalendarDate, IconClose } from '@/components'

type LitePickerOptions = {
    singleMode?: boolean
    maxDate?: Date
    minDate?: Date
}

type DatePickerProps = {
    label?: string,
    id: string,
    name: string,
    value: any,
    className?: string,
    placeholder?: string,
    errors?: string | string[],
    hint?: string,
    preIcon?: ReactNode
    postIcon?: ReactNode
    options?: LitePickerOptions,
    onChange: any
    clearable?: boolean
    'data-test'?: string
}

const DatePicker = ({
    id,
    label,
    errors = [],
    hint,
    className,
    preIcon,
    postIcon,
    options = { singleMode: true },
    value,
    clearable,
    onChange,
    'data-test': dataTest,
    ...props
}: DatePickerProps) => {
    dataTest = dataTest || id
    const inputRef = useRef(null)
    let instance: Litepicker | null = null
    const [inst, setInst] = useState<Litepicker | null>(null)

    const handleChange = ({ start, end }: { start: Date, end: Date }) => {
        if (options.singleMode) {
            if (
                !!start !== !!value.start ||
                start.toISODate() !== value.toISODate()
            ) {
                onChange({ target: { name: props.name, value: start } })
            }
        } else {
            if (
                !!start !== !!value.start ||
                !!end !== !!value.end ||
                start.toISODate() !== value.start.toISODate() || end.toISODate() !== value.end.toISODate()
            ) {
                onChange({
                    target: {
                        value: { start, end },
                        name: props.name
                    }
                })
            }
        }
    }

    const init = () => {
        if (!inputRef.current) return
        instance = new Litepicker({
            element: inputRef.current,
            autoApply: true,
            format: 'MMM DD, YYYY',
            dropdowns: { minYear: 2000, maxYear: 2100, months: true, years: true },
            startDate: options.singleMode ? value : value.start,
            endDate: options.singleMode ? null : value.end,
            buttonText: {
                apply: 'Apply',
                cancel: 'Cancel',
                previousMonth: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m12.5 15-5-5 5-5" stroke-width="1.667" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                nextMonth: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7.5 15 5-5-5-5" stroke-width="1.667" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                reset: 'Reset'
            },
            setup: picker => {
                picker.on('render', ui => {
                    ui.querySelectorAll('.month-item-weekdays-row').forEach((item: HTMLDivElement) => {
                        item.querySelectorAll('div').forEach((item: HTMLDivElement, index: number) => {
                            item.innerHTML = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat', 'Su'][index]
                        })
                    })
                })
                picker.on('selected', (start, end) => {
                    handleChange({ start: start.toJSDate(), end: end?.toJSDate() })
                })
            },
            ...options
        })
        setInst(instance)
    }

    useEffect(() => {
        init()
        return () => {
            instance?.destroy()
        }
    }, [])

    useEffect(() => {
        if (inst) {
            if (options.singleMode) {
                const date = inst.getDate()?.toJSDate()
                if (value) {
                    if (value.toISODate() !== date?.toISODate()) {
                        inst.setDate(value)
                    }
                } else if (date) {
                    inst.clearSelection()
                }
            } else {
                const start = inst.getStartDate()?.toJSDate()
                const end = inst.getEndDate()?.toJSDate()
                if (value.start && value.end) {
                    if (value.start.toISODate() !== start?.toISODate() || value.end.toISODate() !== end?.toISODate()) {
                        inst.setDateRange(value.start, value.end)
                    }
                } else if (start || end) {
                    inst.clearSelection()
                }
            }
        }
    }, [value])

    const classNames = () => {
        const arr = ['input']
        if (errors.length) arr.push('has-error')
        if (className) arr.push(className)
        return arr.join(' ')
    }

    const getPreIcon = () => {
        if (clearable) return <label className="input-icon input-pre-icon stroke-gray-500" htmlFor={id} data-test={`${dataTest}-pre-icon`}><IconCalendar/></label>
        return preIcon && <label className="input-icon input-pre-icon" htmlFor={id} data-test={`${dataTest}-pre-icon`}>{preIcon}</label>
    }

    const getPostIcon = () => {
        if (clearable) {
            if ((options.singleMode && value) || (!options.singleMode && (value.start || value.end))) {
                return <label className="input-icon input-post-icon stroke-gray-500" htmlFor={id} data-test={`${dataTest}-clear-icon`}>
                    <button onClick={() => {
                        inst?.clearSelection()
                        onChange({
                            target: {
                                value: { start: null, end: null },
                                name: props.name
                            }
                        })
                    }}>
                        <IconClose/>
                    </button>
                </label>
            }
            return postIcon && <label className="input-icon input-post-icon" htmlFor={id} data-test={`${dataTest}-post-icon`}>{postIcon}</label>
        }
        return <label className="input-icon input-post-icon stroke-gray-500" htmlFor={id} data-test={`${dataTest}-post-icon`}><IconCalendarDate/></label>
    }

    return <div className={classNames()} data-test={dataTest}>
        {label && <label data-test={`${dataTest}-label`} htmlFor={id}>{label}</label>}
        <div className={`input-container datepicker ${getPreIcon() ? 'has-pre-icon' : ''} ${getPostIcon() ? 'has-post-icon' : ''}`}>
            {getPreIcon()}
            <input
                id={id}
                ref={inputRef}
                autoComplete="off"
                data-test={`${dataTest}-input`}
                {...props}
            />
            {getPostIcon()}
        </div>

        {hint && <div className="text-primary-700 text-sm mt-1.5">{hint}</div>}

        <InputError errors={errors}/>
    </div>
}

export default DatePicker
