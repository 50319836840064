import Model from './Model'
import { IntegrationCredentialStatusEnum } from '@/enums'
import { IntegrationCredentialStatus, InverterManufacturer } from '@/models/index'
import { api, Options, Params, Payload } from '@/services'

export default class Integration extends Model {
    static endpoint = 'api/v1/company/integrations'

    credentialStatus: IntegrationCredentialStatus

    inverterManufacturer: InverterManufacturer

    constructor(props?: any) {
        super(props)
        this.inverterManufacturer = InverterManufacturer.find(props?.provider)
        if (this.inverterManufacturer.isUnknown) {
            this.status = IntegrationCredentialStatusEnum.UNSUPPORTED
        }
        this.credentialStatus = new IntegrationCredentialStatus({ status: this.status })
    }

    static DEFAULT_ORDER: any = {
        enphase: 1,
        solaredge: 2,
        generac: 3,
        other: 4
    }

    static MAINTAINER_USERNAME = 'monitoring@solarinsure.com'

    static MAINTAINER_ENPHASE_ID = 1034

    static MAINTAINER_SOLAREDGE_ID = 1035

    static MAINTAINER_GENERAC_ID = 1036

    get isMaintainer() {
        return this.status === IntegrationCredentialStatusEnum.MAINTAINER
    }

    get isValid() {
        return this.status === IntegrationCredentialStatusEnum.VALID
    }

    get isUsesMaintainer() {
        return this.status === IntegrationCredentialStatusEnum.USES_MAINTAINER
    }

    get isNotRequired() {
        return this.status === IntegrationCredentialStatusEnum.NOT_REQUIRED
    }

    get isInvalid() {
        return this.status === IntegrationCredentialStatusEnum.INVALID
    }

    get isMissing() {
        return this.status === IntegrationCredentialStatusEnum.MISSING
    }

    get isInvalidOrMissing() {
        return this.isInvalid || this.isMissing
    }

    get isUnsupported() {
        return this.status === IntegrationCredentialStatusEnum.UNSUPPORTED
    }

    get isUnknown() {
        return this.status === IntegrationCredentialStatusEnum.UNKNOWN
    }

    get isComplianceVerified() {
        if (this.inverterManufacturer.isGenerac) {
            return this.matched_systems_count > 0 && !this.isInvalidOrMissing
        }
        return this.isUsesMaintainer && this.matched_systems_count > 0
    }

    get isComplianceNotVerified() {
        return this.isUsesMaintainer && this.matched_systems_count === 0 && this.warranties_count > 0
    }

    showCredentials() {
        let { id } = this
        if (this.isUsesMaintainer) {
            if (this.inverterManufacturer.isEnphase) {
                id = Integration.MAINTAINER_ENPHASE_ID
            } else if (this.inverterManufacturer.isSolaredge) {
                id = Integration.MAINTAINER_SOLAREDGE_ID
            } else if (this.inverterManufacturer.isGenerac) {
                id = Integration.MAINTAINER_GENERAC_ID
            }
        }
        return api.integrationCredentials.showCredentials(id)
    }

    update = (payload: Payload, params?: Params, options?: Options) =>
        api.http.put(Integration.endpoint, { id: this.id, ...payload }, params, options)

    destroy = (params?: Params, options?: Options) => api.http.delete(Integration.endpoint, {
        company_id: this.company_id,
        provider: this.provider,
        ...params
    }, options)
}
