import { ReactNode, useState } from 'react'

import { Button, Popup, IconXCircle } from '@/components'
import { Warranty } from '@/models'

type WarrantyReviewCancellationReinstatementRequestPopupProps = {
    children: ReactNode
    warranty: Warranty
    className?: string
    onChange: () => void
}

const WarrantyReviewCancellationReinstatementRequestPopup = ({
    children,
    warranty,
    className,
    ...props
}: WarrantyReviewCancellationReinstatementRequestPopupProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [processing, setProcessing] = useState(false)

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        try {
            const cancellationRequestId = warranty.pending_cancellation_requests?.[0]?.id
            await Warranty.approveCancellationRequest(cancellationRequestId)
            setIsOpen(false)
            props.onChange()
        } finally {
            setProcessing(false)
        }
    }

    const handleDeny = async () => {
        setProcessing(true)
        try {
            const cancellationRequestId = warranty.pending_cancellation_requests?.[0]?.id
            await Warranty.denyCancellationRequest(cancellationRequestId)
            setIsOpen(false)
            props.onChange()
        } finally {
            setProcessing(false)
        }
    }

    const getRequestType = () => warranty.isReinstatementRequested ? 'reinstatement' : 'cancellation'

    return <>
        <span role="button" tabIndex={-1} onKeyDown={() => setIsOpen(true)} className={className} onClick={() => setIsOpen(true)}>
            {children}
        </span>
        <Popup open={isOpen} onClose={() => setIsOpen(false)} className="lg:min-w-[718px]">
            <form onSubmit={handleSubmit} className="flex flex-col lg:pr-7 text-gray-900" noValidate>
                <p className="flex items-center gap-2 text-gray-900 text-lg font-semibold capitalize mt-3">
                    <IconXCircle className="stroke-error-600 w-6 h-6"/>
                    Warranty {getRequestType()} Request
                </p>
                <span className="text-gray-500 my-5">
                    <b className="text-gray-900">Plan ID:</b>  {warranty.policy_num}
                </span>
                <p className="font-semibold capitalize">
                    Contractor&apos;s { getRequestType() } reason:
                </p>
                <p className="italic font-light">
                    &quot;{warranty.pending_cancellation_requests[0]?.cancellation_reason}&quot;
                </p>
                <span className="text-gray-500 font-light mt-6">
                    Do you approve the {getRequestType()} of this warranty?
                </span>
                <div className="flex gap-2 justify-center mt-8">
                    <Button className="capitalize" design="btn-secondary-gray" hasError processing={processing} onClick={handleDeny} type="button">No, Deny {getRequestType()}</Button>
                    <Button className="capitalize" processing={processing}>Yes, approve {getRequestType()}</Button>
                </div>
            </form>
        </Popup>
    </>
}

export default WarrantyReviewCancellationReinstatementRequestPopup
