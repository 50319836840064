import { Link, useNavigate, useParams } from 'react-router-dom'

import { Button, Card, IconArrowRight, IconFlipBackward, IconLifeBuoy, Image, Popup } from '@/components'
import { AuthLayout } from '@/containers'

const resources = [
    {
        id: 1,
        poster: '/resources/Solar_Insure_Platform_Training.png',
        media: '/resources/Solar_Insure_Platform_Training.pdf',
        title: 'Solar Insure Platform Training',
        created_at: '20 Jan 2022',
        description: ''
    },
    {
        id: 2,
        poster: '/resources/SolarInsure_SolarWarrantyComparison.png',
        media: '/resources/SolarInsure_SolarWarrantyComparison.pdf',
        title: 'SolarInsure Solar Warranty Comparison',
        created_at: '19 Jan 2022',
        description: ''
    }
]
const Resources = () => {
    const { id = '' } = useParams()
    const navigate = useNavigate()
    const breadcrumb = [
        <IconLifeBuoy key={1} className="stroke-gray-500"/>,
        <Link key={2} to="/support">Support</Link>,
        'Resources'
    ]
    const heading = <h1 className="flex items-center text-3xl mt-2 font-display leading-[38px]">
        <Link to="/support" className="w-7 h-7 rounded-full bg-primary-200 mr-4 flex items-center justify-center">
            <IconFlipBackward className="stroke-primary-700" size="sm"/>
        </Link>
        Resources
    </h1>

    const resource = resources.find(item => item.id === parseInt(id))

    return <AuthLayout heading={heading} breadcrumb={breadcrumb}>
        <Card className="grid md:grid-cols-2 gap-8">
            {resources.map(item =>
                <Link to={`/support/resources/${item.id}`} key={item.id}>
                    <div className="w-full h-80 flex items-center justify-center relative border">
                        <div className="w-full h-full">
                            <Image src={item.poster} className="w-full h-full object-cover"/>
                        </div>
                        <div className="absolute flex justify-between p-6 text-white text-sm left-0 bottom-0 w-full border-t border-white backdrop-blur-xl">
                            <span>{item.created_at}</span>
                            <span>Solar Insure Resources</span>
                        </div>
                    </div>
                    <h3 className="text-2xl font-semibold font-display mt-8">{item.title}</h3>
                    <Button design="btn-link" className="mt-8" size="btn-lg">
                        Read Post <IconArrowRight className="stroke-primary-700 ml-2"/>
                    </Button>
                </Link>)}
        </Card>

        <Popup open={!!resource} onClose={() => navigate('/support/resources')}>
            {resource && <embed title="resource" src={`${resource.media}`} className="w-[90rem] h-[50rem] max-w-full max-h-full"/>}
        </Popup>
    </AuthLayout>
}

export default Resources
