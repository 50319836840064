import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Button from './Button'

type TabProps = {
    title: ReactNode,
    icon?: ReactNode,
    id: string | number | null
    to?: string
    regex?: RegExp
    showIf?: boolean
}

type TabsProps = {
    size?: 'sm' | 'md',
    design?: 'default' | 'buttons' | 'buttons-secondary',
    hash?: boolean,
    className?: string,
    value?: string | number | null,
    onChange?: any,
    tabs: Array<TabProps>
    'data-test'?: string
    replace?: boolean
}

const Tabs = ({
    size = 'md',
    className = '',
    value,
    tabs,
    design = 'default',
    hash = false,
    'data-test': dataTest = 'tabs',
    replace,
    onChange,
    ...props
}: TabsProps) => {
    const location = useLocation()

    useEffect(() => {
        if (hash && location.hash?.substring(1) && onChange) {
            onChange(location.hash.substring(1))
        }
    }, [])

    const getHref = (item: TabProps) => {
        if (item.to) {
            return item.to
        } else if (hash) {
            return `#${item.id}`
        }
        return undefined
    }

    const handleChange = ({ id }: TabProps) => {
        if (onChange) {
            onChange(id)
        }
    }

    const getIsActive = (item: TabProps, index: number) => {
        if (item.regex) {
            return item.regex.test(location.pathname)
        }
        return item.id === value || (!index && !value)
    }

    return <ul className={`tabs ${size} ${className} ${design}`} data-test={dataTest} {...props}>
        {tabs.map((item, index) => {
            if (item.showIf === false) return null
            return <li
                key={item.id}
                className={getIsActive(item, index) ? 'current' : ''}
                data-test={`${dataTest}-tab-${value}`}
            >
                <Button
                    design="btn-link"
                    onClick={() => handleChange(item)}
                    href={getHref(item)}
                    type="button"
                    replace={replace}
                >
                    <div className="flex gap-2 items-center">
                        {item.title} {item.icon}
                    </div>
                </Button>
            </li>
        })}
    </ul>
}

export default Tabs
