import { Link, Navigate, useLocation } from 'react-router-dom'

import {
    Card,
    IconBuilding07,
    IconCertificate, IconCube,
    IconCurrencyDollar,
    IconLineChart,
    IconUsers01,
    PageHeader
} from '@/components'
import { AuthLayout } from '@/containers'
import { useAuth } from '@/hooks'

const Error404 = () => {
    const location = useLocation()
    const auth = useAuth()

    if (!auth.user?.isAuthenticated) return <Navigate to="/login"/>

    const tabs = [
        {
            condition: location.pathname.startsWith('/monitoring'),
            backUrl: '/monitoring',
            breadcrumb: [
                <IconLineChart key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/monitoring">Monitoring</Link>,
                'Homeowner Details - Monitoring Tab'
            ]
        },
        {
            condition: location.pathname.startsWith('/warranties'),
            backUrl: '/warranties',
            breadcrumb: [
                <IconCertificate key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/warranties">Warranties</Link>,
                'Homeowner Details - Warranty Tab'
            ]
        },
        {
            condition: location.pathname.startsWith('/invoices'),
            backUrl: '/invoices',
            breadcrumb: [
                <IconCurrencyDollar key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/invoices">Invoices</Link>,
                'Invoice Details'
            ]
        },
        {
            condition: location.pathname.startsWith('/users'),
            backUrl: '/users',
            breadcrumb: [
                <IconUsers01 key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/users">Users</Link>,
                'User Details'
            ]
        },
        {
            condition: location.pathname.startsWith('/companies'),
            backUrl: '/companies',
            breadcrumb: [
                <IconBuilding07 key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/companies">Companies</Link>,
                'Company Details'
            ]
        },
        {
            condition: location.pathname.startsWith('/contractors'),
            backUrl: '/contractors',
            breadcrumb: [
                <IconBuilding07 key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/contractors">Companies</Link>,
                'Company Details'
            ]
        },
        {
            condition: location.pathname.startsWith('/products'),
            backUrl: '/products',
            breadcrumb: [
                <IconCube key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/products">Products</Link>,
                'Product Details'
            ]
        }
    ]

    const activeTab = tabs.find(item => item.condition)

    return <AuthLayout
        breadcrumb={activeTab?.breadcrumb}
        heading={<PageHeader title="Page Not Found" backUrl={activeTab?.backUrl}/>}
    >
        <Card className="text-lg">
            <p className="py-4">
                Sorry, the page you&apos;re looking for doesn&apos;t exist or may have been removed. This might be due
                to an outdated link or a typo in the URL.
            </p>
            <h4 className="py-4 font-semibold">Suggestions</h4>
            <ul className="py-4 list-disc ml-4.5">
                <li>Double-check the URL for any mistakes.</li>
                <li>Return to the Dashboard to find what you&apos;re looking for.</li>
                <li>If you believe this is an error or need assistance, please contact our support team at <a href="mailto:service@solarinsure.com." className="text-primary-700">service@solarinsure.com.</a>
                </li>
            </ul>
        </Card>
    </AuthLayout>
}

export default Error404
