import { ReactEventHandler, ReactNode } from 'react'

import InputError from './InputError'

type TextAreaProps = {
    label?: ReactNode
    id: string
    name?: string
    placeholder?: string
    rows?: number
    cols?: number
    className?: string
    value?: string | number
    defaultValue?: string | number
    errors?: string | string[]
    hint?: string
    preIcon?: ReactNode
    postIcon?: ReactNode
    required?: boolean
    onChange?: ReactEventHandler
    size?: 'textarea-md' | 'textarea-sm'
    disabled?: boolean
    'data-test'?: string
}

const TextArea = ({ label, id, errors = [], hint, className, preIcon, postIcon, size = 'textarea-md', 'data-test': dataTest, ...props }: TextAreaProps) => {
    dataTest = dataTest || id
    const classNames = () => {
        const arr = ['textarea']
        if (errors.length) arr.push('has-error')
        if (className) arr.push(className)
        if (size) arr.push(size)
        return arr.join(' ')
    }

    return <div className={classNames()} data-test={dataTest}>
        {label && <label data-test={`${dataTest}-label`} htmlFor={id}>{label}</label>}

        <div className={`textarea-container ${preIcon ? 'has-pre-icon' : ''} ${postIcon ? 'has-post-icon' : ''}`}>
            {preIcon && <label data-test={`${dataTest}-pre-icon`} className="textarea-icon textarea-pre-icon" htmlFor={id}>{preIcon}</label>}
            <textarea
                id={id}
                data-test={`${dataTest}-input`}
                {...props}
            />
            {postIcon && <label data-test={`${dataTest}-post-icon`} className="textarea-icon textarea-post-icon" htmlFor={id}>{postIcon}</label>}
        </div>

        {hint && <div className="text-gray-500 text-sm mt-1.5" data-test={`${dataTest}-hint`}>{hint}</div>}

        <InputError data-test={`${dataTest}-error`} errors={errors}/>

    </div>
}
export default TextArea
