import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Badge, Table, TableEditColumns, ColumnProps, RowProps } from '@/components'
import { useQuery } from '@/hooks'
import { Model } from '@/models'
import { sessionStorage } from '@/services'

export type Meta = {
    total: number
    overall_count?: number
    current_page?: number
    per_page?: number
}
export type Filters = {
    [key: string]: any
}

export type GlobalIndexData = {
    meta: Meta
    rows: Model[]
    selectedRows?: RowProps[] | 'all'
    excludedRows?: RowProps[]
    rest?: any
}

type GlobalIndexProps = {
    id: string
    reload?: boolean
    columns: Array<ColumnProps>
    rows: Array<RowProps>
    data: GlobalIndexData
    api: (params?: any, options?: any) => Promise<any>
    placeholder?: ReactNode
    dataType: string
    getDefaultFilters?: () => Filters
    filters?: Filters
    defaultTableFilters?: Filters
    heading?: ReactNode
    leftSide?: ReactNode
    rightSide?: ReactNode
    infinity?: boolean
    selectable?: boolean
    searchPlaceholder?: string
    maxColumnsAmount?: number

    onChange: (data: { name: 'meta' | 'rows' | 'selectedRows' | 'excludedRows' | 'rest', value: RowProps[] | Meta }) => void
    onFiltersChange?: (filters: any) => void
    onReloadChange?: (value: boolean) => void
}

const GlobalIndex = ({
    id,
    reload,
    data,
    columns,
    rows,
    heading,
    leftSide,
    rightSide,
    api,
    filters,
    defaultTableFilters,
    infinity,
    selectable,
    searchPlaceholder,
    maxColumnsAmount = 10,
    dataType = 'Items',
    placeholder,
    getDefaultFilters,
    ...props
}: GlobalIndexProps) => {
    const query = useQuery()
    const location = useLocation()
    const [abortController, setAbortController] = useState<AbortController | null>()
    const [activeColumns, setActiveColumns] = useState<string[]>([])
    const [error, setError] = useState<any>(null)
    const [processing, setProcessing] = useState<boolean | 'prev' | 'next'>(false)

    const getTableHeight = () => {
        const MIN_HEIGHT = 700
        const FILTERS_HEIGHT = 46 + 46
        const PADDINGS_HEIGHT = 64 + 64
        const height = window.innerHeight - FILTERS_HEIGHT - PADDINGS_HEIGHT
        return height < MIN_HEIGHT ? MIN_HEIGHT : height
    }

    const fetchData = async (tableFilters: Filters, isScrolled = false, isPrevious = false) => {
        abortController?.abort('canceled')
        const controller = new AbortController
        setAbortController(controller)
        if (isScrolled) {
            setProcessing(isPrevious ? 'prev' : 'next')
        } else {
            setProcessing(true)
        }
        try {
            const { data: rows, meta, ...rest } =
                await api({ ...defaultTableFilters, ...tableFilters, ...filters }, { signal: controller.signal })
            const newData = rows.map((item: Model) => {
                item.page = meta.current_page
                return item
            })

            if (isScrolled) {
                props.onChange({ name: 'rows', value: isPrevious ? [...newData, ...data.rows] : [...data.rows, ...newData] })
            } else {
                props.onChange({ name: 'rows', value: newData })
            }

            props.onChange({ name: 'meta', value: meta })
            props.onChange({ name: 'rest', value: rest })
        } catch (err: any) {
            if (err.name !== 'AbortError') {
                setError(true)
            }
            throw err
        } finally {
            setProcessing(false)
        }
    }

    const handleSelectedRowsChange = (selected: RowProps[]) => {
        props.onChange({ name: 'selectedRows', value: selected })
    }

    const handleExcludedRowsChange = (selected: RowProps[]) => {
        props.onChange({ name: 'excludedRows', value: selected })
    }

    useEffect(() => {
        fetchData(data.meta.current_page ? { ...query, page: 1 } : query)
    }, [filters])


    const handleReload = async () => {
        if (reload) {
            await fetchData({ ...query, page: 1 })
            if (props.onReloadChange) props.onReloadChange(false)
        }
    }

    useEffect(() => {
        handleReload()
    }, [reload])

    useEffect(() => {
        if (getDefaultFilters) {
            const newFilters = getDefaultFilters()
            const oldFilters = filters
            const isFiltersChanged = oldFilters && Object
                .keys(filters)
                .some(key => !!newFilters[key] !== !!newFilters[key] ||
                        newFilters[key]?.toString() !== oldFilters[key]?.toString())

            if (isFiltersChanged && props.onFiltersChange) props.onFiltersChange(newFilters)
        }
    }, [location])

    const handleRowClick = () => {
        if (location.search) {
            sessionStorage.set(`preservedQuery.${id}`, location.search)
        }
    }

    useEffect(() => {
        sessionStorage.delete(`preservedQuery.${id}`)
    }, [])

    const columnsMemo = useMemo(() =>
        columns.filter(({ field }) => columns.length <= maxColumnsAmount || activeColumns.includes(field)),
    [columns, maxColumnsAmount, activeColumns])
    const rowsMemo = useMemo(() => rows.map((item, index) => {
        item._page = data.rows[index].page
        return item
    }), [rows, data.rows])

    return <div className="flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row gap-3 items-center justify-between">
            <div className="flex flex-col sm:flex-row gap-3 items-center flex-wrap justify-start">
                {leftSide}

                <div className="flex flex-col sm:flex-row gap-3 items-center flex-wrap justify-start">
                    {columns.length > maxColumnsAmount && <TableEditColumns
                        name={`${id}-index-columns-edit`}
                        maxAmount={maxColumnsAmount}
                        columns={columns}
                        value={activeColumns}
                        onChange={setActiveColumns}
                        data-test={`${id}-columns-edit`}
                    />}

                    {(!!data.meta.overall_count || !!data.meta.total) &&
                        <Badge className="bg-primary-50 text-primary-600 whitespace-nowrap" data-test={`${id}-index-count-badge`}>
                            {data.meta.total.format()} {data.meta.overall_count && data.meta.overall_count !== data.meta.total ? ` of ${data.meta.overall_count.format()}` : ''} {dataType}
                        </Badge>}
                </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-3 items-center flex-wrap justify-end">
                {rightSide}
            </div>
        </div>

        <Table
            columns={columnsMemo}
            rows={rowsMemo}
            meta={data.meta}
            minHeight={getTableHeight()}
            placeholder={placeholder}
            errors={error}
            filters={filters}
            processing={processing}
            data-test={`${id}-index-table`}
            dataType={dataType}
            infinity={infinity}
            selectable={selectable}
            selectedRows={data.selectedRows}
            excludedRows={data.excludedRows}
            searchPlaceholder={searchPlaceholder}
            heading={heading}
            onChange={fetchData}
            onRowClick={handleRowClick}
            onSelectedRowsChange={handleSelectedRowsChange}
            onExcludedRowsChange={handleExcludedRowsChange}
        />
    </div>
}

export default GlobalIndex
