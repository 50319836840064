import { useState } from 'react'

import { Alert, IconAlertTriangleFilled } from '@/components'

const WarrantyDuplicateAlert = () => {
    const [isHidden, setIsHidden] = useState(false)

    if (isHidden) {
        return null
    }
    return <Alert className="mb-4" type="warning" onClose={() => setIsHidden(true)} icon={<IconAlertTriangleFilled/>}>
        <p className="mb-4 font-semibold text-black">Review Potential Duplicate Warranty</p>
        <p className="flex items-center text-black">
            We have discovered another warranty registered with the same address, which appears to be a potential
            duplicate.
        </p>
        <p className="flex items-center text-black">
            If this warranty was registered unintentionally, we highly recommend canceling it right away to prevent
            duplicate billing.
        </p>
        <p className="mb-3 flex items-center text-black">
            To proceed, you have two options:
        </p>
        <div className="text-warning-600 flex flex-col lg:flex-row gap-2 items-center mb-3">
            <span className="font-semibold text-black">Cancel Warranty:</span>
            <span className="text-black">
                If this warranty was unintentionally registered, it is strongly advised to cancel it immediately to
                avoid any duplicate billing.
            </span>
        </div>
        <div className="text-warning-600 flex flex-col lg:flex-row gap-2 items-center mb-3">
            <span className="font-semibold text-black">Mark as Unique:</span>
            <span className="text-black">
                If you believe this warranty is unique and not a duplicate, you can indicate it as such. Please provide
                an explanation for our records.
            </span>
        </div>
        <div className="flex gap-2 text-black items-center">
            <div>
                <p className="font-semibold whitespace-nowrap">
                    Message from <br/> support team:
                </p>
            </div>
            <div className="bg-yellow-25 p-5">
                <p className="italic font-light flex items-center justify-center text-black h-full">
                    “Please note that in certain cases, multiple warranties for the same homeowner at the same
                    address can be valid, especially when installing systems on different structures within the
                    property. In such instances, the newer potential duplicate warranty should be designated as
                    unique, rather than treated as a duplicate.”
                </p>
            </div>
        </div>
    </Alert>
}

export default WarrantyDuplicateAlert
