import { Image } from './ui'
import logoEnphase from '@/assets/images/logos/Enphase.png'
import logoEnphase2x from '@/assets/images/logos/Enphase@2x.png'
import logoEnphase3x from '@/assets/images/logos/Enphase@3x.png'
import logoGenerac from '@/assets/images/logos/Generac.png'
import logoGenerac2x from '@/assets/images/logos/Generac@2x.png'
import logoGenerac3x from '@/assets/images/logos/Generac@3x.png'
import logoCompactWhite from '@/assets/images/logos/Logo-Compact-white.png'
import logoCompactWhite2x from '@/assets/images/logos/Logo-Compact-white@2x.png'
import logoCompactWhite3x from '@/assets/images/logos/Logo-Compact-white@3x.png'
import logoPrimaryDaybreakWhite from '@/assets/images/logos/Logo-Primary-daybreak-white.svg'
import logoPrimaryDaybreak from '@/assets/images/logos/Logo-Primary-daybreak.svg'
import logoSmall from '@/assets/images/logos/Logo-Small.png'
import logoSmall2x from '@/assets/images/logos/Logo-Small@2x.png'
import logoSmall3x from '@/assets/images/logos/Logo-Small@3x.png'
import poweredByEnphase from '@/assets/images/logos/Powered_By_Enphase.png'
import poweredByEnphase2x from '@/assets/images/logos/Powered_By_Enphase@2x.png'
import poweredByEnphase3x from '@/assets/images/logos/Powered_By_Enphase@3x.png'
import logoSolarEdge from '@/assets/images/logos/SolarEdge.png'
import logoSolarEdge2x from '@/assets/images/logos/SolarEdge@2x.png'
import logoSolarEdge3x from '@/assets/images/logos/SolarEdge@3x.png'
import logoTesla from '@/assets/images/logos/Tesla.png'
import logoTesla2x from '@/assets/images/logos/Tesla@2x.png'
import logoTesla3x from '@/assets/images/logos/Tesla@3x.png'
import { InverterManufacturerEnum } from '@/enums'

const logos = {
    compactWhite: {
        src: logoCompactWhite,
        src2x: logoCompactWhite2x,
        src3x: logoCompactWhite3x
    },
    small: {
        src: logoSmall,
        src2x: logoSmall2x,
        src3x: logoSmall3x
    },
    enphase: {
        src: logoEnphase,
        src2x: logoEnphase2x,
        src3x: logoEnphase3x
    },
    generac: {
        src: logoGenerac,
        src2x: logoGenerac2x,
        src3x: logoGenerac3x
    },
    solaredge: {
        src: logoSolarEdge,
        src2x: logoSolarEdge2x,
        src3x: logoSolarEdge3x
    },
    tesla: {
        src: logoTesla,
        src2x: logoTesla2x,
        src3x: logoTesla3x
    },
    poweredByEnphase: {
        src: poweredByEnphase,
        src2x: poweredByEnphase2x,
        src3x: poweredByEnphase3x
    },
    primary: { src: logoPrimaryDaybreak },
    primaryWhite: { src: logoPrimaryDaybreakWhite }
}

type LogoProps = {
    type:
    'primary'
    | 'primaryWhite'
    | 'compactWhite'
    | 'small'
    | 'poweredByEnphase'
    | InverterManufacturerEnum
    className?: string
}

const Logo = ({ type = 'primary', ...props }: LogoProps) => <Image
    {...logos[type as keyof typeof logos]}
    {...props}
/>

export default Logo
