import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
    Button,
    IconDownload,
    Tabs,
    IconEdit05,
    IconHexagon,
    IconMail,
    Dropdown,
    Tooltip,
    WarrantyDuplicateAlert,
    WarrantyCancelledBanner,
    WarrantyDetailsReinstatementCancellationBanner,
    WarrantyEditCancellationBanner,
    WarrantyCancelPopup,
    IconClose,
    WarrantyReinstatePopup,
    IconFlipBackward,
    WarrantyReviewCancellationReinstatementRequestPopup,
    RefreshMonitoringButton
} from '@/components'
import { useAuth } from '@/hooks'
import { InverterManufacturer, Warranty } from '@/models'

const WarrantyNavigation = ({ warranty, ...props }: { warranty: Warranty, onChange?: any }) => {
    const { type, tab = 'warranty' } = useParams()
    const auth = useAuth()
    const [processing, setProcessing] = useState(false)

    const handleSend = async () => {
        setProcessing(true)
        try {
            await warranty.send()
        } finally {
            setProcessing(false)
        }
    }

    return <>
        <div className="flex flex-col lg:flex-row justify-between gap-3 mb-6">
            {!warranty.deleted_at && !auth.user?.isSoftwareAffiliate && <Tabs
                className="text-primary-700 text-base font-body"
                tabs={[
                    { to: `/${type}/${warranty.id}/warranty`, id: 'warranty', title: 'Warranty' },
                    { to: `/${type}/${warranty.id}/monitoring`, id: 'monitoring', title: 'Monitoring' },
                    { to: `/${type}/${warranty.id}/system-history`, id: 'system-history', title: 'System History' },
                    { to: `/${type}/${warranty.id}/devices`, id: 'devices', title: 'Device Details' }
                ]}
                value={tab}
            />}
            {!auth.user?.isAffiliate && <div
                className="flex flex-wrap-reverse gap-3 justify-center md:justify-end items-end mt-5 md:mt-0 ml-auto"
            >
                {tab === 'warranty' && (auth.user?.isAdminOrStaff || auth.user?.isContractor) && <>
                    {!warranty?.isCancellationRequested && !warranty.deleted_at &&
                        <WarrantyCancelPopup warranty={warranty} onChange={props.onChange}>
                            <Tooltip
                                content={warranty?.isCanceled ? 'Reinstate Warranty' : 'Cancel Warranty'}
                                disabled={warranty.deleted_at && !auth.user?.isAdmin}
                            >
                                <Button square design="btn-secondary-gray" hasError>
                                    <IconClose/>
                                </Button>
                            </Tooltip>
                        </WarrantyCancelPopup>}
                    {warranty?.isCanceled && !warranty.isReinstatementRequested &&
                        <WarrantyReinstatePopup warranty={warranty} onChange={props.onChange}>
                            <Tooltip
                                content={auth.user?.isAdminOrStaff ? 'Reinstate Warranty' : 'Request Reinstate Warranty'}
                                disabled={warranty.isReinstatementRequested && !auth.user?.isAdmin}
                            >
                                <Button
                                    design="btn-secondary-gray" hasError
                                    disabled={warranty.isReinstatementRequested && !auth.user?.isAdmin}
                                >
                                    <IconFlipBackward className="mr-2"/>
                                    <span className="font-semibold">Reinstate Warranty</span>
                                </Button>
                            </Tooltip>
                        </WarrantyReinstatePopup>}
                    {auth.user?.isAdminOrStaff &&
                        (warranty?.isCancellationRequested || warranty.isReinstatementRequested) &&
                        <WarrantyReviewCancellationReinstatementRequestPopup
                            warranty={warranty}
                            onChange={props.onChange}
                        >
                            <Tooltip content={warranty?.isCancellationRequested ? 'Review Cancellation Request' : 'Review Reinstatement Request'}>
                                <Button square design="btn-secondary-gray" hasError>
                                    <IconClose/>
                                </Button>
                            </Tooltip>
                        </WarrantyReviewCancellationReinstatementRequestPopup>}
                    <Tooltip
                        content="Edit Warranty"
                        disabled={warranty.deleted_at || (auth.user?.isContractor && warranty.isEditRequested)}
                    >
                        <Button
                            data-test="edit-warranty-button"
                            design="btn-secondary-gray"
                            square
                            href={`/${type}/${warranty.id}/warranty/edit`}
                            disabled={warranty.deleted_at || (auth.user?.isContractor && warranty.isEditRequested)}
                        >
                            <IconEdit05/>
                        </Button>
                    </Tooltip>
                    <Tooltip content="Email Warranty" disabled={warranty.deleted_at}>
                        <Button
                            design="btn-secondary-gray"
                            square
                            processing={processing}
                            onClick={handleSend}
                            disabled={warranty.deleted_at}
                        >
                            <IconMail/>
                        </Button>
                    </Tooltip>
                </>}
                <Tooltip content="Download Warranty" disabled={warranty.deleted_at}>
                    <Button
                        design="btn-secondary-gray"
                        square
                        onClick={() => warranty.download()}
                        disabled={warranty.deleted_at}
                    >
                        <IconDownload/>
                    </Button>
                </Tooltip>

                {(auth.user?.isAdminOrStaff || auth.user?.isContractor) &&
                    tab === 'monitoring' &&
                    warranty.homeowner?.system?.systemStatus?.refreshable &&
                        <RefreshMonitoringButton warranty={warranty} onChange={props.onChange}/>}
                {warranty.homeowner?.system?.manufacturerUrl
                    ? <Button
                        design="btn-secondary-gray" target="_blank" disabled={warranty.deleted_at}
                        href={warranty.homeowner?.system?.manufacturerUrl}
                    >
                        <div className="mr-2">
                            {warranty.homeowner.system.inverterManufacturer.getLogo()}
                        </div>
                        Go to {warranty.homeowner.system.inverterManufacturer.title}
                    </Button>
                    : <Dropdown
                        disabled={warranty.deleted_at}
                        button={<Button design="btn-secondary-gray" disabled={warranty.deleted_at}>
                            <IconHexagon
                                className={`mr-2 ${warranty.deleted_at ? 'stroke-warning-100' : 'stroke-warning-600'}`}
                                size="md"
                            />
                            Go to Manufacturer Site
                        </Button>}
                    >
                        <div className="px-2">
                            {InverterManufacturer.allSupported.map(item => <Button
                                key={item.key}
                                design="btn-link-gray"
                                className="w-full !justify-start"
                                target="_blank"
                                href={item.getUrl()}
                            >
                                <div className="mr-2">
                                    {item.getLogo()}
                                </div>
                                {item.title}
                            </Button>)}
                        </div>
                    </Dropdown>}
            </div>}
        </div>

        {(warranty.isReinstatementRequested || warranty.isCancellationRequested) &&
            <WarrantyDetailsReinstatementCancellationBanner warranty={warranty} onChange={props.onChange}/>}

        {warranty.isEditRequested && <WarrantyEditCancellationBanner warranty={warranty} onChange={props.onChange}/>}

        {warranty.isDuplicate && <WarrantyDuplicateAlert/>}

        {warranty.isCanceled && <WarrantyCancelledBanner warranty={warranty}/>}
    </>
}

export default WarrantyNavigation
