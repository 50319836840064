import { Switch } from '@/components'

type NotificationsFormProps = {
    form: any
    onChange: any
    inputClassName?: string
}
const NotificationsForm = ({ form, inputClassName, ...props }: NotificationsFormProps) =>
    <>
        {typeof form.uploadStatus !== 'undefined' && <div>
            <h4>Upload Status</h4>
            <Switch
                label="In Platform Notifications"
                className="mt-4"
                size="switch-sm"
                name="uploadStatus.database"
                checked={form.uploadStatus.database}
                onChange={props.onChange}
            />
            <Switch
                label="Email"
                className="mt-4"
                size="switch-sm"
                name="uploadStatus.mail"
                checked={form.uploadStatus.mail}
                onChange={props.onChange}
            />
        </div>}
        {typeof form.inactiveCompanies !== 'undefined' && <div>
            <h4>Inactive Companies Report</h4>
            <Switch
                label="In Platform Notifications"
                className="mt-4"
                size="switch-sm"
                name="inactiveCompanies.mail"
                checked={form.inactiveCompanies.mail}
                onChange={props.onChange}
            />
            <Switch
                label="Email"
                className="mt-4"
                size="switch-sm"
                name="inactiveCompanies.database"
                checked={form.inactiveCompanies.database}
                onChange={props.onChange}
            />
        </div>}
        {typeof form.credentials_update !== 'undefined' && <div>
            <h4>Credentials Update</h4>
            <Switch
                label="In Platform Notifications"
                className="mt-4"
                size="switch-sm"
                name="credentials_update.database"
                checked={form.credentials_update.database}
                onChange={props.onChange}
            />
            <Switch
                label="Email"
                className="mt-4"
                size="switch-sm"
                name="credentials_update.mail"
                checked={form.credentials_update.mail}
                onChange={props.onChange}
            />
        </div>}
        {typeof form.company_updates !== 'undefined' && <div>
            <h4>Company Updates</h4>
            <Switch
                label="In Platform Notifications"
                className="mt-4"
                size="switch-sm"
                name="company_updates.database"
                checked={form.company_updates.database}
                onChange={props.onChange}
            />
            <Switch
                label="Email"
                className="mt-4"
                size="switch-sm"
                name="company_updates.mail"
                checked={form.company_updates.mail}
                onChange={props.onChange}
            />
        </div>}
        {typeof form.monthly_reports !== 'undefined' && <div>
            <h4>Monthly Reports</h4>
            <Switch
                label="In Platform Notifications"
                className="mt-4"
                size="switch-sm"
                name="monthly_reports.database"
                checked={form.monthly_reports.database}
                onChange={props.onChange}
            />
            <Switch
                label="Email"
                className="mt-4"
                size="switch-sm"
                name="monthly_reports.mail"
                checked={form.monthly_reports.mail}
                onChange={props.onChange}
            />
        </div>}
        {typeof form.fraud_review !== 'undefined' && <div>
            <h4 className="md:mt-4">Fraud Review - 120+ Day Old Registrations</h4>
            <Switch
                label="Email"
                className="mt-4"
                size="switch-sm"
                name="fraud_review.mail"
                checked={form.fraud_review.mail}
                onChange={props.onChange}
            />
        </div>}
    </>

export default NotificationsForm
