import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import TablePlaceholder from './TablePlaceholder'
import Tbody, { RowProps } from './Tbody'
import TFooter from './TFooter'
import Thead, { ColumnProps } from './Thead'
import { IconSearch, Input } from '@/components'
import { useNavigateWithQuery, useQuery } from '@/hooks'

export type { RowProps, ColumnProps }

const DEFAULT_FILTERS = {
    page: 1,
    per_page: 10,
    search: '',
    sort_column: '',
    descending: false
}

type TableProps = {
    className?: string
    columns: Array<ColumnProps>
    rows: Array<{ id: string | number, onClick?: any, [key: string]: any }>
    selectable?: boolean
    clickable?: true
    pagination?: boolean
    sortable?: boolean,
    heading?: ReactNode
    searchable?: boolean
    useQueryParams?: boolean
    filters?: any
    infinity?: boolean
    processing?: boolean | 'prev' | 'next'
    meta?: any
    onChange?: (filters: any, isScrolled?: boolean, isPrevious?: boolean) => any
    selectedRows?: any[] | 'all'
    excludedRows?: any[]
    onSelectedRowsChange?: (selectedRows: any) => void,
    onExcludedRowsChange?: (excludedRows: any) => void,
    dataType?: string,
    errors?: any,
    defaultPerPage?: number
    onRowsChange?: (rowsLength: number) => void,
    placeholder?: ReactNode
    showPerPage?: boolean
    'data-test'?: string
    minHeight?: number
    searchPlaceholder?: string
    onRowClick?: (row: RowProps) => void
}

const Table = ({
    columns,
    rows,
    meta,
    selectable,
    sortable = true,
    pagination = true,
    searchable = true,
    infinity = false,
    processing = false,
    defaultPerPage = 10,
    minHeight = 400,
    placeholder,
    className = '',
    dataType = 'Items',
    errors,
    heading = null,
    selectedRows = [],
    excludedRows = [],
    useQueryParams = true,
    showPerPage = true,
    searchPlaceholder = 'Search',
    filters: parentFilters = {},
    'data-test': dataTest = 'table',
    ...props
}: TableProps) => {
    const tableContainerRef = useRef<HTMLDivElement>(null)
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const tableCustomHeadingRef = useRef<HTMLDivElement>(null)
    const tableRef = useRef<HTMLTableElement>(null)
    const localProcessingRef = useRef(false)
    const timeoutRef = useRef<number | undefined>(undefined)

    const navigateWithQuery = useNavigateWithQuery()
    const query = useQuery()
    const location = useLocation()

    const getDefaultFilters = () => ({
        page: useQueryParams && parseInt(query.page) ? parseInt(query.page) : 1,
        per_page: useQueryParams && parseInt(query.per_page) ? parseInt(query.per_page) : defaultPerPage,
        search: useQueryParams && query.search ? query.search : '',
        sort_column: useQueryParams && query.sort_column ? query.sort_column : '',
        descending: useQueryParams && query.descending ? !!query.descending : false
    })

    const [lastScroll, setLastScroll] = useState({ x: 0, y: 0 })
    const [height, setHeight] = useState<string>('auto')
    const [filters, setFilters] = useState(getDefaultFilters())

    const getIsServerSide = () => !!meta?.current_page

    const handleSelectChange = ({ target }: any) => {
        if (!props.onSelectedRowsChange || !selectable) return
        if (target.value === 'all') {
            if (getIsServerSide()) {
                if (props.onExcludedRowsChange) {
                    props.onExcludedRowsChange([])
                    if (target.checked) {
                        props.onSelectedRowsChange('all')
                    } else {
                        props.onSelectedRowsChange([])
                    }
                }
            } else {
                if (target.checked) {
                    props.onSelectedRowsChange(rows)
                } else {
                    props.onSelectedRowsChange([])
                }
            }
        } else {
            if (selectedRows === 'all') {
                if (props.onExcludedRowsChange) {
                    const isExcluded = excludedRows.find(({ id }: any) => id.toString() === target.value)
                    if (isExcluded) {
                        props.onExcludedRowsChange(excludedRows.filter((item: any) =>
                            item.id.toString() !== target.value))
                    } else {
                        const row = rows.find((item: any) => item.id.toString() === target.value)
                        props.onExcludedRowsChange([...excludedRows, row])
                        if (excludedRows.length === meta?.total - 1) {
                            props.onSelectedRowsChange([])
                        }
                    }
                }
            } else {
                const isSelected = selectedRows.some((item: any) => item.id.toString() === target.value)
                if (isSelected) {
                    props.onSelectedRowsChange(selectedRows.filter((item: any) =>
                        item.id.toString() !== target.value))
                } else {
                    const row = rows.find((item: any) => item.id.toString() === target.value)
                    props.onSelectedRowsChange([...selectedRows, row])
                }
            }
        }
    }

    const filterDefaults = (data: any) => Object.keys(data).reduce((acc: any, key: string) => {
        const value = data[key as keyof typeof data]
        const defaultValue = DEFAULT_FILTERS[key as keyof typeof DEFAULT_FILTERS]
        return { ...acc, [key]: defaultValue === value ? '' : value }
    }, {})

    const handleChange = (updated: any) => {
        timeoutRef.current = setTimeout(() => {
            updated = filterDefaults(updated)
            if (props.onChange) props.onChange(updated)
            if (useQueryParams) navigateWithQuery(updated)
        }, 500) as unknown as number
    }

    const handleFiltersChange = (key: string, value: string | number) => {
        setFilters(filters => {
            const updated = { ...filters, page: 1, [key]: value }
            if (key !== 'page') updated.page = 1
            clearTimeout(timeoutRef.current)
            handleChange(updated)
            return updated
        })
    }

    const updateUrlPage = (scrollLength: number) => {
        if (!scrollLength || processing) return
        const isScrollToTop = scrollLength > 0
        const scrollContainer = scrollContainerRef.current
        if (!scrollContainer) return
        const containerRect = scrollContainer.getBoundingClientRect()
        const rows: any[] = [...scrollContainer.querySelectorAll('tbody tr')]
        for (const item of isScrollToTop ? rows : rows.reverse()) {
            const rect = item.getBoundingClientRect()
            if ((isScrollToTop && rect.y > containerRect.y) ||
                (!isScrollToTop && rect.y < containerRect.y + containerRect.height)) {
                const itemPage = item.dataset.page
                const curPage = query.page || '1'
                if (itemPage !== curPage) {
                    const updated = { ...filters, page: parseInt(itemPage) }
                    if (useQueryParams) navigateWithQuery(filterDefaults(updated))
                    setFilters(updated)
                }
                break
            }
        }
    }

    const preserveScroll = () => {
        if (!scrollContainerRef.current || !tableRef.current) return
        const oldScrollTop = scrollContainerRef.current.scrollTop
        const { height: oldHeight } = tableRef.current.getBoundingClientRect()
        const obs = new ResizeObserver(([entry], observer) => {
            if (!scrollContainerRef.current) return
            const { height: newHeight } = entry.contentRect
            if (newHeight !== oldHeight) {
                scrollContainerRef.current.scrollTo({ top: oldScrollTop + newHeight - oldHeight })
                observer.disconnect()
            }
        })
        obs.observe(tableRef.current)
    }

    const loadPrev = async () => {
        const curPage = rows[0]?._page || 1

        if (curPage > 1 && props.onChange && !localProcessingRef.current) {
            localProcessingRef.current = true
            const updated = { ...filters, page: curPage - 1 }
            setFilters(updated)
            preserveScroll()
            try {
                await props.onChange(filterDefaults(updated), true, true)
            } finally {
                localProcessingRef.current = false
            }
        }
    }
    const loadNext = async (page = 0) => {
        const curPage = page || rows[rows.length - 1]?._page || 1
        if (tableContainerRef.current && tableRef.current) {
            if (curPage < meta?.last_page && props.onChange && !localProcessingRef.current) {
                localProcessingRef.current = true
                const updated = { ...filters, page: curPage + 1 }
                setFilters(updated)
                try {
                    await props.onChange(filterDefaults(updated), true, false)
                } finally {
                    localProcessingRef.current = false
                }

                if (!tableContainerRef.current) return
                const tableContainerHeight = tableContainerRef.current.getBoundingClientRect().height
                const tableHeight = tableRef.current.getBoundingClientRect().height
                if (tableHeight < tableContainerHeight) {
                    await loadNext(curPage + 1)
                }
            }
        }
    }

    const handleScroll = async () => {
        const scrollContainer = scrollContainerRef.current
        if (!scrollContainer) return
        setLastScroll({ x: scrollContainer.scrollLeft, y: scrollContainer.scrollTop })
        if (infinity && !processing && (lastScroll.y !== scrollContainer.scrollTop)) {
            const pixelsUntilBottom = (scrollContainer.scrollHeight - scrollContainer.offsetHeight) -
                scrollContainer.scrollTop
            const pixelsUntilTop = scrollContainer.scrollTop
            if (lastScroll.y > scrollContainer.scrollTop && pixelsUntilTop < 300) {
                await loadPrev()
            } else if (pixelsUntilBottom < 300) {
                await loadNext()
            }
            if (Math.abs(lastScroll.y - scrollContainer.scrollTop) < 700) {
                updateUrlPage(lastScroll.y - scrollContainer.scrollTop)
            }
        }
    }

    const fetchInitialPages = async () => {
        await loadNext()
        await loadPrev()
    }

    useEffect(() => {
        if (meta?.total && infinity && scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo(0, 0)
            fetchInitialPages()
        }
    }, [
        // meta?.total,
        meta?.per_page,
        meta?.search,
        meta?.sort_column,
        meta?.descending

        // meta?.start_date,
        // meta?.end_date,
        // meta?.status,
        // meta?.company_id,
        // meta?.manufacturer
    ])

    useEffect(() => {
        if (!useQueryParams) return
        const newValues: any = getDefaultFilters()
        const oldValues: any = filters
        if (Object.keys(oldValues).some(key => newValues[key]?.toString() !== oldValues[key]?.toString() && key !== 'page')) {
            setFilters(newValues)
            if (props.onChange) {
                props.onChange(filterDefaults(newValues))
            }
        }
    }, [location])

    const filteredRows = useMemo(() => {
        if (getIsServerSide()) return rows
        let res = [...rows]
        if (filters.search) {
            res = res.filter(item => !filters.search || Object.values(item).some(item => {
                if (typeof item?.toString === 'function') {
                    return item.toString().toLowerCase().includes(filters.search?.toLowerCase())
                }
                return false
            }))
        }
        if (filters.sort_column && sortable) {
            res = res.sort((a, b) => {
                const key = typeof a[`${filters.sort_column}_raw`] !== 'undefined'
                    ? `${filters.sort_column}_raw`
                    : filters.sort_column
                const aItem = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key]
                const bItem = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key]
                if (a._group > b._group) return 1
                if (aItem < bItem) {
                    return filters.descending ? 1 : -1
                }
                if (aItem > bItem) {
                    return filters.descending ? -1 : 1
                }
                return 0
            })
        }
        return res
    }, [filters, rows])

    const rowsForPage = useMemo(() => {
        if (getIsServerSide()) return filteredRows
        return pagination
            ? [...filteredRows].splice((filters.per_page * filters.page) - filters.per_page, filters.per_page)
            : filteredRows
    }, [pagination, filteredRows])

    useEffect(() => {
        if (props.onRowsChange) props.onRowsChange(rowsForPage.length)
    })

    const updateHeight = () => {
        if (tableContainerRef.current && tableRef.current && infinity) {
            const { top } = tableContainerRef.current.getBoundingClientRect()
            const { height: tableHeight } = tableRef.current.getBoundingClientRect()
            const height = window.innerHeight - (top + 32)
            setHeight((height > tableHeight) || !rows.length ? 'auto' : `${height < minHeight ? minHeight : height}px`)
        } else {
            setHeight('')
        }
    }

    useEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight)

        if (selectable && selectedRows && props.onSelectedRowsChange && props.onExcludedRowsChange) {
            if (selectedRows === 'all') {
                props.onExcludedRowsChange(excludedRows.filter(excludedRow =>
                    rows.some(row => row.id === excludedRow.id)))
            } else {
                props.onSelectedRowsChange(selectedRows.filter(selectedRow =>
                    rows.some(row => row.id === selectedRow.id)))
            }
        }

        return () => {
            window.removeEventListener('resize', updateHeight)
        }
    }, [rows])

    // const getFilters = () => {
    //     if (!useQueryParams) return filters
    //     return {
    //         page: parseInt(query.page) ? parseInt(query.page) : 1,
    //         per_page: parseInt(query.per_page) ? parseInt(query.per_page) : 10,
    //         search: query.search ? query.search : '',
    //         sort_column: query.sort_column ? query.sort_column : '',
    //         descending: query.descending ? !!(query.descending) : false
    //     }
    // }

    const getScrollContainerHeight = () => `calc(100% - ${tableCustomHeadingRef.current?.getBoundingClientRect().height || 0}px)`

    return <>
        {searchable && <Input
            id="datatable-search"
            placeholder={searchPlaceholder}
            className="mb-4 md:mb-6 flex"
            value={filters.search}
            onChange={(e: any) => handleFiltersChange('search', e.target.value)}
            preIcon={<IconSearch className="stroke-gray-700"/>}
            data-test={`${dataTest}-search`}
        />}

        <div
            ref={tableContainerRef}
            className={`datatable ${className || ''} ${infinity && 'infinity'}`}
            style={{ height }}
            data-test={dataTest}
        >
            <div ref={tableCustomHeadingRef}>
                {heading}
            </div>
            <div
                ref={scrollContainerRef}
                style={{ height: getScrollContainerHeight() }}
                className="overflow-auto snap-x snap-mandatory md:snap-none" onScroll={handleScroll}
            >
                <div className={`loader loader-top ${processing === 'prev' ? 'show' : ''}`}>
                    Loading...
                </div>
                <table ref={tableRef}>
                    <Thead
                        columns={columns}
                        rows={rows}
                        meta={meta}
                        selectable={selectable}
                        sortable={sortable}
                        selectedRows={selectedRows}
                        excludedRows={excludedRows}
                        sortColumn={filters.sort_column}
                        descending={filters.descending}
                        onChange={handleSelectChange}
                        onSortColumnChange={handleFiltersChange}
                        onSortDirectionChange={handleFiltersChange}
                        data-test={dataTest}
                        dataType={dataType}
                    />
                    <Tbody
                        columns={columns}
                        rows={rowsForPage}
                        selectable={selectable}
                        selectedRows={selectedRows}
                        excludedRows={excludedRows}
                        onChange={handleSelectChange}
                        onRowClick={props.onRowClick}
                        data-test={dataTest}
                    />
                </table>
                <div className={`loader loader-bottom ${processing === 'next' ? 'show' : ''}`}>
                    Loading...
                </div>
                <TablePlaceholder
                    processing={processing}
                    content={placeholder}
                    rows={rowsForPage}
                    dataType={dataType}
                    errors={errors}
                    filters={{ search: filters.search, ...parentFilters }}
                />
            </div>
            {pagination && !infinity && <TFooter
                total={meta?.total || filteredRows.length}
                page={filters.page}
                perPage={filters.per_page}
                showPerPage={showPerPage}
                onPageChange={handleFiltersChange}
                onPerPageChange={handleFiltersChange}
                data-test={dataTest}
            />}
        </div>
    </>
}

export default Table
