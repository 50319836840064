import { useEffect, useState } from 'react'
import { Link, useLoaderData, useParams } from 'react-router-dom'

import {
    IconCertificate,
    IconLineChart, IconList,
    WarrantyHeader,
    WarrantyNavigation
} from '@/components'
import { AuthLayout, DevicesIndex, MonitoringShow, SystemHistoryIndex, WarrantiesShow } from '@/containers'
import { Warranty } from '@/models'
import { sessionStorage } from '@/services'

const Show = () => {
    const { type, id, tab = 'warranty' } = useParams()
    const data = useLoaderData() as Warranty

    const [warranty, setWarranty] = useState(data)

    const fetchWarranty = async () => {
        const res = await Warranty.show(warranty.id) as Warranty
        setWarranty(res)
    }

    useEffect(() => {
        setWarranty(data)
    }, [id])

    const getBreadcrumb = () => {
        const breadcrumbs: Record<string, [JSX.Element, string]> = {
            monitoring: [<IconLineChart key={1} className="stroke-gray-500"/>, 'Monitoring'],
            warranties: [<IconCertificate key={1} className="stroke-gray-500"/>, 'Warranties'],
            'warranties-by-contractors': [<IconList key={1} className="stroke-gray-500"/>, 'Warranties by Contractor']
        }

        const tabs: any = {
            warranty: 'Warranty',
            monitoring: 'Monitoring',
            'system-history': 'System History',
            devices: 'Device Details'
        }

        const [icon, label] = type ? breadcrumbs[type] : breadcrumbs.warranties

        return [
            icon,
            <Link key={2} to={`/${type}`}>{label}</Link>,
            `Homeowner Details - ${tabs[tab]} Tab`
        ]
    }

    const getPage = () => {
        if (tab === 'devices') {
            return <DevicesIndex warranty={warranty}/>
        } else if (tab === 'system-history') {
            return <SystemHistoryIndex warranty={warranty}/>
        } else if (tab === 'monitoring') {
            return <MonitoringShow warranty={warranty}/>
        }

        return <WarrantiesShow warranty={warranty} onChange={fetchWarranty}/>
    }
    const heading = <WarrantyHeader
        warranty={warranty}
        backUrl={`/${type}${sessionStorage.get(`preservedQuery.${type}`)}`}
    />

    return <AuthLayout heading={heading} breadcrumb={getBreadcrumb()}>
        <WarrantyNavigation warranty={warranty} onChange={fetchWarranty}/>
        {getPage()}
    </AuthLayout>
}

export default Show
