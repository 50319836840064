import { FormEvent, useEffect, useMemo, useState } from 'react'

import { Button, Form } from '@/components'
import Contacts from '@/components/forms/Contacts'
import { useToastNotifications } from '@/hooks'
import { Company, User } from '@/models'

type UsersEditFormProps = {
    company: Company,
    onSuccess?: (res?: any) => void
    onExit: () => void
}

const UsersEditForm = ({ company, ...props }: UsersEditFormProps) => {
    const { success } = useToastNotifications()
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<any>({})
    const getForm = (): any => ({
        company_id: company.id,
        role: company.isAffiliate ? 'conglomerate' : 'contractor',
        contacts: company.users.map(item => ({
            id: item.id,
            company_id: company.id,
            name: item.name,
            email: item.email,
            job_title: item.job_title,
            phone: item.phone
        }))
    })
    const [form, setForm] = useState<any>(getForm())

    useEffect(() => {
        setForm(getForm())
    }, [company])

    const handleChange = ({ target: { name, value, type, checked } }: any) => {
        setForm({ ...form, [name]: type === 'checkbox' ? checked : value })
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setProcessing(true)
        setErrors({})
        try {
            const res = await Promise.allSettled(form.contacts.map((contact: any) =>
                contact.is_new ? User.store(contact) : new User(contact).update(contact)))
            const errors = res.reduce((acc: { contacts: any }, item, index) => {
                if (item.status === 'rejected') {
                    return {
                        contacts: {
                            ...acc.contacts,
                            [index]: item.reason.errors
                        }
                    }
                }
                return acc
            }, { contacts: {} })
            if (Object.keys(errors.contacts).length) {
                setErrors(errors)
            } else {
                if (props.onSuccess) await props.onSuccess()
                success('Users successfully created/updated.')
                setTimeout(() => {
                    props.onExit()
                }, 1000)
            }
        } finally {
            setProcessing(false)
        }
    }
    const isDirty = useMemo(() => Object.keys(form).some(key => {
        if (key === 'contacts') {
            return form.contacts.some((item: any, index: number) => Object.keys(item)
                .some(key => !getForm().contacts[index] || item[key] !== getForm().contacts[index][key]))
        }
        return getForm()[key]?.toString() !== form[key]?.toString()
    }), [form, company])

    return <Form isDirty={isDirty} confirmation className="grid lg:grid-cols-2 gap-6" noValidate onSubmit={handleSubmit}>
        <Contacts
            form={form}
            errors={errors}
            onChange={handleChange}
        />

        <div className="lg:col-span-2 flex flex-col gap-3 md:flex-row justify-end">
            <Button design="btn-secondary-gray" hasError onClick={props.onExit} type="button">
                Exit Edit Mode Without Saving
            </Button>
            <Button processing={processing} disabled={!isDirty}>
                Save and Exit
            </Button>
        </div>
    </Form>
}

export default UsersEditForm
