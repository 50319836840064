import { ReactNode, useEffect, useState } from 'react'

import { Input } from '@/components/ui'

type OptionProps = {
    value: string | number,
    title: string
}
type SearchableSelectProps = {
    label?: string
    placeholder?: string
    id: string
    className?: string
    name?: string
    options: Array<OptionProps>
    value: string | number
    errors?: string | string[]
    hint?: string
    onChange: any
    preIcon?: ReactNode
    postIcon?: ReactNode
    size?: 'select-md' | 'select-sm'
    disabled?: boolean
    readonly?: boolean
    inputValue?: string
    'data-test'?: string
}
const SearchableSelect = ({ id, name, className, value, inputValue, placeholder, label, options, errors, 'data-test': dataTest, ...props }: SearchableSelectProps) => {
    const [stateValue, setStateValue] = useState(options.find(item => item.value === value)?.title || '')
    const handleChange = (e: any) => {
        setStateValue(e.target.value)
        if (!e.target.value && value) {
            props.onChange({ target: { name, value: '' } })
        }
    }

    const getSuggestions = () => options
        .filter(item => item.title.toString().toLowerCase().includes(stateValue.toString().toLowerCase()))
        .map(item => item.title)
        .slice(0, 5)

    const handleSuggestionSelect = (index: number) => {
        const title = getSuggestions()[index]
        const option = options.find(item => item.title === title)
        props.onChange({ target: { name, value: option?.value || '' } })
        setStateValue(title)
    }

    useEffect(() => {
        const option = options.find(item => item.value === parseInt(value.toString()))
        if (option) setStateValue(option.title)
    }, [value, options])

    useEffect(() => {
        if (typeof inputValue === 'string' && inputValue !== stateValue) setStateValue(inputValue)
    }, [inputValue])

    return <Input
        id={id}
        name={name}
        value={stateValue}
        suggestions={getSuggestions()}
        placeholder={placeholder}
        errors={errors}
        label={label}
        onChange={handleChange}
        autoComplete="off"
        onSuggestionSelect={handleSuggestionSelect}
        preIcon={props.preIcon}
        postIcon={props.postIcon}
        data-test={dataTest}
        disabled={props.disabled}
        className={className}
    />
}

export default SearchableSelect
