import { useState } from 'react'

import { DeviceAlertsCard, IconChevronDown } from '@/components'
import { Device, GeneracSystem, Warranty } from '@/models'

type DeviceDetailsGeneracProps = {
    warranty: Warranty
}

const DeviceDetailsGenerac = ({ warranty }: DeviceDetailsGeneracProps) => {
    const [openSystems, setOpenSystems] = useState<(string | number)[]>([]) // eslint-disable-line
    const [openCategories, setOpenCategories] = useState<(string | number)[]>([]) // eslint-disable-line

    const handleToggleSystemClick = (id: string | number) => {
        setOpenSystems(state => state.includes(id) ? state.filter(item => item !== id) : [...state, id])
    }
    const handleToggleCategoryClick = (id: string | number) => {
        setOpenCategories(state => state.includes(id) ? state.filter(item => item !== id) : [...state, id])
    }

    const getIsAllExpanded = (system: GeneracSystem) =>
        openSystems.includes(system.id) &&
        system.deviceCategories.every(item => openCategories.includes(item.id))

    const handleToggleAllClick = (system: GeneracSystem) => {
        if (getIsAllExpanded(system)) {
            setOpenSystems(state => state.filter(item => item !== system.id))
            setOpenCategories(state => state.filter(item => system.deviceCategories.every(({ id }) => item !== id)))
        } else {
            setOpenSystems(state => state.includes(system.id) ? state : [...state, system.id])
            setOpenCategories(state => [
                ...state,
                ...system.deviceCategories.filter(item => !state.includes(item.id)).map(item => item.id)
            ])
        }
    }

    return <div className="flex flex-col gap-6">
        {warranty.homeowner?.system.generacSystems?.map(system =>
            <div key={system.id} className="font-body">
                <div className="flex items-center bg-primary-100 rounded-lg px-6 py-4">
                    <button
                        className="flex gap-2 items-center grow"
                        onClick={() => handleToggleSystemClick(system.id)}
                    >
                        <IconChevronDown className={`stroke-gray-700 transition-all ${openSystems.includes(system.id) ? 'rotate-180' : ''}`}/>
                        {system.systemStatus.iconBadge('xs')}
                        <h3 className="text-gray-900 font-semibold text-lg">System {system.serialNumber}</h3>

                        <div className="text-gray-500 flex items-center gap-2">
                            {Device.getIcon('error', 'sm', false)} Error ({system.erroredDevices.length})
                        </div>

                        <div className="text-gray-500 flex items-center gap-2">
                            {Device.getIcon('normal', 'sm', false)} Success ({system.normalDevices.length})
                        </div>

                        <div className="text-gray-500 flex items-center gap-2">
                            {Device.getIcon('disabled', 'sm', false)} Status Unavailable ({system.disabledDevices.length})
                        </div>
                    </button>
                    <button className="text-primary-800" onClick={() => handleToggleAllClick(system)}>
                        {getIsAllExpanded(system) ? 'Collapse' : 'Expand'} All
                    </button>
                </div>

                {openSystems.includes(system.id) && <div className="mt-6 flex flex-col gap-6">
                    {system.deviceCategories.map(item =>
                        !!item.devices.length && <div className="bg-gray-100 rounded-lg p-6" key={item.id}>
                            <button
                                onClick={() => handleToggleCategoryClick(item.id)}
                                className="flex items-center gap-2 w-full"
                            >
                                <IconChevronDown
                                    className={`stroke-gray-700 transition-all ${openCategories.includes(item.id) ? 'rotate-180' : ''}`}
                                />

                                {item.title} ({item.devices.length}) {item.statusIcon}
                            </button>

                            {openCategories.includes(item.id) && !!item.devices.length &&
                                <div className="grid lg:grid-cols-3 gap-6 mt-6">
                                    {item.devices.map((item, index) => <DeviceAlertsCard key={index} device={item}/>)}
                                </div>}
                        </div>)}
                </div>}
            </div>)}
    </div>
}

export default DeviceDetailsGenerac
