import { ReactNode, useState } from 'react'

import { Button, TextArea, Input, Popup, IconXCircle } from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyCancelFormProps = {
    children: ReactNode
    warranty: Warranty
    disabled?: boolean
    className?: string
    onChange: any
}

const WarrantyCancelPopup = ({
    children,
    warranty,
    disabled = false,
    className,
    ...props
}: WarrantyCancelFormProps) => {
    const auth = useAuth()
    const [isOpen, setIsOpen] = useState(false)
    const [errors, setErrors] = useState<any>({})
    const [processing, setProcessing] = useState(false)

    const [form, setForm] = useState({
        reason: '',
        contact_email: ''
    })

    const getIsSameDay = () => +new Date(warranty.created_at) > +new Date().subtract('day', 1)

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        setErrors({})
        try {
            if (auth?.user?.isAdminOrStaff || getIsSameDay()) {
                await warranty.destroy(form)
            } else {
                await warranty.submitCancellationRequest(form)
            }
            setIsOpen(false)
            if (props.onChange) props.onChange()
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            }
        } finally {
            setProcessing(false)
        }
    }

    const handleDelete = () => {
        if (disabled) return
        setIsOpen(true)
    }

    const handleChange = ({ target: { name, value } }: any) => {
        setForm({ ...form, [name]: value })
    }

    const getTitle = () => {
        if (auth.user?.isContractor) {
            if (getIsSameDay()) {
                return 'Cancel Same-Day Registered Warranty'
            }
            return 'Warranty Cancellation Request'
        }
        return 'Warranty Cancellation'
    }

    return <>
        <span role="button" tabIndex={-1} onKeyDown={handleDelete} className={className} onClick={handleDelete}>
            {children}
        </span>
        <Popup open={isOpen} onClose={() => setIsOpen(false)} className="min-w-[315px]">
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 text-gray-900" noValidate>
                <p className="flex items-center gap-2 text-gray-900 text-lg font-semibold"><IconXCircle className="stroke-error-600 w-6 h-6"/>
                    {getTitle()}
                </p>
                {auth.user?.isAdminOrStaff && <span className="text-gray-500">
                    Do you want to cancel <b>Plan ID {warranty.policy_num} </b> warranty?
                </span>}

                {auth.user?.isContractor && (getIsSameDay()
                    ? <>
                        <span className="text-gray-500 font-light">
                            Do you want to cancel this warranty?
                        </span>

                        <span className="text-gray-500 font-light">
                            Since your cancellation request is being made <span className="font-medium">within 24 hours</span> of registration, we will process it immediately. <br/>
                            Please note that if a cancellation request is submitted <span className="font-medium">after 24 hours</span>, it will be reviewed by our Solar Insure staff to ensure any necessary billing adjustments are made. Thank you for choosing our warranty services.
                        </span>
                    </>
                    : <>
                        <span className="text-gray-500 font-light">
                            Do you want to cancel <span className="font-medium">Plan ID {warranty.policy_num}</span> warranty?
                        </span>

                        <span className="text-gray-500 font-light">
                            Please note <span className="font-medium">the reason for cancellation</span> below. A Solar Insure representative will contact you shortly to review the request. If any adjustments to billing are necessary, they will be reflected accordingly in your subsequent invoice.
                        </span>
                    </>)}

                <TextArea
                    id="email"
                    name="reason"
                    label="Cancellation Reason"
                    onChange={handleChange}
                    value={form.reason}
                    placeholder="Enter cancellation reason"
                    errors={errors.reason}
                />

                <Input
                    id="contact-email"
                    name="contact_email"
                    label="Contact Email"
                    type="email"
                    placeholder="you@company.com"
                    onChange={handleChange}
                    value={form.contact_email}
                    errors={errors.contact_email}
                />

                <div className="flex flex-col lg:flex-row gap-2 justify-center mt-2">
                    <Button className="whitespace-nowrap" design="btn-secondary-gray" hasError onClick={() => setIsOpen(false)} type="button">No, Exit without Cancelling</Button>
                    <Button className="whitespace-nowrap" processing={processing}>Yes, Cancel Warranty</Button>
                </div>
            </form>
        </Popup>
    </>
}

export default WarrantyCancelPopup
