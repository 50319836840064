import { useEffect, useState } from 'react'

import { Popup, IconUsers01, Input, Button, SearchableSelect, CustomSelect } from '@/components'
import { useToastNotifications } from '@/hooks'
import { Company, User } from '@/models'

type ImpersonationModePopupProps = {
    isOpen: boolean,
    onClose: any
}

const EnterImpersonationModePopup = ({ isOpen, ...props }: ImpersonationModePopupProps) => {
    const [errors, setErrors] = useState<any>({})
    const [processing, setProcessing] = useState(false)
    const { success } = useToastNotifications()

    const [companies, setCompanies] = useState<Company[]>([])
    const [form, setForm] = useState<any>({
        company_name: '',
        company_id: '',
        password: '',
        role: ''
    })

    const fetchCompanies = async () => {
        const data = await Company.autocomplete()
        setCompanies(data)
    }

    const getFilteredCompanies = () => companies.filter((item: Company) => item.type === form.role)

    useEffect(() => {
        fetchCompanies()
    }, [])

    useEffect(() => {
        if (getFilteredCompanies().every(item => item.id?.toString() !== form.company_id?.toString())) {
            setForm((form: any) => ({ ...form, company_id: '', company_name: '' }))
        }
    }, [form.role])
    useEffect(() => {
        const company = getFilteredCompanies().find(item => item.id === form.company_id)
        if (company) {
            setForm((form: any) => ({ ...form, company_name: company.name }))
        }
    }, [form.company_id])

    const handleChange = ({ target: { name, value } }: any) => {
        setForm((form: any) => ({ ...form, [name]: value }))
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setErrors({})
        setProcessing(true)
        try {
            await User.impersonate(form.company_id, form.password)
            props.onClose()
            const company = companies.find((item: Company) => item.id === form.company_id)
            const role = User.allAccountTypes.find(item => item.value === form.role)
            success(`Successfully selected the role "${role?.shortTitle}" for "${company?.name}" for the user ${new User().impersonateEmail}. Please log out and then log in with the username ${new User().impersonateEmail} using the password you just provided.`, 'Success!', 10000)
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    const isDirty = () => form.role || form.company_id || form.password

    return <Popup open={isOpen} onClose={props.onClose} className="w-full lg:w-[703px] lg:px-10">
        <div className="flex items-center gap-2 mb-4">
            <IconUsers01 size="xl" className="stroke-primary-600"/>
            <span className="font-semibold text-xl font-display">Impersonation Mode</span>
        </div>
        <div className="bg-warning-50 border border-warning-400 rounded-lg p-4 text-gray-500">
            <span className="font-semibold">In impersonation mode</span>, any actions performed will directly affect the
            production data of the impersonated company. Only the engineering team has access.
        </div>
        <div className="mt-2 text-gray-500">
            Select a company and role to view the Daybreak Platform from their perspective.
        </div>
        <form className="mt-8" noValidate onSubmit={handleSubmit} autoComplete="off">
            <div className="flex flex-col md:flex-row gap-2 w-full items-start md:items-center justify-between mb-4">
                <CustomSelect
                    id="impersonate-role"
                    label="Select Role"
                    options={[
                        { value: '', title: 'None' },
                        ...User.companyAccountTypes.map(item => ({ ...item, title: item.shortTitle }))
                    ]}
                    value={form.role}
                    name="role"
                    onChange={handleChange}
                    className="w-full md:w-unset"
                />
                <SearchableSelect
                    disabled={!form.role}
                    id="impersonate-company-id"
                    name="company_id"
                    label="Select Company"
                    value={form.company_id}
                    inputValue={form.company_name}
                    errors={errors.company_id}
                    placeholder="None"
                    options={getFilteredCompanies().map(item => ({
                        value: item.id,
                        title: item.name
                    }))}
                    onChange={handleChange}
                    className="w-full md:w-unset"
                />
                <Input
                    disabled={!form.role}
                    id="impersonate-password"
                    name="password"
                    label="Password"
                    placeholder="Password"
                    value={form.password}
                    type="password"
                    onChange={handleChange}
                    className="w-full md:w-unset"
                    autoComplete="new-password"
                />
            </div>

            <div className="lg:col-span-2 flex justify-end">
                <Button
                    type="button" design="btn-secondary-gray" processing={processing} hasError onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button className="ml-3" processing={processing} disabled={!isDirty()}>
                    Proceed
                </Button>
            </div>
        </form>
    </Popup>
}

export default EnterImpersonationModePopup
