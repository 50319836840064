import { useState } from 'react'

import { Button, Input } from '@/components'
import { LoginWrapper } from '@/containers'
import { useToastNotifications } from '@/hooks'
import { AuthUser } from '@/models'

const ForgotPassword = () => {
    const { success } = useToastNotifications()
    const [errors, setErrors] = useState<any>({})
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState({ email: '' })

    const handleChange = (e: any) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setErrors({})
        setProcessing(true)
        try {
            await AuthUser.forgotPassword(form)
            success('We have e-mailed your password reset link.')
            setForm({ email: '' })
        } catch (err: any) {
            if (err.errors) setErrors(err.errors)
        } finally {
            setProcessing(false)
        }
    }

    return <LoginWrapper>
        <div className="w-80 h-auto">
            <h1 className="text-3xl text-left font-medium font-display" data-test="forgot-password-title">
                Forgot Password
            </h1>
            <form onSubmit={handleSubmit} noValidate>
                <Input
                    id="email"
                    name="email"
                    type="email"
                    className="mb-4 mt-4"
                    placeholder="Enter your email"
                    onChange={handleChange}
                    value={form.email}
                    errors={errors.email}
                    data-test="forgot-password-email"
                />
                <div className="flex justify-start-left">
                    <Button className="w-2/6 text-white" disabled={processing} data-test="forgot-password-submit-button">
                        Send
                    </Button>
                    <Button href="/login" design="btn-secondary-gray" className="w-2/6 ml-4" disabled={processing} data-test="forgot-password-sign-in-button">
                        Sign in
                    </Button>
                </div>

            </form>
        </div>
    </LoginWrapper>
}

export default ForgotPassword
