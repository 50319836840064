import { Badge, IconCheck } from '@/components'
import { WarrantyStatusEnum } from '@/enums'

type StatusColor =
    'yellow'
    | 'success'
    | 'orange'
    | 'gray'
    | 'indigo'
    | 'error'

const statuses = [
    {
        key: WarrantyStatusEnum.EDIT_REQUESTED,
        title: 'Edit Requested',
        color: 'yellow',
        isDefault: true
    },
    {
        key: WarrantyStatusEnum.ACTIVE,
        title: 'Active',
        color: 'success',
        isDefault: true
    },
    {
        key: WarrantyStatusEnum.POTENTIAL_DUPLICATE,
        title: 'Potential Duplicate',
        color: 'orange',
        isDefault: true
    },
    {
        key: WarrantyStatusEnum.CANCELLATION_REQUESTED,
        title: 'Cancellation Requested',
        color: 'gray',
        isDefault: true
    },
    {
        key: WarrantyStatusEnum.REINSTATEMENT_REQUESTED,
        title: 'Reinstatement Requested',
        color: 'indigo',
        isDefault: true
    },
    {
        key: WarrantyStatusEnum.CANCELED,
        title: 'Canceled',
        color: 'error',
        isDefault: false
    }
]

export default class WarrantyStatus {
    [key: string]: any

    public key = 'active'

    public color: StatusColor = 'success'

    public title = 'Active'

    public isDefault = false


    constructor(object: any = {}) {
        Object.keys(object).forEach((key: string) => {
            this[key as keyof WarrantyStatus] = object[key]
        })
    }

    static index() {
        return statuses.map(item => new WarrantyStatus(item))
    }

    static show(key: WarrantyStatusEnum) {
        return new WarrantyStatus(statuses.find(item => item.key === key))
    }

    static get defaultStatuses() {
        return WarrantyStatus.index().filter(item => item.isDefault).map(item => item.key)
    }

    badge(isActive = false, showIcon = true) {
        let className = ''
        let activeClassName = ''
        let iconClassName = ''
        switch (this.color) {
            case 'yellow': {
                className = 'bg-yellow-50'
                activeClassName = 'border-yellow-500'
                iconClassName = 'stroke-yellow-500 bg-yellow-500'
                break
            }
            case 'success': {
                className = 'bg-success-50'
                activeClassName = 'border-success-500'
                iconClassName = 'stroke-success-500 bg-success-500'
                break
            }
            case 'orange': {
                className = 'bg-orange-50'
                activeClassName = 'border-orange-500'
                iconClassName = 'stroke-orange-500 bg-orange-500'
                break
            }
            case 'gray': {
                className = 'bg-gray-100'
                activeClassName = 'border-gray-500'
                iconClassName = 'stroke-gray-500 bg-gray-500'
                break
            }
            case 'indigo': {
                className = 'bg-indigo-50'
                activeClassName = 'border-indigo-500'
                iconClassName = 'stroke-indigo-500 bg-indigo-500'
                break
            }
            case 'error': {
                className = 'bg-error-50'
                activeClassName = 'border-error-500'
                iconClassName = 'stroke-error-500 bg-error-500'
            }
        }
        return <Badge
            className={`text-xs text-gray-800 flex gap-1.5 items-center ${className} ${isActive ? `${activeClassName} border` : ''}`}
        >
            {showIcon && (isActive
                ? <IconCheck className={`${iconClassName} !bg-transparent`} size="xs"/>
                : <div className="w-3 h-3 flex items-center justify-center">
                    <div className={`${iconClassName} w-1.5 h-1.5 rounded-full`}/>
                </div>)}
            {this.title}
        </Badge>
    }
}
