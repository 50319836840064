import { Placement } from '@popperjs/core'
import Tippy from '@tippyjs/react'
import { ReactNode, useState } from 'react'


type TooltipProps = {
    children: ReactNode
    content: ReactNode
    disabled?: boolean
    options?: any
    className?: string
    color?: 'default' | 'white'
    placement?: Placement
    trigger?: string
    interactive?: boolean
    hideOnClick?: boolean
    backdrop?: boolean
}
const Tooltip = ({
    children,
    content,
    className,
    color = 'default',
    disabled,
    placement,
    trigger = 'mouseenter focus',
    interactive = false,
    hideOnClick = true,
    backdrop = false
}: TooltipProps) => {
    const [isShown, setIsShown] = useState(false)

    return <>
        {backdrop && isShown && <div className="fixed inset-0 z-50 bg-black bg-opacity-50 pointer-events-none"/>}
        <Tippy
            content={content}
            disabled={disabled}
            trigger={trigger}
            hideOnClick={hideOnClick}
            interactive={interactive}
            className={color}
            placement={placement}
            maxWidth={540}
            onShow={() => setIsShown(true)}
            onHide={() => setIsShown(false)}
        >
            <div className={className}>
                {children}
            </div>
        </Tippy>
    </>
}

export default Tooltip
