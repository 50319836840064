// import { useEffect } from 'react'

import { Button, IconArrowLeft, IconArrowRight } from '@/components'

type PaginationProps = {
    current: number
    total: number
    className?: string
    perPage?: number
    onChange?: any
    'data-test'?: string
}
const Pagination = ({ className, current, total, perPage = 10, 'data-test': dataTest, ...props }: PaginationProps) => {
    const getLastPage = () => Math.ceil(total / perPage)

    const handleChange = (page: number) => {
        props.onChange('page', page)
    }

    // useEffect(() => {
    //     if (getLastPage() < current) props.onChange(getLastPage() || 1)
    // }, [total, current])

    const getPages = () => {
        if (getLastPage() < 8) return Array.from({ length: getLastPage() }, (_, i) => i + 1)

        const arr = []
        if (current > 1) arr.push(1)
        if (current > getLastPage() - 3) arr.push(2)
        if (current > getLastPage() - 2) arr.push(3)
        if (current > 3) arr.push(0)
        if (current === getLastPage()) arr.push(current - 2)
        if (current > 2) arr.push(current - 1)
        arr.push(current)
        if (getLastPage() - current > 1) arr.push(current + 1)
        if (current === 1) arr.push(current + 2)
        if (getLastPage() - current > 2) arr.push(0)
        if (current < 3) arr.push(getLastPage() - 2)
        if (current < 4) arr.push(getLastPage() - 1)
        if (current < getLastPage()) arr.push(getLastPage())

        return arr
    }

    return <div {...props} className={`pagination ${className || ''}`}>
        <Button type="button" design="btn-secondary-gray" className="mr-6" data-test={`${dataTest}-previous-page-button`} onClick={() => handleChange(current - 1)} disabled={current === 1}>
            <IconArrowLeft className="lg:mr-2"/> <span className="hidden lg:inline">Previous</span>
        </Button>

        {getPages().map((item, index) =>
            <button
                key={index}
                className={`pagination-button ${current === item ? 'active' : ''} ${item === current + 1 ? 'next' : ''} ${item === current - 1 ? 'prev' : ''}`}
                disabled={!item}
                type="button"
                onClick={() => handleChange(item)}
                data-test={`${dataTest}-${item}-page-button`}
            >
                {item || '...'}
            </button>)}

        <Button type="button" design="btn-secondary-gray" className="ml-6" data-test={`${dataTest}-next-page-button`} onClick={() => handleChange(current + 1)} disabled={current === getLastPage() || !getLastPage()}>
            <span className="hidden lg:inline">Next</span> <IconArrowRight className="lg:ml-2"/>
        </Button>
    </div>
}

export default Pagination
