import { useEffect, useState } from 'react'

import {
    Badge,
    CustomSelect,
    DatePicker,
    Table
} from '@/components'
import { useNavigateWithQuery, useQuery } from '@/hooks'
import { SystemHistory, SystemStatus, SystemStatusCategory, Warranty } from '@/models'


const SystemHistoryIndex = ({ warranty }: { warranty: Warranty }) => {
    const query = useQuery()
    const navigateWithQuery = useNavigateWithQuery()
    const getDefaultFilters = () => ({ status: query.status || '' })
    const getDefaultDateRange = () => ({
        // Adding ' 00:00' to the end of string date to clear timezone
        start: query.start_date ? new Date(`${query.start_date} 00:00`) : null,
        end: query.end_date ? new Date(`${query.end_date} 00:00`) : null
    })
    const [abortController, setAbortController] = useState<AbortController | null>()
    const [processing, setProcessing] = useState(false)
    const [systemHistories, setSystemHistories] = useState<SystemHistory[]>([])
    const [meta, setMeta] = useState<any>(null)
    const [filters, setFilters] = useState(getDefaultFilters())
    const [dateRange, setDateRange] = useState(getDefaultDateRange())

    const handleDateChange = ({ target: { value } }: any) => {
        navigateWithQuery({
            page: null,
            start_date: value.start?.toISODate(),
            end_date: value.end?.toISODate()
        })
        setDateRange(value)
    }

    const handleFiltersChange = ({ target: { name, value } }: any) => {
        navigateWithQuery({ page: null, [name]: value })
        setFilters({ ...filters, [name]: value })
    }

    const fetchEvents = async (tableFilters: any = {}) => {
        abortController?.abort('canceled')
        setProcessing(true)
        const controller = new AbortController
        setAbortController(controller)
        try {
            const { data, meta } = await SystemHistory.index({
                id: warranty.homeowner?.id,
                ...tableFilters,
                start_date: dateRange.start?.toISODate(),
                end_date: dateRange.end?.toISODate(),
                ...filters
            }, { signal: controller.signal })
            setSystemHistories(data)
            setMeta(meta)
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        fetchEvents(meta ? { ...query, page: 1 } : query)
    }, [filters, dateRange])

    const getStatusOptions = () => [
        {
            value: '',
            title: 'Any Status'
        },
        ...SystemStatusCategory.all.reduce((acc: any[], item, index) =>
            [
                ...acc,
                {
                    value: item.value,
                    title: <span className="font-semibold w-full">
                        {item.title}
                    </span>,
                    group: index > 0
                },
                ...item.tiers.reduce((acc: any[], item, _, all) =>
                    [
                        ...acc,
                        ...all.length > 1 ? [{
                            value: item.value,
                            title: <span className="font-semibold w-full text-sm">
                                • {item.title}
                            </span>
                        }] : [],
                        ...item.statuses.map((item: SystemStatus) => ({
                            value: item.value,
                            title: <div className="flex w-full gap-2 items-center font-body text-gray-900 text-sm">
                                {item.iconBadge('xs')} {item.title}
                            </div>
                        }))
                    ], [])
            ], [])
    ]

    return <>
        <div className="flex flex-col sm:flex-row mb-2 sm:mb-6 gap-3 items-center">
            <DatePicker
                clearable
                id="date-range"
                name="date"
                placeholder="Select dates"
                value={dateRange}
                onChange={handleDateChange}
                options={{ singleMode: false, maxDate: new Date() }}
            />

            <CustomSelect
                name="status"
                id="status"
                options={getStatusOptions()}
                value={filters.status}
                onChange={handleFiltersChange}
            />

            <Badge className="bg-primary-50 text-primary-600 whitespace-nowrap">
                { meta?.total?.format()} {meta?.overall_count && meta?.overall_count !== meta?.total ? ` of ${meta?.overall_count.format()}` : '' } Events
            </Badge>
        </div>

        <Table
            filters={filters}
            processing={processing}
            meta={meta}
            onChange={fetchEvents}
            searchable={false}
            className="mb-6"
            columns={[
                { field: 'status', title: 'System Status' },
                { field: 'duration_seconds', title: 'Status Duration' },
                { field: 'detection_start_date', title: 'Detection Start Date' },
                { field: 'detection_end_date', title: 'Detection End Date' }
            ]}
            rows={systemHistories.map(item => ({
                id: item.id,
                status: <div className="flex items-center gap-3">
                    {item.system.systemStatus.iconBadge()}
                    {item.system.systemStatus.title}
                </div>,
                status_raw: item.status,
                duration_seconds: (() => {
                    const timeUnits: any = {
                        year: 31536000,
                        month: 2592000,
                        day: 86400,
                        hour: 3600,
                        minute: 60,
                        second: 1
                    }

                    for (const unit in timeUnits) {
                        if (item.duration_seconds >= timeUnits[unit]) {
                            const value = Math.floor(item.duration_seconds / timeUnits[unit])
                            return `${value} ${unit.pluralize(value)}`
                        }
                    }

                    return '0 seconds'
                })(),
                detection_start_date: item.detection_start_date?.format({
                    hour: '2-digit',
                    minute: '2-digit'
                }) || '',
                detection_end_date: item.detection_end_date?.format({
                    hour: '2-digit',
                    minute: '2-digit'
                }) || ''
            }))}
        />

        <p className="italic text-sm text-gray-500">
            History collection started on {new Date('02/29/2024').format()}
        </p>
    </>
}

export default SystemHistoryIndex
