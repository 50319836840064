import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AddressForm, Button, CompanyForm, ContactsForm, Input, SearchableSelect } from '@/components'
import { useToastNotifications } from '@/hooks'
import { Company, User } from '@/models'

const CompanyCreateForm = ({ role }: { role: string }) => {
    const navigate = useNavigate()
    const { success } = useToastNotifications()
    const [errors, setErrors] = useState<any>({})
    const [processing, setProcessing] = useState(false)
    const [companies, setCompanies] = useState<Company[]>([])
    const [form, setForm] = useState<any>({
        company_id: '',

        email: '',
        name: '',
        unique_id: '',
        policy_rate: 0,
        warranty_match_method: '',
        solar_commission_rate: 0,
        battery_commission_rate: 0,
        monthly_sales_quota: '',
        conglomerate_id: '',

        type: '',

        address: '',

        contacts: [
            {
                is_new: true,
                name: '',
                email: '',
                role: role.includes('contractor') ? 'contractor' : 'conglomerate',
                job_title: '',
                phone: ''
            }
        ]
    })

    useEffect(() => {
        const clone = { ...form }


        if (['software_affiliate', 'lender_affiliate'].includes(role)) {
            delete clone.conglomerate_id
            delete clone.policy_rate
            clone.warranty_match_method = ''
            if (role === 'software_affiliate') {
                clone.solar_commission_rate = ''
                clone.battery_commission_rate = ''
            } else {
                delete clone.solar_commission_rate
                delete clone.battery_commission_rate
            }
        }
        if (['contractor'].includes(role)) {
            clone.conglomerate_id = ''
            clone.policy_rate = ''
            delete clone.warranty_match_method
            delete clone.solar_commission_rate
            delete clone.battery_commission_rate
        }

        if (role === 'software_affiliate') {
            clone.type = 'software_affiliate'
            clone.role = 'conglomerate'
        } else if (role === 'lender_affiliate') {
            clone.type = 'lender_affiliate'
            clone.role = 'conglomerate'
        } else {
            clone.type = 'contractor'
        }

        setForm(clone)
    }, [role])

    const fetchCompanies = async () => {
        const data = await Company.autocomplete()
        setCompanies(data)
    }

    useEffect(() => {
        fetchCompanies()
    }, [])

    const handleChange = ({ target: { name, value, checked, type } }: any) => {
        setForm((form: any) => ({ ...form, [name]: type === 'checkbox' ? checked : value }))
        if (name === 'company_id') {
            const company = companies.find(item => item.id?.toString() === value?.toString())
            setForm((form: any) => ({ ...form, unique_id: company?.unique_id || '' }))
        }
    }

    const storeCompany = async () => {
        try {
            const res = await Company.store(form)
            success('Company was successfully created.')
            navigate(`/companies/${res.data.id}`)
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            }
        }
    }

    const storeUsers = async () => {
        if (!form.company_id) {
            setErrors({ company_id: 'The company field is required.' })
            return
        }
        for (const index in form.contacts) {
            const contact = form.contacts[index]
            try {
                await User.store({
                    role: role === 'contractor-user' ? 'contractor' : 'conglomerate',
                    company_id: form.company_id,
                    ...contact
                })
            } catch (err: any) {
                if (err.errors) {
                    setErrors((errors: any) => ({ contacts: { ...errors.contacts || {}, [index]: err.errors } }))
                }
                throw err
            }
        }
        navigate(`/companies/${form.company_id}`)
        success('Users successfully created.')
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setErrors({})
        setProcessing(true)
        try {
            if (['contractor-user', 'affiliate-user'].includes(role)) {
                await storeUsers()
            } else {
                await storeCompany()
            }
        } catch (err: any) {
            // do nothing
        } finally {
            setProcessing(false)
        }
    }

    return <form className="lg:col-span-2 grid lg:grid-cols-2 gap-6" noValidate onSubmit={handleSubmit}>
        <h2 className="lg:col-span-2 text-lg">Company Info</h2>

        {['contractor-user', 'affiliate-user'].includes(role) && <>
            <SearchableSelect
                id="company-id"
                name="company_id"
                label="Company Name*"
                value={form.company_id}
                errors={errors.company_id}
                placeholder="Company"
                options={companies.filter(company => {
                    if (role === 'contractor-user') {
                        return company.isContractor
                    }
                    return company.isAffiliate
                }).map(item => ({
                    value: item.id,
                    title: item.name
                }))}
                onChange={handleChange}
            />

            <Input
                disabled
                id="unique_id"
                name="unique_id"
                label="Salesforce Unique ID*"
                placeholder="Salesforce Unique ID"
                value={form.unique_id || '-'}
            />
        </>}
        {['software_affiliate', 'lender_affiliate', 'contractor'].includes(role) && <>
            <CompanyForm
                form={form}
                errors={errors}
                onChange={handleChange}
            />

            <h2 className="lg:col-span-2 text-lg">Address</h2>
            <AddressForm
                inline
                form={form}
                errors={errors}
                onChange={handleChange}
            />
        </>}

        <div className="lg:col-span-2">
            <h2 className="col-span-2 text-lg">Company Contacts</h2>
            <p className="mt-2 text-gray-500">Enter the contact information for all employees at this company that need login access to the Solar Insure Platform. Each user will receive an email to set up login credentials.</p>
        </div>

        <div className="lg:col-span-2">
            <ContactsForm
                form={form}
                errors={errors}
                onChange={handleChange}
            />
        </div>

        <div className="lg:col-span-2 flex justify-end">
            <Button type="button" design="btn-secondary-gray" hasError onClick={() => navigate(-1)}>
                Cancel
            </Button>
            <Button className="ml-3" processing={processing}>
                Create Account
            </Button>
        </div>
    </form>
}

export default CompanyCreateForm
