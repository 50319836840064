import Model from './Model'
import WarrantyStatus from './WarrantyStatus'
import { IconFlipBackward, IconXCircle } from '@/components'
import PotentialDuplicateWarrantyNotice from '@/components/warranty-histories/PotentialDuplicateWarrantyNotice'
import WarrantyEditDetails from '@/components/warranty-histories/WarrantyEditDetails'
import WarrantyRequestHandled from '@/components/warranty-histories/WarrantyRequestHandled'
import WarrantyStatusChange from '@/components/warranty-histories/WarrantyStatusChange'

const eventTypes = [
    { key: 'warranty_created' },
    {
        key: 'warranty_edited',
        Component: WarrantyEditDetails
    },
    {
        key: 'warranty_updated',
        Component: WarrantyEditDetails
    },
    {
        key: 'warranty_edit_requested',
        Component: WarrantyEditDetails
    },
    {
        key: 'warranty_edit_denied',
        title: 'edit',
        Component: WarrantyRequestHandled
    },
    {
        key: 'warranty_edit_approved',
        title: 'edit',
        action: 'edited',
        Component: WarrantyRequestHandled
    },
    {
        key: 'warranty_edit_modified_and_approved',
        Component: WarrantyEditDetails
    },
    { key: 'warranty_edit_request_cancelled' },
    {
        key: 'warranty_canceled',
        title: 'cancellation',
        icon: <IconXCircle className="stroke-error-500" size="lg"/>,
        Component: WarrantyStatusChange
    },
    {
        key: 'warranty_cancellation_requested',
        title: 'cancellation',
        icon: <IconXCircle className="stroke-error-500" size="lg"/>,
        Component: WarrantyStatusChange
    },
    {
        key: 'warranty_cancellation_request_denied',
        title: 'cancellation',
        action: 'cancelled',
        Component: WarrantyRequestHandled
    },
    {
        key: 'warranty_cancellation_request_approved',
        title: 'cancellation',
        action: 'cancelled',
        Component: WarrantyRequestHandled
    },
    { key: 'warranty_cancellation_request_cancelled' },
    {
        key: 'warranty_reinstated',
        title: 'reinstatement',
        icon: <IconFlipBackward className="stroke-error-500" size="lg"/>,
        Component: WarrantyStatusChange
    },
    {
        key: 'warranty_reinstatement_requested',
        title: 'reinstatement',
        icon: <IconFlipBackward className="stroke-error-500" size="lg"/>,
        Component: WarrantyStatusChange
    },
    {
        key: 'warranty_reinstatement_request_denied',
        title: 'reinstatement',
        action: 'reinstated',
        Component: WarrantyRequestHandled
    },
    {
        key: 'warranty_reinstatement_request_approved',
        title: 'reinstatement',
        action: 'reinstated',
        Component: WarrantyRequestHandled
    },
    { key: 'warranty_reinstatement_request_cancelled' },
    {
        key: 'potential_duplicate_found',
        Component: PotentialDuplicateWarrantyNotice
    },
    { key: 'duplicate_resolved' },
    {
        key: 'affiliate_changed',
        Component: WarrantyEditDetails
    }
]

export default class WarrantyHistory extends Model {
    static endpoint = 'api/v1/warranty/history'

    constructor(props?: any) {
        super(props)
        this.event = props.event || '-'
        try {
            this.properties = JSON.parse(props.properties)
        } catch (err: any) {
            this.properties = null
        }
        if (this.event === 'warranty_canceled' && props.old_status === 'cancellation_requested') {
            this.event = 'warranty_cancellation_request_approved'
        }
        if (this.event === 'warranty_reinstated' && props.old_status === 'reinstatement_requested') {
            this.event = 'warranty_reinstatement_request_approved'
        }
    }

    get statusObject() {
        return WarrantyStatus.show(this.status)
    }

    get eventObject(): any {
        return eventTypes.find(({ key }) => key === this.event)
    }

    get eventTitle() {
        return this.event?.replaceAll('_', ' ').capitalize() || '-'
    }
}
