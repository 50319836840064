import { Button, Dropdown, IconChevronDown, Tooltip } from '@/components'
import { useAuth } from '@/hooks'

type WarrantyCreateButtonProps = {
    className?: string
}
const WarrantyCreateButton = ({ className }: WarrantyCreateButtonProps) => {
    const auth = useAuth()

    return <Dropdown
        className={className}
        button={<Tooltip
            disabled={auth.user?.isAllowedToCreateWarranty}
            content={<div className="text-center">
                <p>Registration disabled: Certification incomplete.</p>
                <p>Please submit the required credentials to proceed with warranty registration.</p>
                <p>Contact your account manager if you have questions about your certification status.</p>
            </div>}
        >
            <Button disabled={!auth.user?.isAllowedToCreateWarranty}>
                Add New Warranty
                <IconChevronDown className="ml-2" size="md"/>
            </Button>
        </Tooltip>}
    >
        <div className="px-2">
            <Button design="btn-link-gray" className="w-full" href="/warranties/create">
                Single Warranty
            </Button>
            <Button design="btn-link-gray" className="w-full" href="/warranties/upload">
                Bulk Upload
            </Button>
        </div>
    </Dropdown>
}

export default WarrantyCreateButton
