import { Chart as ChartJs } from 'chart.js'
import { useEffect, useRef, useState } from 'react'

import {
    Button,
    CustomSelect,
    IconExpand01,
    IconMinimize01,
    IconSearch,
    LineChart,
    SearchableSelect, Tabs
} from '@/components'
import { useAuth } from '@/hooks'
import { Company } from '@/models'
import { api, feature, WarrantySalesItemType } from '@/services'

type SalesReportProps = {
    className?: string
    onChange: (key: string) => void
    processing: boolean
    onExpand?: (key: string, isExpanded: boolean) => void
    isExpanded?: boolean
}

const SalesReport = ({ className, processing, isExpanded, ...props }: SalesReportProps) => {
    const auth = useAuth()
    const didMount = useRef(false)
    const [contractors, setContractors] = useState<Company[]>([])
    const [filters, setFilters] = useState<any>({
        sort_by: 'month',
        contractor_id: ''
    })
    const [listSales, setListSales] = useState<Array<WarrantySalesItemType>>([])
    const [abortController, setAbortController] = useState<AbortController | null>()
    const [sales, setSales] = useState({
        month: 0,
        year: 0
    })

    useEffect(() => () => {
        abortController?.abort('canceled')
    }, [abortController])

    const getStartDate = () => {
        switch (filters.sort_by) {
            case 'year':
                return new Date().subtract('y', 4).startOf('year')
            case 'week':
                return new Date().subtract('weeks', 6).startOf('week')
            case 'quarter':
                return new Date().subtract('quarter', 4).startOf('quarter')
            default:
                return new Date().startOf('month').subtract('M', 7)
        }
    }

    const getEndDate = () => {
        switch (filters.sort_by) {
            case 'year':
                return new Date().subtract('year', 1).endOf('year')
            case 'week':
                return new Date().subtract('week', 1).startOf('week')
            case 'quarter':
                return new Date().subtract('quarter', 1).endOf('quarter')
            default:
                return new Date().subtract('month', 1).endOf('month')
        }
    }

    const getDates = () => {
        const res = []
        const start = getStartDate()
        const end = getEndDate()

        while (start <= end) {
            res.push(start.clone())
            start.add(filters.sort_by, 1)
        }
        return res
    }

    const getLabels = () => {
        let format = {}
        if (filters.sort_by === 'week') {
            format = { month: 'short', day: 'numeric', year: null }
            return getDates().map(item => `${item.format(format)} - ${item.clone().endOf('week').format(format)}`)
        }
        if (filters.sort_by === 'month') {
            format = { month: 'short', day: null }
        }
        if (filters.sort_by === 'year') {
            format = { month: null, day: null }
        }
        if (filters.sort_by === 'quarter') {
            return getDates().map(item => `Q${Math.floor((item.getMonth() / 3) + 1)} ${item.format({
                month: null,
                day: null
            })}`)
        }

        return getDates().map(item => item.format(format))
    }

    const getDatasets = () => {
        const ctx = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D
        const gradient1 = ctx.createLinearGradient(0, 0, 0, 300)
        gradient1.addColorStop(0, 'rgba(62,188,218,0.1)')
        gradient1.addColorStop(0.5, 'transparent')
        const gradient2 = ctx.createLinearGradient(0, 0, 0, 300)
        gradient2.addColorStop(0, 'rgba(255,165,0,0.1)')
        gradient2.addColorStop(0.5, 'transparent')
        const gradient3 = ctx.createLinearGradient(0, 0, 0, 300)
        gradient3.addColorStop(0, 'rgba(121,109,178,0.1)')
        gradient3.addColorStop(0.5, 'transparent')
        const gradient4 = ctx.createLinearGradient(0, 0, 0, 300)
        gradient4.addColorStop(0, 'rgba(0,160,113,0.1)')
        gradient4.addColorStop(0.5, 'transparent')
        return [{
            label: 'Total Sales',
            data: listSales.map(({
                power_production_only: powerProductionOnly,
                production_with_20_year_separate_storage: productionWith20YearSeparateStorage,
                production_with_30_year_separate_storage: productionWith30YearSeparateStorage
            }: any) => powerProductionOnly + productionWith20YearSeparateStorage + productionWith30YearSeparateStorage),
            borderWidth: 2,
            borderColor: '#3ebcda',
            tension: 0.25,
            fill: true,
            backgroundColor: gradient1
        }, {
            label: '30-Year Solar Only',
            data: listSales.map(({ power_production_only: powerProductionOnly }) =>
                powerProductionOnly),
            borderWidth: 2,
            borderColor: '#ffa500',
            tension: 0.25,
            fill: true,
            backgroundColor: gradient2
        }, {
            label: '20-Year Battery',
            data: listSales.map(({ production_with_20_year_separate_storage: productionWith20YearSeparateStorage }) =>
                productionWith20YearSeparateStorage),
            borderWidth: 2,
            borderColor: '#796db2',
            tension: 0.25,
            fill: true,
            backgroundColor: gradient3
        }, ...feature('contractors-30-year-battery') || auth.user?.isAdminOrStaffOrAdvisor ? [{
            label: '30-Year Battery',
            data: listSales.map(({ production_with_30_year_separate_storage: productionWith30YearSeparateStorage }) =>
                productionWith30YearSeparateStorage),
            borderWidth: 2,
            borderColor: '#00a071',
            tension: 0.25,
            fill: true,
            backgroundColor: gradient4
        }] : []]
    }

    const fetchData = async () => {
        const controller = new AbortController
        setAbortController(controller)
        try {
            const res = await api.dashboard.warrantySales({
                start_date: getStartDate().toISODate(),
                end_date: getEndDate().toISODate(),
                sortBy: filters.sort_by,
                contractor_id: filters.contractor_id
            }, { signal: controller.signal })
            setListSales(res)
            const [year] = await api.dashboard.warrantySales({
                start_date: new Date()
                    .subtract('year', 1)
                    .startOf('year')
                    .toISODate(),
                end_date: new Date()
                    .subtract('year', 1)
                    .endOf('year')
                    .toISODate(),
                sortBy: 'year',
                contractor_id: filters.contractor_id
            }, { signal: controller.signal })
            const [month] = await api.dashboard.warrantySales({
                start_date: new Date()
                    .subtract('month', 1)
                    .startOf('month')
                    .toISODate(),
                end_date: new Date()
                    .subtract('month', 1)
                    .endOf('month')
                    .toISODate(),
                sortBy: 'month',
                contractor_id: filters.contractor_id
            }, { signal: controller.signal })
            setSales({
                year: year.power_production_only +
                    year.production_with_20_year_separate_storage +
                    year.production_with_30_year_separate_storage,
                month: month.power_production_only +
                    month.production_with_20_year_separate_storage +
                    month.production_with_30_year_separate_storage
            })
        } finally {
            props.onChange('salesReport')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])
    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true
            return
        }
        fetchData()
    }, [filters])

    const handleFiltersChange = (e: any) => {
        setFilters((filters: any) => ({ ...filters, [e.target.name]: e.target.value }))
    }

    const fetchContractors = async () => {
        const data = await Company.autocomplete()
        setContractors(data)
    }

    useEffect(() => {
        if (auth.user?.isAffiliate || auth.user?.isAdminOrStaffOrAdvisor) {
            fetchContractors()
        }
    }, [])

    const timePeriods = [
        { value: 'week', title: 'By Week' },
        { value: 'month', title: 'By Month' },
        { value: 'quarter', title: 'By Quarter' },
        { value: 'year', title: 'By Year' }
    ]

    return <div className={`h-full flex flex-col ${className || ''}`}>
        <div className="flex flex-col gap-3 sm:flex-row justify-between mb-5 flex-wrap">
            <div className="flex">
                <div className="border-r border-gray-200 pr-4">
                    <h3 className="font-semibold text-primary-700" data-test="dashboard-sales-report-last-month-value">
                        {sales.month.money()}
                    </h3>
                    <div className="text-xs text-gray-500 mt-1" data-test="dashboard-sales-report-last-month-title">
                        Last Month
                    </div>
                </div>
                <div className="pl-4">
                    <h3 className="text-gray-500" data-test="dashboard-sales-report-last-year-value">
                        {sales.year.money()}
                    </h3>
                    <div className="text-xs text-gray-500 mt-1" data-test="dashboard-sales-report-last-year-title">
                        Last Year
                    </div>
                </div>
            </div>

            <div className="flex gap-4">
                {(auth.user?.isAffiliate || auth.user?.isAdminOrStaffOrAdvisor) && <SearchableSelect
                    id="sales-report-contractor-id"
                    options={contractors.map(item => ({ value: item.id, title: item.name }))}
                    name="contractor_id"
                    value={filters.contractor_id}
                    onChange={handleFiltersChange}
                    placeholder="Contractor"
                    preIcon={<IconSearch className="stroke-gray-500"/>}
                    data-test="dashboard-sales-report-contractor"
                />}

                {isExpanded ? <Tabs
                    design="buttons"
                    value={filters.sort_by}
                    tabs={timePeriods.map(item => ({ id: item.value, title: item.title }))}
                    onChange={(value: string) => handleFiltersChange({ target: { name: 'sort_by', value } })}
                    data-test="dashboard-sales-report-period"
                /> : <CustomSelect
                    id="sales-report-period"
                    options={timePeriods}
                    name="sort_by"
                    value={filters.sort_by}
                    onChange={handleFiltersChange}
                    data-test="dashboard-sales-report-period"
                />}

                <div className="hidden md:block">
                    {props.onExpand && <Button design="btn-link-gray" onClick={() => props.onExpand && props.onExpand('salesReport', !isExpanded)}>
                        {isExpanded ? <IconMinimize01 size="lg"/> : <IconExpand01 size="lg"/>}
                    </Button>}
                </div>
            </div>
        </div>
        <div className="relative h-[99%] w-[99%]">
            <LineChart
                datasets={getDatasets()}
                labels={getLabels()}
                plugins={[
                    {
                        beforeDraw: (chart: ChartJs) => {
                            if (!chart.tooltip?.opacity) return
                            const { ctx, chartArea: { bottom, top } } = chart
                            ctx.save()
                            ctx.lineWidth = 1
                            ctx.beginPath()
                            ctx.setLineDash([2, 2])
                            ctx.moveTo(chart.tooltip?.caretX || 0, bottom)
                            ctx.lineTo(chart.tooltip?.caretX || 0, top)
                            ctx.strokeStyle = '#d5d3d0'
                            ctx.stroke()
                        }
                    }
                ]}
            />
        </div>
        <div className="flex justify-center mt-3 gap-3">
            <div className="flex items-center text-gray-500 text-xs" data-test="dashboard-sales-report-total-sales-label">
                <div className="rounded-full w-2 h-2 bg-brand-blue-skies mr-2" data-test="dashboard-sales-report-total-sales-icon"/>
                Total Sales
            </div>
            <div className="flex items-center text-gray-500 text-xs" data-test="dashboard-sales-report-solar-sales-label">
                <div className="rounded-full w-2 h-2 bg-orange-400 mr-2" data-test="dashboard-sales-report-solar-sales-icon"/>
                30-Year Solar Only
            </div>
            <div className="flex items-center text-gray-500 text-xs" data-test="dashboard-sales-report-20-year-battery-sales-label">
                <div className="rounded-full w-2 h-2 bg-purple-400 mr-2" data-test="dashboard-sales-report-20-year-battery-sales-icon"/>
                20-Year Battery
            </div>
            {(feature('contractors-30-year-battery') || auth.user?.isAdminOrStaffOrAdvisor) &&
                <div className="flex items-center text-gray-500 text-xs" data-test="dashboard-sales-report-30-year-battery-sales-label">
                    <div className="rounded-full w-2 h-2 bg-success-400 mr-2" data-test="dashboard-sales-report-30-year-battery-sales-icon"/>
                    30-Year Battery
                </div>}
        </div>
    </div>
}

export default SalesReport
