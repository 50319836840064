import { ReactNode, useState } from 'react'

import { Button, Popup, TextArea, Input, IconFlipBackward } from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyCancelFormProps = {
    children: ReactNode
    warranty: Warranty
    className?: string
    onChange: () => void
}

const WarrantyReinstatePopup = ({ children, warranty, className, ...props }: WarrantyCancelFormProps) => {
    const auth = useAuth()
    const [isOpen, setIsOpen] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<any>({})

    const [form, setForm] = useState({
        reason: '',
        contact_email: ''
    })

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        setErrors({})
        try {
            if (auth?.user?.isAdminOrStaff) {
                await warranty.destroy(form)
            } else {
                await warranty.submitCancellationRequest(form)
            }
            setIsOpen(false)
            props.onChange()
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            }
        } finally {
            setProcessing(false)
        }
    }

    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleChange = ({ target: { name, value } }: any) => {
        setForm({ ...form, [name]: value })
    }

    return <>
        <span role="button" tabIndex={-1} onKeyDown={handleOpen} className={className} onClick={handleOpen}>
            {children}
        </span>
        <Popup open={isOpen} onClose={() => setIsOpen(false)} className="min-w-[315px] lg:min-w-[718px]">
            {auth.user?.isAdminOrStaff && <>
                <div className="flex items-center gap-2">
                    <IconFlipBackward className="stroke-error-700" size="md"/>
                    <h4 className="font-semibold font-display text-lg text-gray-900">Reinstate warranty</h4>
                </div>

                <p className="my-4 text-gray-600">
                    Do you want to reinstate <b>Plan ID {warranty.policy_num} </b> warranty?
                </p>
            </>}

            {auth.user?.isContractor &&
                <>
                    <div className="flex items-center gap-2">
                        <IconFlipBackward className="stroke-error-700" size="md"/>
                        <h4 className="font-semibold font-display text-lg text-gray-900">Warranty Reinstatement Request</h4>
                    </div>
                    <p className="my-4">Do you want to reinstate <span
                        className="font-semibold"
                    >Plan ID {warranty?.policy_num}</span> warranty?</p>
                    <p>
                        Please note
                        <span className="font-semibold"> the reason for reinstatement </span>
                        below.
                        A Solar Insure representative will contact you shortly to review the request.
                        If any adjustments to billing are necessary, they will be reflected accordingly in your
                        subsequent invoice.
                    </p>
                </>}
            <form onSubmit={handleSubmit} className="text-gray-500" noValidate>
                <TextArea
                    id="email"
                    className="mt-8"
                    name="reason"
                    label="Reinstatement Reason"
                    onChange={handleChange}
                    value={form.reason}
                    errors={errors.reason}
                />

                <Input
                    id="contact-email"
                    className="mt-6"
                    name="contact_email"
                    label="Contact Email"
                    type="email"
                    placeholder="you@company.com"
                    onChange={handleChange}
                    value={form.contact_email}
                    errors={errors.contact_email}
                />
                <div className="flex flex-col lg:flex-row mt-8 gap-4 justify-center">
                    <Button design="btn-secondary-gray" hasError onClick={() => setIsOpen(false)} type="button">No, Exit
                        without Reinstating</Button>
                    <Button className="text-gray-900" processing={processing}>Yes, Reinstate Warranty</Button>
                </div>
            </form>
        </Popup>
    </>
}

export default WarrantyReinstatePopup
