import { useEffect, useMemo, useState } from 'react'

import tableEmptyStateAdd from '@/assets/images/table/tableEmptyStateAdd.svg'
import {
    CustomSelect,
    MonitoringHeader,
    MonitoringReport,
    SearchableSelect,
    Image,
    WarrantyCreateButton,
    PageHeader,
    Dropdown,
    Tooltip,
    Button,
    IconFileDownload02,
    IconChevronDown,
    MonitoringStatusDropdown
} from '@/components'
import { AuthLayout, GlobalIndex, GlobalIndexData, Filters } from '@/containers'
import {
    InverterManufacturerEnum,
    SystemStatusCategoryEnum,
    SystemTierCategoryEnum,
    WarrantyProductTypeEnum
} from '@/enums'
import { useAuth, useNavigateWithQuery, useQuery, useAnalytics } from '@/hooks'
import { Company, InverterManufacturer, System, Warranty, WarrantyProductType } from '@/models'

interface MonitoringIndexData extends GlobalIndexData {
    rows: System[]
    selectedRows: System[] | 'all'
    excludedRows: System[]
}

interface MonitoringIndexFilters extends Filters {
    company_id: string
    manufacturer: InverterManufacturerEnum
    statuses: string[]
    type: WarrantyProductTypeEnum
}

const MonitoringIndex = () => {
    const query = useQuery()
    const navigateWithQuery = useNavigateWithQuery()
    const auth = useAuth()
    const { trackEvent } = useAnalytics()

    const [data, setData] = useState<MonitoringIndexData>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: [],
        selectedRows: [],
        excludedRows: []
    })
    const [contractors, setContractors] = useState<Company[]>([])

    const getDefaultFilters = () => {
        const statuses = query.statuses || []
        return {
            company_id: query.company_id || '',
            statuses: Array.isArray(statuses) ? statuses : [statuses],
            manufacturer: query.manufacturer || '',
            type: query.type || ''
        }
    }
    const [filters, setFilters] = useState<MonitoringIndexFilters>(getDefaultFilters())

    const [summary, setSummary] = useState({
        sites: { total: 0 },
        groups: {
            [SystemTierCategoryEnum.TIER_1]: 0,
            [SystemTierCategoryEnum.TIER_2]: 0,
            [SystemTierCategoryEnum.TIER_3]: 0,
            [SystemTierCategoryEnum.NORMAL_SITES]: 0,
            [SystemStatusCategoryEnum.MONITORED]: 0
        },
        statuses_count: {}
    })

    const handleExport = async (format: 'csv' | 'json') => {
        trackEvent(`export_monitoring_${format}`, 'User Interaction', 'Export Systems')

        return System.export(data.selectedRows === 'all'
            ? { excluded_ids: data.excludedRows.map(({ id }) => id), select_all: true, ...query, format }
            : { ids: data.selectedRows.map(({ id }) => id), format }
        )
    }

    const handleChange = ({ name, value }: any) => {
        setData(data => ({ ...data, [name]: value }))
    }
    const handleFiltersChange = ({ target: { name, value } }: any) => {
        navigateWithQuery({ page: null, [name]: value })
        setFilters({ ...filters, [name]: value })
    }

    const fetchSummary = async () => {
        const { data } = await System.summary(filters)
        setSummary(data)
    }

    const fetchContractors = async () => {
        const data = await Company.onlyContractors()
        setContractors(data)
    }

    useEffect(() => {
        if (auth.user?.isAdminOrStaffOrAdvisor) {
            fetchContractors()
        }
    }, [])

    useEffect(() => {
        fetchSummary()
    }, [filters])

    const contractorOptions = useMemo(() => contractors.map(item => ({
        value: item.id,
        title: item.name
    })), [contractors])

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.warranty_id,
        status: <div className="flex items-center gap-3">
            <Tooltip content={<div className="p-1">
                <div className="flex items-start">
                    <div>
                        {item.systemStatus.iconBadge()}
                    </div>
                    <div className="flex flex-col gap-1 pl-2">
                        <span className="font-bold">{item.systemStatus.title}:</span>
                        <div className="font-normal">
                            {item.systemStatus.description}
                        </div>
                    </div>
                </div>
            </div>}>
                {item.systemStatus.iconBadge()}
            </Tooltip>
            <div className="flex flex-col">
                {item.systemStatus.title}
                {item.status_change_detected_at &&
                    <div className="text-xs font-body text-gray-500 font-normal leading-[14px]">
                        Detected {new Date(item.status_change_detected_at).format()}
                    </div>}
            </div>
        </div>,
        customer_name: item.customer_name,
        contractor: item.company?.name || '-',
        manufacturer: InverterManufacturer.find(item.manufacturer)?.title,
        location: `${item.city}, ${item.state}`,
        system_size: `${Warranty.getField('size_kw')
            .format(item.system_size)} kW`,
        pto_date: item.pto_date && new Date(item.pto_date).isValid() ? new Date(item.pto_date).format() : '-',
        inverter_model: item.inverter_model || '-',
        plan_number: item.plan_number || '-',
        last_updated_at: item.last_updated_at && new Date(item.last_updated_at).isValid() ? new Date(item.last_updated_at).format() : '-',
        registration_date: item.registration_date && new Date(item.registration_date).isValid() ? new Date(item.registration_date).format() : '-',
        system_id: item.system_id || '-',
        panel_model: item.panel_model || '-',
        installation_date: item.installation_date && new Date(item.installation_date).isValid() ? new Date(item.installation_date).format() : '-',
        _path: `/monitoring/${item.warranty_id}/monitoring`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Monitoring"/>}>

        <MonitoringHeader/>

        <MonitoringReport
            data={summary}
        />

        <GlobalIndex
            id="monitoring"
            infinity
            selectable
            searchPlaceholder="Search by Name, Email, Address, Plan ID, System ID, or More"
            dataType="Systems"
            data={data}
            filters={filters}
            defaultTableFilters={{ descending: true }}
            getDefaultFilters={getDefaultFilters}
            maxColumnsAmount={6}
            onChange={handleChange}
            onFiltersChange={setFilters}
            api={(...args) => System.index(...args)}
            leftSide={<>
                {auth.user?.isAdminOrStaffOrAdvisor &&
                    <SearchableSelect
                        id="company-id"
                        name="company_id"
                        value={filters.company_id}
                        placeholder="Any Contractor"
                        options={contractorOptions}
                        onChange={handleFiltersChange}
                        data-test="monitoring-index-company-id-filter"
                    />}
                <CustomSelect
                    name="manufacturer"
                    id="manufacturer"
                    options={[
                        { value: '', title: 'Any Manufacturer' },
                        ...InverterManufacturer.all
                            .filter(item => !item.isAlternative)
                            .sort((a, b) => {
                                if (a.isUnknown !== b.isUnknown) return a.isUnknown ? 1 : -1
                                return a.title.localeCompare(b.title)
                            })
                            .map(item => ({ value: item.key, title: item.title }))
                    ]}
                    value={filters.manufacturer}
                    onChange={handleFiltersChange}
                    data-test="monitoring-index-manufacturer-filter"
                />
                <MonitoringStatusDropdown
                    name="statuses"
                    id="warranty-statuses"
                    data-test="monitoring-index-statuses-filter"
                    onChange={handleFiltersChange}
                    value={filters.statuses}
                    summary={summary}
                />
                <CustomSelect
                    name="type"
                    id="warranty-type"
                    className="grow"
                    options={[
                        { value: '', title: 'Any Type' },
                        ...WarrantyProductType.all.map(item => ({
                            value: item.key,
                            title: <div className="flex gap-2">
                                {item.icon()} {item.title}
                            </div>
                        }))
                    ]}
                    value={filters.type}
                    onChange={handleFiltersChange}
                    data-test="monitoring-index-type-filter"
                />
            </>}
            rightSide={<>
                <Dropdown
                    disabled={!data.selectedRows.length}
                    button={<Tooltip content="Export" disabled={!data.selectedRows.length}>
                        <Button design="btn-secondary" size="btn-lg" disabled={!data.selectedRows.length} data-test="monitoring-index-export-button">
                            <IconFileDownload02/>
                            <IconChevronDown className="ml-2"/>
                        </Button>
                    </Tooltip>}
                >
                    <div className="p-2">
                        <Button design="btn-link-gray" className="w-full whitespace-nowrap" onClick={() => handleExport('csv')} onMouseOver={() => trackEvent('export_monitoring_csv', 'User Interaction', 'Export Systems')} data-test="monitoring-index-export-csv">
                            Export CSV
                        </Button>
                        <Button design="btn-link-gray" className="w-full whitespace-nowrap mt-2" onClick={() => handleExport('json')} onMouseOver={() => trackEvent('export_monitoring_json', 'User Interaction', 'Export Systems')} data-test="monitoring-index-export-json">
                            Export JSON
                        </Button>
                    </div>
                </Dropdown>
            </>}
            placeholder={data.meta.total === 0 && !auth.user?.isAdvisor && <>
                <Image
                    className="w-30"
                    src={tableEmptyStateAdd}
                />
                <div className="mt-4 flex flex-col items-center">
                    <h4 className="text-center font-light">Start by Adding a New Warranty</h4>
                    <p className="text-center text-gray-500 font-light text-sm mt-1">
                        Monitoring Data for registered warranties will live here. Start registering Solar Insure
                        Warranties to see monitoring connections.
                    </p>
                    <WarrantyCreateButton className="mt-6"/>
                </div>
            </>}
            columns={[
                { title: 'System Status', field: 'status', cellClassName: '!py-3.5' },
                { title: 'Homeowner Name', field: 'customer_name' },
                { title: 'Contractor', field: 'contractor', showIf: auth.user?.isAdminOrStaffOrAdvisor },
                { title: 'Manufacturer', field: 'manufacturer' },
                { title: 'Location', field: 'location' },
                { title: 'System Size', field: 'system_size' },
                { title: 'PTO Date', field: 'pto_date', sortable: false },
                { title: 'Inverter Model', field: 'inverter_model' },
                { title: 'Plan ID', field: 'plan_number' },
                { title: 'Last Update Date', field: 'last_updated_at' },
                { title: 'Submission Date', field: 'registration_date' },
                { title: 'System ID', field: 'system_id' },
                { title: 'Panel Model', field: 'panel_model' },
                { title: 'Installation Date', field: 'installation_date' }
            ]}
            rows={rowsMemo}
        />
    </AuthLayout>
}

export default MonitoringIndex
