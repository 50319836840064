import { useEffect, useMemo, useState } from 'react'

import {
    AddressForm,
    Button,
    CompanyForm,
    EligibleCommissionEditForm, Form,
    Popup
} from '@/components'
import { useAuth, useToastNotifications } from '@/hooks'
import { Company } from '@/models'

type CompanyEditFormProps = {
    company: Company,
    onSuccess?: (res: any) => void
    onExit: () => void
}

const CompanyEditForm = ({ company, ...props }: CompanyEditFormProps) => {
    const { success } = useToastNotifications()
    const auth = useAuth()
    const [processing, setProcessing] = useState(false)
    const [showEligibleCommissionPopup, setShowEligibleCommissionPopup] = useState(false)
    const [errors, setErrors] = useState<any>({})
    const getForm = (): any => ({
        id: company.id,
        name: company.name || '',
        email: company.email || '',
        address: company.address || '',

        role: auth.user?.isAdminOrStaff ? company.type || '' : undefined,
        create_warranty: auth.user?.isAdminOrStaff ? company.create_warranty || false : undefined,
        policy_rate: auth.user?.isAdminOrStaff && !company?.isAffiliate ? company.policy_rate || '' : undefined,

        battery_eligibility: auth.user?.isAdminOrStaff && !company?.isAffiliate
            ? company.batteryEligibility.key : undefined,
        battery_20_year_rate: auth.user?.isAdminOrStaff && !company?.isAffiliate ? company.battery_20_year_rate || '' : undefined,
        battery_30_year_rate: auth.user?.isAdminOrStaff && !company?.isAffiliate ? company.battery_30_year_rate || '' : undefined,

        solar_commission_rate: auth.user?.isAdminOrStaff && company?.isSoftwareAffiliate
            ? company.currentEligibleCommission?.solar_commission_rate || ''
            : undefined,
        battery_commission_rate: auth.user?.isAdminOrStaff && company?.isSoftwareAffiliate
            ? company.currentEligibleCommission?.battery_commission_rate || ''
            : undefined,
        warranty_match_method: auth.user?.isAdminOrStaff && company?.isAffiliate ? company.warranty_match_method || '' : undefined,

        monthly_sales_quota: auth.user?.isAdminOrStaff ? company.monthly_sales_quota || 0 : undefined,
        conglomerate_id: auth.user?.isAdminOrStaff && company.isContractor ? company.conglomerate?.id || '' : undefined,
        update_policy: auth.user?.isAdminOrStaff ? '' : undefined,

        unique_id: auth.user?.isAdminOrStaff ? company.unique_id || '' : undefined
    })
    const [form, setForm] = useState<any>(getForm())

    const handleChange = ({ target: { name, value, type, checked } }: any) => {
        setForm((form: any) => ({ ...form, [name]: type === 'checkbox' ? checked : value }))
    }

    const handleEligibleCommissionFocus = () => {
        setShowEligibleCommissionPopup(true)
    }

    const handleSubmit = async (e?: any) => {
        e?.preventDefault()
        setProcessing(true)
        setErrors({})
        const clone = { ...form }
        if (!clone.update_policy) {
            clone.update_policy = 'all'
        }
        try {
            const res = auth.user?.company?.id === clone.id
                ? await Company.updateSelf(clone)
                : await company.update(clone)
            success('Company successfully updated!')
            if (props.onSuccess) await props.onSuccess(res)
            props.onExit()
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            }
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        setForm((form: any) => ({
            ...form,
            solar_eligible_commission_rate: auth.user?.isAdminOrStaff && company?.isSoftwareAffiliate
                ? company.currentEligibleCommission?.solar_commission_rate || ''
                : undefined,
            battery_eligible_commission_rate: auth.user?.isAdminOrStaff && company?.isSoftwareAffiliate
                ? company.currentEligibleCommission?.battery_commission_rate || ''
                : undefined
        }))
    }, [company])

    const isDirty = useMemo(() => Object.keys(form).some(key =>
        getForm()[key]?.toString() !== form[key]?.toString()), [form, company])

    return <>
        <Form isDirty={isDirty} confirmation className="grid lg:grid-cols-2 gap-6" onSubmit={handleSubmit}>
            <CompanyForm
                form={form}
                company={company}
                errors={errors}
                onChange={handleChange}
                onEligibleCommissionFocus={handleEligibleCommissionFocus}
            />

            <div className="col-start-1">
                <AddressForm
                    inline
                    form={form}
                    errors={errors}
                    onChange={handleChange}
                />
            </div>

            <div className="lg:col-span-2 flex flex-col gap-3 md:flex-row justify-end">
                <Button design="btn-secondary-gray" hasError onClick={props.onExit} type="button">
                    Exit Edit Mode Without Saving
                </Button>
                <Button processing={processing} disabled={!isDirty}>
                    Save and Exit
                </Button>
            </div>
        </Form>

        <Popup open={showEligibleCommissionPopup} onClose={() => setShowEligibleCommissionPopup(false)}>
            {showEligibleCommissionPopup && <EligibleCommissionEditForm
                company={company}
                onSuccess={props.onSuccess}
            />}
        </Popup>
    </>
}

export default CompanyEditForm
