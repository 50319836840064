import { api, Payload } from '@/services'

export default {
    sendEmail(payload: Payload) {
        return api.http.post('api/v1/support/send-email', payload)
    },
    feedback(payload: Payload) {
        return api.http.post('api/v1/support/articles/feedback', payload)
    },
    deleteNotification(id: number) {
        return api.http.get(`api/v1/support/articles/notification/${id}`)
    }
}
