import { Badge, Button, Select, Switch, Table } from '@/components'
import { useAnalytics } from '@/hooks'

type EditDataStepProps = {
    form: any
    errors: any
    columns: any
    headers: any
    onNext: any
    onPrev: any
    onChange: any
    processing: boolean
    onColumnsChange: any
}
const EditDataStep = ({ form, columns, headers, errors, processing, ...props }: EditDataStepProps) => {
    const { trackEvent } = useAnalytics()

    const handleSelectChange = (e: any) => {
        const clone = [...columns]
        const index = clone.findIndex((item: any) => item.value === e.target.name)
        clone[index].index = e.target.value
        props.onColumnsChange(clone)
    }
    const handleSwitchChange = ({ target: { name, checked } }: any) => {
        const clone = [...columns]
        const index = clone.findIndex((item: any) => item.value === name)
        clone[index].show = checked

        const fullNameIndex = clone.findIndex(({ value }) => value === 'full_name')
        const firstNameIndex = clone.findIndex(({ value }) => value === 'first_name')
        const lastNameIndex = clone.findIndex(({ value }) => value === 'last_name')
        if (name === 'first_name') {
            clone[fullNameIndex].show = !checked
            clone[lastNameIndex].show = checked
        }
        if (name === 'last_name') {
            clone[fullNameIndex].show = !checked
            clone[firstNameIndex].show = checked
        }
        if (name === 'full_name') {
            clone[lastNameIndex].show = !checked
            clone[firstNameIndex].show = !checked
        }

        props.onColumnsChange(clone)
    }

    const getErrorsCount = () => Object.keys(errors).length

    const getDuplicatedRows = () => Object.keys(errors).reduce((acc: string[], item) => {
        const [, index] = item.split('.')
        const row = parseInt(index) + 1
        return item.includes('street_address') && errors[item] === 'Duplicate row detected.' ? [...acc, `Row ${row}`] : acc
    }, [])

    const getErrorRows = () => Object.keys(errors).reduce((acc: string[], item) => {
        const [, index] = item.split('.')
        const row = (parseInt(index) + 1).toString()
        return row && !acc.includes(row) ? [...acc, row] : acc
    }, [])

    const getParsedErrors = () => {
        // Uploads are chunked, so the backend returns the chunk index back
        const chunkIndex = errors.chunk_index
        if (!chunkIndex || !chunkIndex[0]) return errors

        // For chunks 2, 3, etc. we need to update the errors key
        // so if we get an error on index 50 on chunk_index 200, the proper key is warranties.250.email
        const regex = /(\d+)/g
        return Object.keys(errors).reduce((acc, key) => {
            const [badIndex]: any = key.match(regex) || []
            const updatedKey = key.replace(regex, parseInt(badIndex) + chunkIndex[0])
            return { ...acc, [updatedKey]: errors[key].map((item: string) => item.replace(key, updatedKey)) }
        }, {})
    }

    const handleChangeSendEmail = (e: any) => {
        trackEvent('click_email_homeowner_toggle', 'User Interactions', 'Email Homeowner Toggle Click')
        props.onChange(e)
    }

    const trackHoverEvent = (eventType: string) => {
        const category = 'User Interaction'

        if (eventType === 'bulk_upload_step_2_back') {
            trackEvent('hover_bulk_upload_step_2_back', category, 'Bulk Upload Back Button Hover')
        } else if (eventType === 'bulk_upload_step_2_save') {
            trackEvent('hover_bulk_upload_step_2_save', category, 'Bulk Upload Save Button Hover')
        } else if (eventType === 'email_homeowner_toggle') {
            trackEvent('hover_email_homeowner_toggle', category, 'Bulk Upload Email Homeowner Button Hover')
        } else if (eventType === 'hover_email_homeowner_toggle') {
            trackEvent('hover_email_homeowner_toggle', category, 'Email Homeowner Toggle Hover')
        }
    }

    const handleClickBack = () => {
        props.onPrev()
        trackEvent('click_bulk_upload_step_2_back', 'User Interaction', 'Bulk Upload Step 2 Back Button Click')
    }

    return <>
        <h3 className="font-semibold mb-4 text-center text-sm mt-9 mb-0">Instructions</h3>
        <p className="text-center text-sm mt-4">We{'\''}ve pulled in the column headers from your uploaded file and populated the dropdown headers in the right column. Please make sure the column headers match the required fields in the left column.</p>
        <div className="lg:grid lg:grid-cols-2 gap-8 mt-8">
            <div>
                <table className="w-full">
                    <thead>
                        <tr>
                            <th className="text-sm text-left font-semibold">
                                Column Matching
                            </th>
                            <th></th>
                        </tr>
                        <tr className="my-4">
                            <th className="text-sm text-left font-normal py-4">Solar Insure Headers</th>
                            <th className="text-sm text-left font-normal py-4">Uploaded File Headers</th>
                        </tr>
                    </thead>
                    <tbody>
                        {columns.map((item: any) =>
                            <tr key={item.value} className="flex flex-col gap-3 mb-3 lg:table-row lg:mb-0">
                                <td className="lg:py-1">
                                    <Switch
                                        disabled={item.readonly}
                                        checked={item.show}
                                        label={item.label}
                                        name={item.value}
                                        onChange={handleSwitchChange}
                                    />
                                </td>
                                <td className="py-1 lg:pl-2">
                                    <Select
                                        id={`${item.value}-select`}
                                        options={headers.map((item: any, index: number) => ({
                                            value: index,
                                            title: item
                                        }))}
                                        value={item.index}
                                        name={item.value}
                                        onChange={handleSelectChange}
                                    />
                                </td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
            <div>
                {getDuplicatedRows().length
                    ? <p className="text-error-500 mb-2">
                        Duplicate row(s) detected. Please remove the duplicate
                        row(s): <b>{new Intl.ListFormat().format(getDuplicatedRows())}</b> to continue.
                    </p>
                    : getErrorsCount() > 0 && <p className="text-error-500 mb-2">
                        {getErrorsCount()} {getErrorsCount() > 1 ? 'errors were found in' : 'error was found in'} {getErrorRows().length > 1 ? 'rows' : 'row'} {new Intl.ListFormat().format(getErrorRows())}.
                        Use your mouse to hover over the error rows to get more detailed information. To resolve these
                        issues, return to the previous screen to upload a new file with corrected data.
                    </p>}
                <Badge className="bg-primary-50 text-primary-700 mb-6 inline-block leading-5">{form.warranties.length} warranties will be created</Badge>
                <Table
                    useQueryParams={false}
                    searchable={false}
                    columns={columns.filter(({ show }: any) => show).map((item: any) => ({
                        field: item.value,
                        title: item.label,
                        className: 'whitespace-nowrap'
                    }))}
                    rows={form.warranties.map((item: any, index: number) => {
                        const errors = columns.filter(({ show }: any) => show).reduce((acc: string[], column: any) => {
                            const key = `warranties.${index}.${column.value}`
                            const errors = getParsedErrors()
                            if (errors[key]) {
                                return typeof errors[key] === 'string'
                                    ? [...acc, errors[key].replace(key, column.label)]
                                    : [...acc, ...errors[key]
                                        ? errors[key].map((item: string) => item.replace(key, column.label))
                                        : []]
                            }
                            return acc
                        }, [])
                        return {
                            ...item,
                            _tooltip: errors.length ? errors.map((item: string, index: number) =>
                                <p key={index}>{item}</p>) : null,
                            _className: errors.length ? 'bg-error-100' : ''
                        }
                    })}
                />
            </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-end items-center gap-3 mt-6">
            <div onMouseOver={() => trackHoverEvent('hover_email_homeowner_toggle')} onFocus={() => {}}>
                <Switch
                    checked={form.send_emails}
                    onChange={handleChangeSendEmail}
                    name="send_emails"
                    label="Email Certificate to Homeowners"
                />
            </div>
            <div className="flex gap-3">
                <Button
                    design="btn-secondary-gray"
                    onClick={handleClickBack}
                    className="w-36"
                    onMouseOver={() => trackHoverEvent('bulk_upload_step_2_back')}
                >
                    Back
                </Button>
                <Button onClick={props.onNext} onMouseOver={() => trackHoverEvent('bulk_upload_step_2_save')} processing={processing} className="w-36">
                    Save
                </Button>
            </div>
        </div>
    </>
}

export default EditDataStep
