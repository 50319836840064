import { To } from 'react-router-dom'

import { PageHeader } from '@/components'
import { Company } from '@/models'

type CompanyHeaderProps = {
    company: Company
    backUrl: To
}

const CompanyHeader = ({ company, backUrl }: CompanyHeaderProps) => <PageHeader
    title={company.name}
    backUrl={backUrl}
    subtitle={
        <p className="text-gray-500 text-base mt-2">
            Company Type: <span className="bg-primary-50 px-2 py-1 text-primary-700 capitalize ml-1">
                {company.typeTitle}
            </span>
        </p>
    }
/>

export default CompanyHeader
