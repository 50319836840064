import { useState } from 'react'
import { Link } from 'react-router-dom'

import BugReport from './BugReport'
import ContactInfo from './ContactInfo'
import MenuItems from './MenuItems'
import Logo from '../Logo'
import {
    Button, IconActivity, IconBuilding07, IconUsers01, IconCertificate,
    IconLineChart, IconList,
    IconHome,
    IconTools,
    IconLifeBuoy,
    IconCube, IconCurrencyDollar
} from '@/components'
import { useAuth } from '@/hooks'

const SideBar = () => {
    const auth = useAuth()
    const [open, setOpen] = useState(false)

    const menuTopItems = [
        {
            id: 'dashboard',
            title: 'Dashboard',
            icon: <IconHome size="xl" className="stroke-white"/>,
            path: '/dashboard'
        },
        {
            id: 'monitoring',
            title: 'Monitoring',
            icon: <IconLineChart size="xl" className="stroke-white"/>,
            path: '/monitoring',
            showIf: !auth.user?.isSoftwareAffiliate
        },
        {
            id: 'warranties',
            title: 'Warranties',
            icon: <IconCertificate size="xl" className="stroke-white"/>,
            path: '/warranties',
            showIf: auth.user?.isContractor || auth.user?.isAdminOrStaffOrAdvisor
        },
        {
            id: 'warranties-by-contractors',
            title: 'Warranties by Contractors',
            icon: <IconList size="xl" className="stroke-white"/>,
            path: '/warranties-by-contractors',
            showIf: auth.user?.isAdminOrStaffOrAdvisor || auth.user?.isAffiliate
        },
        {
            id: 'contractors',
            title: 'Contractors',
            icon: <IconTools size="xl" className="stroke-white"/>,
            path: '/contractors',
            showIf: auth.user?.isAffiliate
        },
        {
            id: 'invoices',
            title: 'Invoice Summary',
            icon: <IconCurrencyDollar size="xl" className="stroke-white"/>,
            path: '/invoices',
            showIf: auth.user?.isContractor || auth.user?.isAdminOrStaffOrAdvisor
        },
        {
            id: 'users',
            title: 'Users',
            icon: <IconUsers01 size="xl" className="stroke-white"/>,
            path: '/users',
            showIf: auth.user?.isAdminOrStaffOrAdvisor
        },
        {
            id: 'activity',
            title: 'Activity',
            icon: <IconActivity size="xl" className="stroke-white"/>,
            path: '/activities',
            showIf: auth.user?.isAdminOrStaffOrAdvisor
        },
        {
            id: 'products',
            title: 'Products',
            icon: <IconCube size="xl" className="stroke-white"/>,
            path: '/products',
            showIf: auth.user?.isAdminOrStaffOrAdvisor
        },
        {
            id: 'companies',
            title: 'Companies',
            icon: <IconBuilding07 size="xl" className="stroke-white"/>,
            path: '/companies',
            showIf: auth.user?.isAdminOrStaffOrAdvisor
        }
    ]

    const menuBottomItems = [
        {
            id: 'support',
            title: 'Support',
            icon: <IconLifeBuoy size="xl" className="stroke-white"/>,
            path: '/support',
            badge: auth.user?.article_notifications?.length || null
        }
    ]

    return <div className={`side-nav${open ? ' open' : ''}`}>
        <div className="side-nav-heading">
            <Link to="/dashboard" className="side-nav-logo-container" data-test="sidebar-home-link">
                <Logo type="primaryWhite" className="w-36 sm:mb-10 sm:hidden md:block" data-test="sidebar-logo"/>
                <Logo type="compactWhite" className="w-20 sm:mb-10 hidden sm:block md:hidden" data-test="sidebar-logo"/>
            </Link>

            <Button onClick={() => setOpen(!open)} design="btn-link" className="side-nav-menu-icon" data-test="sidebar-mobile-menu-button">
                <div></div>
                <div></div>
                <div></div>
            </Button>
        </div>

        <div className="nav-items-container">
            <MenuItems items={menuTopItems}/>

            <div>
                <MenuItems items={menuBottomItems}/>

                <ContactInfo/>
                {import.meta.env.PROD && <div className="nav-items"><BugReport/></div>}
            </div>
        </div>
    </div>
}

export default SideBar
