import { Chart as ChartJs } from 'chart.js'
import { useEffect, useState, useRef } from 'react'

import { DoughnutChart, Tabs } from '@/components'
import { useAnalytics } from '@/hooks'
import { api } from '@/services'

type SalesTrackingProps = {
    className?: string
    onChange: any
    processing: boolean
}

const SalesTracking = ({ className, processing, ...props }: SalesTrackingProps) => {
    const { trackEvent } = useAnalytics()
    const [active, setActive] = useState({
        show: false,
        index: 0,
        x: 0,
        y: 0
    })

    const chartRef = useRef<HTMLDivElement>(null)

    const [abortController, setAbortController] = useState<AbortController | null>(null)

    const [data, setData] = useState({
        monthly: {
            current: 0,
            percentage: 0,
            previous: 0
        },
        quarterly: {
            current: 0,
            percentage: 0,
            previous: 0
        },
        yearly: {
            current: 0,
            percentage: 0,
            previous: 0
        }
    })

    type SalesOption = 'quarterly' | 'monthly' | 'yearly'
    const [period, setPeriod] = useState<SalesOption>('quarterly')

    const getPreviousPeriod = (short = false) => {
        if (period === 'quarterly') {
            const year = `${new Date().subtract('quarter', 1).format({ day: null, month: null })}`
            const quarter = `Q${Math.ceil((new Date().subtract('quarter', 1).getMonth() + 1) / 3)}`
            const formattedDate = `${quarter} ${new Date().subtract('quarter', 1).format({ day: null, month: null, year: 'numeric' })}`
            return short ? `${quarter} ${year}` : formattedDate
        }
        if (period === 'monthly') {
            return new Date().subtract('month', 1).format({ day: null, month: 'long', year: 'numeric' })
        }
        return new Date().subtract('year', 1).format({ day: null, month: null, year: 'numeric' })
    }

    const fetchData = async () => {
        try {
            const controller = new AbortController
            setAbortController(controller)
            const res = await api.dashboard.warrantySalesTracking({}, { signal: controller.signal })
            setData(res)
        } finally {
            props.onChange('salesTracking')
        }
    }

    const handleTabChange = (value: SalesOption) => {
        setPeriod(value)
        trackEvent(`click_contractors_dashboard_${value}_toggle`, 'User Interaction', `Tab ${value}`)
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    useEffect(() =>
        () => {
            abortController?.abort('canceled')
        }, [abortController])

    return <div className={`h-full flex flex-col justify-between ${className || ''}`}>
        <div>
            <h3 className="text-lg">Warranty Sales Tracking</h3>
            <h4 className="text-gray-500">Q{(Math.floor((new Date().getMonth() / 3) + 1))} {new Date().format({ day: null, month: 'long' })}</h4>
        </div>
        <div className="relative mx-auto w-full h-fit">
            <div className="w-full relative mx-auto flex justify-center" ref={chartRef} onMouseLeave={() => setActive({ ...active, show: false })}>
                {active.show && active.index === 0 && <div
                    className="px-3 py-2 absolute font-body bg-white shadow-md rounded-md -translate-y-1/2 z-40"
                    style={{ left: `${active.x - 50}px`, top: `${active.y - 30}px` }}
                    onMouseEnter={() => setActive({ ...active, show: true })}
                    onMouseLeave={() => setActive({ ...active, show: false })}
                >
                    <h6 className="text-xs text-gray-700 font-semibold">Compared to {getPreviousPeriod()}</h6>
                    <h6 className="text-gray-500 text-sm">
                        <span className="capitalize">{period.slice(0, -2)} </span>
                        To Date: {data[period].current.money()}
                    </h6>
                    <h6 className="text-gray-500 text-sm">
                        {getPreviousPeriod(true)}: {data[period].previous.money()}
                    </h6>
                </div>}
                <DoughnutChart
                    datasets={[{
                        data: [data[period].percentage, 100 - data[period].percentage],
                        backgroundColor: ['#007a96', '#edebe7'],
                        hoverBackgroundColor: ['#00647a', '#edebe7'],
                        borderWidth: 0,
                        borderColor: 'transparent',
                        radius: 128,
                        borderRadius: 60,
                        cutout: '80%',
                        circumference: 180,
                        rotation: -90
                    }]}
                    plugins={[{
                        beforeDraw(chart: ChartJs) {
                            const { ctx, chartArea: { width, height } } = chart
                            ctx.save()
                            const centerX = width / 2
                            const centerY = height / 2
                            const radius = 112
                            ctx.lineCap = 'round'
                            ctx.beginPath()
                            ctx.arc(centerX, centerY + 60, radius, Math.PI + 0.10, - 0.10, false)
                            ctx.lineWidth = 25.6
                            ctx.strokeStyle = '#edebe7'
                            ctx.stroke()
                        }
                    }]}
                    options={{
                        aspectRatio: 2,
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            tooltip: {
                                enabled: false,
                                external: context => {
                                    const tooltipModel = context.tooltip
                                    const [activeEl] = tooltipModel.getActiveElements()
                                    if (activeEl && (activeEl.index !== active.index || !active.show)) {
                                        setActive({
                                            show: true,
                                            index: activeEl.index,
                                            x: tooltipModel.caretX + 8,
                                            y: tooltipModel.caretY
                                        })
                                    }
                                }
                            }
                        }
                    }}
                />
            </div>
            <div className="absolute font-display top-1/2 left-1/2 -translate-x-1/2 flex flex-col items-center justify-center">
                <span className="text-xs text-gray-500">{data[period].current.money()}</span>
                <span className="text-2xl">{Math.round(data[period].percentage)}%</span>
            </div>
        </div>
        <div className="flex flex-col justify-center gap-8">
            <div className="font-body">
                <div className="flex justify-center border-t pt-3">
                    <Tabs
                        value={period}
                        onChange={handleTabChange}
                        design="buttons"
                        tabs={[
                            {
                                id: 'quarterly',
                                title: 'By Quarter'
                            },
                            {
                                id: 'monthly',
                                title: 'By Month'
                            },
                            {
                                id: 'yearly',
                                title: 'By Year'
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default SalesTracking
