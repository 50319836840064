import Icon, { IconProps } from './Icon'

const IconIntegrations = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M7.3,15L7,14.8c-0.6-0.5-1-1.1-1.2-1.8c-0.2-0.7-0.4-1.3-0.4-2c0-1,0.3-2,0.8-2.8c0.5-0.9,1.2-1.5,2.1-2
c0.2-0.1,0.3-0.1,0.5,0C9,6.3,9.2,6.4,9.2,6.6c0.1,0.2,0.1,0.3,0,0.5C9.1,7.3,9,7.4,8.9,7.5C8.2,7.8,7.7,8.3,7.3,9
C7,9.6,6.8,10.3,6.8,11c0,0.5,0.1,1,0.3,1.5c0.2,0.5,0.5,0.9,0.9,1.3L8.1,14v-1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.2,0.5-0.2
c0.2,0,0.3,0.1,0.5,0.2s0.2,0.3,0.2,0.5v2.7c0,0.2-0.1,0.3-0.2,0.5s-0.3,0.2-0.5,0.2H6.1c-0.2,0-0.3-0.1-0.5-0.2
c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.3,0.2-0.5C5.7,15.1,5.9,15,6.1,15H7.3z M14.8,8v1c0,0.2-0.1,0.3-0.2,0.5s-0.3,0.2-0.5,0.2
c-0.2,0-0.3-0.1-0.5-0.2S13.4,9.2,13.4,9V6.3c0-0.2,0.1-0.3,0.2-0.5s0.3-0.2,0.5-0.2h2.7c0.2,0,0.3,0.1,0.5,0.2s0.2,0.3,0.2,0.5
c0,0.2-0.1,0.3-0.2,0.5S16.9,7,16.8,7h-1.2l0.3,0.2c0.5,0.5,0.8,1,1.1,1.5c0.2,0.5,0.4,1.1,0.5,1.6H16c-0.1-0.4-0.2-0.8-0.4-1.1
c-0.2-0.4-0.4-0.7-0.7-1L14.8,8z M15,18.3c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l-0.1-0.7c-0.1-0.1-0.3-0.1-0.4-0.2
c-0.1-0.1-0.2-0.1-0.4-0.2l-0.7,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1l-0.4-0.7c0-0.1-0.1-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.2
l0.6-0.5c0-0.2,0-0.3,0-0.4c0-0.1,0-0.3,0-0.4l-0.6-0.5c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2l0.4-0.7c0-0.1,0.1-0.1,0.2-0.1
c0.1,0,0.1,0,0.2,0l0.7,0.2c0.1-0.1,0.2-0.2,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2l0.1-0.7c0-0.1,0.1-0.1,0.1-0.2
c0.1-0.1,0.1-0.1,0.2-0.1h0.8c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0.1,0.7c0.1,0.1,0.3,0.1,0.4,0.2
c0.1,0.1,0.2,0.1,0.4,0.2l0.7-0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1l0.4,0.7c0,0.1,0.1,0.1,0,0.2s-0.1,0.1-0.1,0.2l-0.6,0.5
c0,0.1,0,0.3,0,0.4s0,0.3,0,0.4l0.6,0.5c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2L18.1,17c0,0.1-0.1,0.1-0.2,0.1
c-0.1,0-0.1,0-0.2,0L17,16.9c-0.1,0.1-0.2,0.2-0.4,0.2s-0.2,0.1-0.4,0.2l-0.1,0.7c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1
H15z M15.4,16.3c0.4,0,0.7-0.1,0.9-0.4c0.3-0.3,0.4-0.6,0.4-0.9c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.3-0.6-0.4-0.9-0.4
c-0.4,0-0.7,0.1-0.9,0.4c-0.3,0.3-0.4,0.6-0.4,0.9c0,0.4,0.1,0.7,0.4,0.9C14.7,16.2,15.1,16.3,15.4,16.3z"
        />
    </Icon>

export default IconIntegrations
