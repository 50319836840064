import { ReactNode, useState } from 'react'

import { Button, TextArea, Popup, IconCertificate } from '@/components'
import { useToastNotifications } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyCancelFormProps = {
    children: ReactNode
    warranty: Warranty
    className?: string
    onChange: any
}

const WarrantyMarkUniquePopup = ({ children, warranty, className, ...props }: WarrantyCancelFormProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [errors, setErrors] = useState<any>({})
    const [processing, setProcessing] = useState(false)
    const { success } = useToastNotifications()

    const [form, setForm] = useState({ reason: '' })

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        try {
            await warranty.markUnique(form.reason)
            setIsOpen(false)
            success('Warranty successfully marked as unique.')
            if (props.onChange) props.onChange()
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            }
        } finally {
            setProcessing(false)
        }
    }

    const handleOpen = (e: any) => {
        if (warranty.deleted_at) {
            handleSubmit(e)
        } else {
            setIsOpen(true)
        }
    }

    const handleChange = ({ target: { name, value } }: any) => {
        setForm({ ...form, [name]: value })
    }
    return <>
        <span role="button" tabIndex={-1} onKeyDown={handleOpen} className={className} onClick={handleOpen}>
            {children}
        </span>

        <Popup open={isOpen} onClose={() => setIsOpen(false)} className="min-w-[315px]">
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 text-gray-900" noValidate>
                <div className="flex items-center gap-2">
                    <IconCertificate className="stroke-gray-700"/>
                    <p className="font-bold">Mark Warranty as Unique</p>
                </div>
                <p className="text-gray-500">
                    Do you want to mark this warranty as unique?
                </p>
                <p className="text-gray-500">
                    Multiple warranties may be needed at the same homeowner address in certain cases. If the homeowner
                    added additional panels, inverters, or a battery after the initial solar system installation and
                    warranty registration.
                </p>
                <p className="text-gray-500">
                    By marking this warranty as unique, the {'"'}Potential Duplicate{'"'} status will be removed, and
                    billing will proceed as usual.
                </p>
                <p className="text-gray-500">
                    To help us improve our registration process, please provide a brief explanation as to why you are
                    marking this warranty as unique:
                </p>
                <TextArea
                    id="email"
                    name="reason"
                    label="Unique Warranty Reason"
                    placeholder="Edit Reason..."
                    onChange={handleChange}
                    value={form.reason}
                    errors={errors.reason}
                />

                <div className="flex flex-col lg:flex-row gap-2 justify-center">
                    <Button design="btn-secondary-gray" hasError onClick={() => setIsOpen(false)} type="button">No, Exit without changing</Button>
                    <Button processing={processing}>Yes, Mark Warranty as Unique</Button>
                </div>
            </form>
        </Popup>
    </>
}

export default WarrantyMarkUniquePopup
