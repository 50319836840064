import { WarrantyStatus } from '@/models'

type WarrantyTableHeadingProps = {
    name?: string
    statuses?: WarrantyStatus[]
    value: string[]
    onChange: any
    'data-test'?: string
}

const WarrantyTableHeading = ({ value, name = 'statuses', statuses = WarrantyStatus.index(), 'data-test': dataTest, ...props }: WarrantyTableHeadingProps) => {
    const handleChange = (key: string) => {
        const clone = [...value].filter(item => statuses?.some(status => status.key === item))
        props.onChange({
            target: {
                name,
                value: clone.includes(key) ? clone.filter(item => item !== key) : [...clone, key]
            }
        })
    }

    return <div className="bg-white py-3 px-6 border-b border-gray-300 text-xs flex flex-wrap gap-8 items-center" data-test={`${dataTest}-status-filter`}>
        <div className="text-gray-500">Warranty Status</div>
        {statuses.map((item: any) => <button
            key={item.key}
            onClick={() => handleChange(item.key)}
            data-test={`${dataTest}-status-filter-${item.key}`}
        >
            {item.badge(value.includes(item.key))}
        </button>)}
    </div>
}

export default WarrantyTableHeading
