import { useState } from 'react'

import {
    WarrantyHistoryTable,
    WarrantyPotentialDuplicateCards,
    WarrantyDetails
} from '@/components'
import { Warranty } from '@/models'

const WarrantiesShow = ({ warranty, ...props }: { warranty: Warranty, onChange: any }) => {
    const [activeDuplicateIndex, setActiveWarrantyIndex] = useState(0)

    const getActiveDuplicate = (): Warranty => warranty.isDuplicate && warranty.all_duplicates[activeDuplicateIndex]

    const handleDuplicateChange = () => {
        setActiveWarrantyIndex(activeDuplicateIndex === 0 ? 0 : activeDuplicateIndex - 1)
        if (props.onChange) props.onChange()
    }

    return <>
        {warranty.isDuplicate && !!warranty.all_duplicates.length &&
            <WarrantyPotentialDuplicateCards
                warranty={warranty}
                activeIndex={activeDuplicateIndex}
                onChange={handleDuplicateChange}
                onIndexChange={setActiveWarrantyIndex}
            />}

        <div data-test="warranty-details-page" className={`grid gap-6 mb-6 ${warranty.isDuplicate && !!warranty.all_duplicates.length ? 'lg:grid-cols-4' : 'lg:grid-cols-2'}`}>

            <WarrantyDetails warranty={warranty} duplicate={getActiveDuplicate()}/>
            {warranty.isDuplicate && !!warranty.all_duplicates.length &&
                <WarrantyDetails warranty={getActiveDuplicate()} duplicate={warranty}/>}
        </div>

        <WarrantyHistoryTable warranty={warranty}/>

        {/*<Card className="mt-6">
                <h2 className="card-title mb-6">
                    Reference Information
                </h2>
            </Card>*/}
    </>
}

export default WarrantiesShow
