import { useState } from 'react'

import { ClickToCopy, CustomSelect, IconChevronDown, IconSearch, Input, Table } from '@/components'
import { Warranty } from '@/models'

type DeviceDetailsDefaultProps = {
    warranty: Warranty
}

const DeviceDetailsDefault = ({ warranty }: DeviceDetailsDefaultProps) => {
    const [filters, setFilters] = useState({
        search: '',
        status: ''
    })
    const [groups, setGroups] = useState<any>(warranty.homeowner?.system?.deviceCategories.reduce((acc, item) => ({
        ...acc,
        [item.type]: true
    }), {}))

    const handleFiltersChange = ({ target: { name, value } }: any) => {
        setFilters({ ...filters, [name]: value })
    }

    const getRows = () =>
        warranty.homeowner?.system?.deviceCategories.reduce((acc: any, item, index) => {
            if (!item.devices.length) return acc
            return [...acc, {
                id: item.type,
                name: <div className="text-base font-semibold capitalize flex gap-3 items-center leading-7">
                    <IconChevronDown
                        className={`stroke-gray-700 transition-all ${groups[item.type] ? 'rotate-180' : ''}`}
                    />
                    {item.title}<span>{item.statusIcon}</span>
                </div>,
                _className: 'bg-orange-50',
                _group: index,
                onClick: () => setGroups((groups: any) => ({
                    ...groups,
                    [item.type]: !groups[item.type]
                }))
            }, ...item.devices.filter(({
                status,
                serial_number: serialNumber
            }) =>
                groups[item.type] &&
                (!filters.status || (['normal', 'disabled'].includes(filters.status) ? status === filters.status : !['normal', 'disabled'].includes(status))) &&
                (!filters.search || serialNumber?.toLowerCase()
                    .includes(filters.search.toLowerCase())))
                .map((item, itemIndex: number) => ({
                    id: item.id || `${index}-${itemIndex}`,
                    manufacturer: item.manufacturer || '',
                    name: <span className="font-semibold">{item.name}</span>,
                    name_raw: item.name || '',
                    serial_number: item.serial_number ? <div className="flex gap-1 items-center">
                        {item.serial_number} <ClickToCopy content={item.serial_number}/>
                    </div> : '',
                    serial_number_raw: item.serial_number,
                    type: item.type || '',
                    last_report: item.last_report_at && new Date(item.last_report_at).isValid()
                        ? new Date(item.last_report_at).format()
                        : item.last_report_at,
                    last_report_raw: item.last_report_at ? new Date(item.last_report_at) : 0,
                    status: item.statusBadge,
                    status_raw: item.status,
                    state: item.state || '',
                    form: item.form || '',
                    model: item.model || '',
                    connected_to: item.connectedTo || '',
                    _group: index
                }))]
        }, []) || []
    return <>
        <div className="flex flex-col gap-2 lg:flex-row justify-between border-b border-orange-400 pb-4 mb-4">

            <div className="flex gap-3 flex-col lg:flex-row">
                {warranty.homeowner?.system?.inverterManufacturer.isEnphase && <CustomSelect
                    id="device-status"
                    name="status"
                    className="grow"
                    size="select-sm"
                    options={[
                        { value: '', title: 'Any Status' },
                        { value: 'normal', title: 'Normal' },
                        { value: 'error', title: 'Error' },
                        { value: 'disabled', title: 'Disabled' }
                    ]}
                    value={filters.status}
                    onChange={handleFiltersChange}
                />}
                <Input
                    id="monitoring-search"
                    name="search"
                    size="input-sm"
                    placeholder="Search by SN"
                    value={filters.search}
                    onChange={handleFiltersChange}
                    preIcon={<IconSearch className="stroke-gray-700"/>}
                />
            </div>
        </div>

        <p className="text-gray-500 text-xs mb-4">
            Last updated at {warranty.homeowner?.monitoring_data?.updated_at ? new Date(`${warranty.homeowner?.monitoring_data?.updated_at}Z`).format({
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short',
                hour12: false
            }) : '-'}
        </p>

        <Table
            useQueryParams={false}
            searchable={false}
            pagination={false}
            columns={[
                { field: 'name', title: 'Device Type' },
                { field: 'manufacturer', title: 'Manufacturer', showIf: warranty.homeowner?.system?.inverterManufacturer.isSolaredge },
                { field: 'serial_number', title: 'Serial Number (SN)' },
                { field: 'type', title: 'Type', showIf: warranty.homeowner?.system?.inverterManufacturer.isSolaredge },
                { field: 'last_report', title: 'Last Report', showIf: warranty.homeowner?.system?.inverterManufacturer.isEnphase },
                { field: 'form', title: 'Form', showIf: warranty.homeowner?.system?.inverterManufacturer.isSolaredge, cellClassName: 'capitalize' },
                { field: 'status', title: 'Status', showIf: warranty.homeowner?.system?.inverterManufacturer.isEnphase },
                { field: 'state', title: 'State', showIf: warranty.homeowner?.system?.inverterManufacturer.isEnphase, cellClassName: 'capitalize' },
                { field: 'model', title: 'Model' },
                { field: 'connected_to', title: 'Connected to', showIf: warranty.homeowner?.system?.inverterManufacturer.isSolaredge }
            ]}
            rows={getRows()}
        />
    </>
}

export default DeviceDetailsDefault
