import React, { ReactNode } from 'react'
// import ReactQuill from 'react-quill'

import InputError from './InputError'
import 'react-quill/dist/quill.snow.css'

const ReactQuill = React.lazy(() => import('react-quill'))

type InputProps = {
    label?: ReactNode
    id: string
    name?: string
    placeholder?: string
    rows?: number
    cols?: number
    className?: string
    value: string
    errors?: string | string[]
    hint?: string
    preIcon?: ReactNode
    postIcon?: ReactNode
    required?: boolean
    onChange?: any
    size?: 'editor-md' | 'editor-sm'
    disabled?: boolean
}

const colors = [
    'transparent', '#fff', '#000', '#fd0', '#00a878', '#3ebcda', '#f0eeea',
    '#e3f3eb', '#c5e6d6', '#98d3b8', '#00a071', '#008054', '#006a40', '#004822',
    '#e2f3f1', '#c0e5e1', '#8fd2cb', '#009d95', '#007d76', '#006862', '#004641',
    '#f5f4f1', '#edebe7', '#a2a19e', '#61605f', '#4d4c4b', '#403f35', '#181717',
    '#e1f2f8', '#bee4f0', '#8bd1e6', '#009ab7', '#007a96', '#006480', '#00425d',
    '#e1f2f8', '#bee4f0', '#2eb2d0', '#00526d', '#00425d', '#003f59', '#002d47',
    '#edeff5', '#daddea', '#909dc2', '#576fa3', '#7c8cb7', '#3a5c96', '#003d7b',
    '#f0eef7', '#e0dbed', '#c0b7da', '#796db2', '#6256a5', '#51479b', '#322d7d',
    '#ffeaed', '#fed3d9', '#f9acb8', '#e75c7d', '#dd3367', '#c80255', '#90002c',
    '#fffbd4', '#fff37c', '#ffe200', '#f1c907', '#e4be0d', '#d5b211', '#806b18',
    '#ffebce', '#ffd8a2', '#ffb953', '#e79100', '#ca7a00', '#904a00', '#6b2a00',
    '#e1f2f8', '#bee4f0', '#8bd1e6', '#009ab7', '#007a96', '#006480', '#00425d',
    '#ffe9e4', '#ffd5cb', '#ffb4a5', '#ff4a41', '#df1022', '#bd000a', '#820000'
]

const Input = ({ label, value, id, errors = [], onChange, hint, className, preIcon, postIcon, size = 'editor-md', ...props }: InputProps) => {
    const classNames = () => {
        const arr = ['editor']
        if (errors.length) arr.push('has-error')
        if (className) arr.push(className)
        if (size) arr.push(size)
        return arr.join(' ')
    }

    const handleChange = (value: string) => {
        onChange({
            target: {
                value,
                name: props.name
            }
        })
    }

    return <div className={classNames()}>
        {label && <label htmlFor={id}>{label}</label>}

        <div className={`editor-container ${preIcon ? 'has-pre-icon' : ''} ${postIcon ? 'has-post-icon' : ''}`}>
            {preIcon && <label className="editor-icon editor-pre-icon" htmlFor={id}>{preIcon}</label>}
            <ReactQuill
                id={id}
                value={value}
                onChange={handleChange}
                theme="snow"
                className="min-h-[15rem]"
                modules={{
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'image', 'video', 'link'],

                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],

                        [{ header: [1, 2, 3, false] }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ color: colors }, { background: colors }],
                        [{ align: [] }],

                        ['clean']
                    ]
                }}
                {...props}
            />
            {postIcon && <label className="editor-icon editor-post-icon" htmlFor={id}>{postIcon}</label>}
        </div>

        {hint && <div className="text-gray-500 text-sm mt-1.5">{hint}</div>}

        <InputError errors={errors}/>

    </div>
}
export default Input
