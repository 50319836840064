import { ReactNode, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Notifications } from './notifications'
import {
    Breadcrumb,
    Button,
    Image,
    Dropdown,
    WarrantyCreateButton, Feature,
    EnterImpersonationModePopup,
    IconGlasses, ExitImpersonationModePopup
} from '@/components'
import { useAuth } from '@/hooks'


type HeaderProps = {
    children: ReactNode
    breadcrumb?: Array<ReactNode>
    className: string
}

const Header = ({ children, className, breadcrumb }: HeaderProps) => {
    const location = useLocation()
    const auth = useAuth()
    const [showEnterImpersonationPopup, setShowEnterImpersonationPopup] = useState(false)
    const [showExitImpersonationPopup, setShowExitImpersonationPopup] = useState(false)

    return <header className={`flex flex-col w-full ${className}`}>
        <div className="flex justify-between flex-col md:flex-row items-baseline w-full">
            {breadcrumb ? <Breadcrumb items={breadcrumb}/> : <div/>}
            <div className="flex items-center md:items-start gap-3 justify-between sm:justify-start relative">
                {auth.user?.isContractor && <>
                    <WarrantyCreateButton/>

                    <Feature name="claims">
                        <Button href="/claims/create" design="btn-secondary-gray">
                            File a Claim
                        </Button>
                    </Feature>
                </>}

                {auth.user?.isAdminOrStaff && location.pathname === '/warranties-by-contractors' &&
                    <Button href="/warranties-by-contractors/upload-affiliates-data">
                        Upload Affiliate Data
                    </Button>}

                <Notifications/>

                <Dropdown
                    placement="bottom-end"
                    button={<Button design={auth.user?.isImpersonated ? 'btn-secondary' : 'btn-secondary-gray'} size="btn-lg" square data-test="header-profile-button">
                        {auth.user?.isImpersonated
                            ? <IconGlasses className="stroke-black"/>
                            : <Image className="w-5 rounded-full" src={`https://ui-avatars.com/api/?name=${auth.user?.name[0]}&color=7F9CF5&background=EBF4FF`}/>}
                    </Button>}
                >
                    <div className="px-2 flex flex-col">
                        <Button design="btn-link-gray" className="w-full !justify-start" href="/profile" data-test="header-profile-link">
                            Profile
                        </Button>
                        {auth.user?.isAdminOrStaff && !auth.user?.isImpersonated && <Button
                            design="btn-link-gray"
                            className="whitespace-nowrap"
                            onClick={() => setShowEnterImpersonationPopup(true)}
                            data-test="header-impersonation-mode-link"
                        >
                            Impersonation mode
                        </Button>}
                        {auth.user?.isImpersonated && <Button
                            design="btn-link-gray"
                            className="whitespace-nowrap"
                            onClick={() => setShowExitImpersonationPopup(true)}
                            data-test="header-impersonation-mode-link"
                        >
                            Exit Impersonation mode
                        </Button>}
                        <Button design="btn-link-gray" className="w-full !justify-start" href="/logout" data-test="header-logout-link">
                            Logout
                        </Button>
                    </div>
                </Dropdown>
            </div>
        </div>
        <div className="flex sm:block flex-col sm:flex-row justify-between mb-4 sm:mb-0">
            {children}
        </div>

        {auth.user?.isAdminOrStaff && showEnterImpersonationPopup &&
            <EnterImpersonationModePopup
                isOpen={true}
                onClose={() => setShowEnterImpersonationPopup(false)}
            />}

        <ExitImpersonationModePopup
            isOpen={showExitImpersonationPopup}
            onClose={() => setShowExitImpersonationPopup(false)}
        />
    </header>
}

export default Header
