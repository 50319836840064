import { Upload } from '@/components'

type DocumentsFormProps = {
    form: any
    onChange: any
    errors?: any
    inputClassName?: string
}
const DocumentsForm = ({ form, inputClassName, errors, ...props }: DocumentsFormProps) =>
    <>
        <Upload
            multiple
            value={form.contracts}
            onChange={props.onChange}
            name="contracts"
            accept="application/pdf"
            className={inputClassName}
        >
            <div className="mt-3 text-center">
                {form.contracts?.length
                    ? <p className="text-sm">{form.contracts.map((item: File, index: number) =>
                        <span key={index}>{item.name}</span>)}</p>
                    : <>
                        <p className="text-sm">
                            <span className="text-orange-700">Click to upload</span> or drag and drop</p>
                        <p className="text-xs mt-1">PDF (max. 10mb)</p>
                    </>}
            </div>
        </Upload>
    </>

export default DocumentsForm
