import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, IconInfoCircle, Popup, WarrantyUpdateDetails, Tooltip } from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'


type WarrantyReviewEditRequestPopupProps = {
    warranty: Warranty
    onChange: any
}

const WarrantyReviewEditRequestPopup = ({ warranty, ...props }: WarrantyReviewEditRequestPopupProps) => {
    const auth = useAuth()
    const navigate = useNavigate()
    const { type } = useParams()
    const [processing, setProcessing] = useState(false)

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (auth.user?.isAdminOrStaff && warranty.isEditRequested && warranty.pending_update_requests.length) {
            setIsOpen(true)
        }
    }, [auth.user, warranty.isEditRequested, warranty.pending_update_requests.length])

    const getUpdateRequest = () => warranty.pending_update_requests[0]

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        try {
            await Warranty.updateRequest(getUpdateRequest().id, e.nativeEvent.submitter.name)
            setIsOpen(false)
            navigate(`/${type}/${warranty.id}/warranty`)
        } finally {
            setProcessing(false)
        }
    }

    const handleModifyForm = () => {
        const requestedChanges = getUpdateRequest().requested_changes
        Object.keys(requestedChanges).forEach(key => {
            let value: any
            if (key === 'install_date') {
                if (new Date(requestedChanges[key]).isValid()) {
                    value = new Date(requestedChanges[key])
                } else if (new Date(requestedChanges[key].replaceAll('-', '/')).isValid()) {
                    value = new Date(requestedChanges[key].replaceAll('-', '/'))
                } else {
                    value = ''
                }
            } else if (key === 'batteries') {
                value = requestedChanges[key] || []
            } else {
                value = requestedChanges[key] || ''
            }
            props.onChange({
                target: {
                    name: key,
                    value
                }
            })
        })
        props.onChange({ target: { name: 'warranty_update_request_id', value: getUpdateRequest().id } })
        setIsOpen(false)
    }

    return isOpen ? <Popup open={isOpen} onClose={() => setIsOpen(false)} data-test="approve-warranty-edits-popup">
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 text-gray-900 lg:min-w-[45rem]" noValidate>
            <p className="text-lg font-semibold flex gap-2 items-center">
                <IconInfoCircle className="stroke-warning-600" size="lg"/>
                Approve Warranty Edits
            </p>

            <WarrantyUpdateDetails
                oldValues={{
                    ...warranty,
                    ...warranty.homeowner,
                    affiliate_name: warranty.affiliate?.name,
                    panel_name: warranty.panel.model_name,
                    inverter_name: warranty.inverter.model_name,
                    inverter_manufacturer: warranty.inverter.provider
                }}
                newValues={{
                    ...getUpdateRequest().requested_changes,
                    price: getUpdateRequest().new_price
                }}
            />

            <p className="text-sm font-semibold" data-test="approve-edits-text">
                Do you want to save these edits to the warranty?
            </p>

            <div className="flex flex-col lg:flex-row gap-2 justify-between">
                <Tooltip content="Modify Warranty Edits">
                    <Button type="button" design="btn-secondary" onClick={handleModifyForm} data-test="modify-warranty-edits-button">Modify Warranty Edits</Button>
                </Tooltip>

                <div className="flex justify-end gap-2">
                    <Button className="w-40" design="btn-secondary-gray" hasError name="deny" processing={processing} data-test="deny-warranty-edits-button">
                        Deny Edits
                    </Button>
                    <Button className="w-40" name="approve" processing={processing} data-test="approve-warranty-edits-button">
                        Yes, Approve Edits
                    </Button>
                </div>
            </div>
        </form>
    </Popup> : null
}

export default WarrantyReviewEditRequestPopup
