import {
    Button,
    Tooltip,
    IconFlipBackward,
    IconClose,
    WarrantyReviewCancellationReinstatementRequestPopup,
    WarrantyReinstatePopup
} from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyIndexReinstatementButtonsProps = {
    warranty: Warranty
    onChange: () => void
}

const WarrantyIndexReinstatementButtons = ({ warranty, ...props }: WarrantyIndexReinstatementButtonsProps) => {
    const auth = useAuth()

    if (auth.user?.isAdminOrStaff && warranty.isReinstatementRequested) {
        return <WarrantyReviewCancellationReinstatementRequestPopup warranty={warranty} onChange={props.onChange}>
            <Tooltip content="Review Reinstatement Request">
                <Button square design="btn-secondary-gray" size="btn-lg" hasError>
                    <IconClose/>
                </Button>
            </Tooltip>
        </WarrantyReviewCancellationReinstatementRequestPopup>
    }
    if (warranty.isCanceled) {
        return <WarrantyReinstatePopup warranty={warranty} onChange={props.onChange}>
            <Tooltip content={auth.user?.isAdminOrStaff ? 'Reinstate Warranty' : 'Request Reinstate Warranty'}>
                <Button square design="btn-secondary-gray" size="btn-lg" hasError>
                    <IconFlipBackward/>
                </Button>
            </Tooltip>
        </WarrantyReinstatePopup>
    }
    return null
}

export default WarrantyIndexReinstatementButtons
