type InputErrorProps = {
    errors: string | string[]
}

const InputError = ({ errors, ...props }: InputErrorProps) =>
    <>
        {typeof errors === 'string'
            ? <div className="text-warning-700 text-sm mt-1.5" {...props}>{errors}</div>
            : errors.map((item: string, index: number) =>
                <div className="text-warning-700 text-sm mt-1.5" {...props} key={index}>{item}</div>)}
    </>

export default InputError
