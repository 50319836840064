import { useState } from 'react'
import { createSearchParams, Link, useLocation } from 'react-router-dom'

import MonitoringDonutReport from './MonitoringDonutReport'
import { Button, Card, IconChevronUp, IconHelpCircle, Tooltip } from '@/components'
import { SystemStatusCategoryEnum } from '@/enums'
import { useQuery } from '@/hooks'
import { InverterManufacturer } from '@/models'

type MonitoringReportProps = {
    data: any
}

const MonitoringReport = ({ data }: MonitoringReportProps) => {
    const [isExpanded, setIsExpanded] = useState(true)
    const location = useLocation()
    const query = useQuery()

    const handleExpandToggleClick = () => {
        setIsExpanded(isExpanded => !isExpanded)
    }

    const getMonitoringIndexPage = (filters: any) => {
        if (location.pathname === '/monitoring') {
            return `/monitoring?${createSearchParams({ ...query, ...filters })}`
        }
        return `/monitoring?${createSearchParams(filters)}`
    }

    return <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-6 lg:gap-6 mb-4">
        <Card className="flex flex-col justify-between items-start">
            <div className="w-full">
                <h4 className="text-sm font-body leading-5 text-gray-500 flex justify-between" data-test="monitoring-index-total-sites-title">
                    Connected Sites
                    <Tooltip content={<div className="font-body leading-[18px]">
                        <h3 className="font-semibold">Connected Sites</h3>
                        <p className="mt-1">
                            Are solar systems that have been successfully integrated into the Solar Insure Monitoring
                            dashboard. This is achieved by matching registration data with supported inverter
                            manufacturers’ database, which enables seamless data integration and regular monitoring
                            updates.
                        </p>
                    </div>}>
                        <IconHelpCircle className="stroke-gray-500"/>
                    </Tooltip>
                </h4>
                <div className="flex justify-between items-center mt-3">
                    <span className="font-display font-semibold text-4xl leading-[2.75rem]" data-test="monitoring-index-connected-sites-count">
                        {(data.groups[SystemStatusCategoryEnum.MONITORED] || 0).format()}
                    </span>
                </div>
                {isExpanded && <>
                    <h4 className="text-sm font-body leading-5 text-gray-500 flex justify-between mt-3" data-test="monitoring-index-connected-sites-title">
                        Total Sites

                        <Tooltip
                            content={<div className="font-body leading-[18px]">
                                <h3 className="font-semibold">Total Sites</h3>
                                <p className="mt-1">
                                    Sum of all homeowner sites with Solar Insure warranties. It includes Connected Sites
                                    with full dashboard integration, Unmonitored Sites awaiting database match, and
                                    Excluded Sites not currently monitored. This total reflects Solar Insure&apos;s
                                    complete service reach.
                                </p>
                            </div>}
                        >
                            <IconHelpCircle className="stroke-gray-500"/>
                        </Tooltip>
                    </h4>
                    <p className="mt-1 text-lg leading-7" data-test="monitoring-index-total-sites-count">
                        {data.sites.total?.format()}
                    </p>
                </>}
            </div>

            <Button design="btn-link" onClick={handleExpandToggleClick} className="mt-2 gap-2">
                <IconChevronUp className={`stroke-black transition-all ${!isExpanded ? 'rotate-180' : ''}`}/>
                See {isExpanded ? 'Less' : 'More'}
            </Button>
        </Card>

        <Card className="col-span-2 flex flex-col justify-between items-start relative">
            <MonitoringDonutReport
                data={data}
                isExpanded={isExpanded}
            />

            <Button design="btn-link" onClick={handleExpandToggleClick} className="mt-2 gap-2 absolute bottom-6 left-6">
                <IconChevronUp className={`stroke-black transition-all ${!isExpanded ? 'rotate-180' : ''}`}/>
                See {isExpanded ? 'Less' : 'More'}
            </Button>

            <div className="self-end">
                <Tooltip content="Currently Supported Integrations">
                    <div className="flex">
                        <Link to={getMonitoringIndexPage({ manufacturer: InverterManufacturer.enphase.key })} className="p-1.5 rounded-full border border-gray-300 bg-white translate-x-3 z-[1] hover:z-10">
                            {InverterManufacturer.enphase.getLogo()}
                        </Link>
                        <Link to={getMonitoringIndexPage({ manufacturer: InverterManufacturer.solaredge.key })} className="p-1.5 rounded-full border border-gray-300 bg-white translate-x-1.5 z-[2] hover:z-10">
                            {InverterManufacturer.solaredge.getLogo()}
                        </Link>
                        <Link to={getMonitoringIndexPage({ manufacturer: InverterManufacturer.generac.key })} className="p-1.5 rounded-full border border-gray-300 bg-white z-[3] hover:z-10">
                            {InverterManufacturer.generac.getLogo()}
                        </Link>
                    </div>
                </Tooltip>
            </div>
        </Card>
    </div>
}

export default MonitoringReport
