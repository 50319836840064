import { useState } from 'react'

import { Button, IconDownload, Upload } from '@/components'
import { useToastNotifications, useAnalytics } from '@/hooks'
import { Warranty } from '@/models'
import { excel, JSONResult } from '@/services'

type UploadStepProps = {
    onNext: () => void
    onChange: (json: JSONResult) => void
}

const UploadStep = ({ ...props }: UploadStepProps) => {
    const { trackEvent } = useAnalytics()
    const { error } = useToastNotifications()
    const [file, setFile] = useState<File | null>(null)
    const [errors, setErrors] = useState<any>({})
    const handleFileChange = async (e: any) => {
        const [file] = e.target.value
        if (file) {
            if (file.size > 1e+7) {
                setErrors({ file: 'The uploaded file is larger than 10MB.' })
                error('Please upload a new .csv file', 'Invalid file')
            } else if (file.type === 'text/csv') {
                try {
                    const json = await excel.parse(file)
                    setFile(file)
                    setErrors({})
                    props.onChange(json)
                } catch (err) {
                    setErrors({ file: 'The uploaded file is corrupt and cannot be read.' })
                    error('Please upload a new .csv file', 'Invalid file')
                }
            } else {
                setErrors({ file: 'Oops, something went wrong. Please upload a new file to continue.' })
                error('Please upload a .csv file', 'Invalid file type')
            }
        }
    }

    const handleClickNext = () => {
        props.onNext()
        trackEvent('click_bulk_upload_step_1_continue', 'User Interaction', 'Bulk Upload Continue Button Click')
    }

    const handleHoverNext = () => {
        trackEvent('hover_bulk_upload_step_1_continue', 'User Interaction', 'Bulk Upload Continue Button Hover')
    }

    return <>
        <div className="flex justify-between items-end">
            <div className="text-gray-800 leading-6">
                <h3 className="font-semibold">
                    Upload your CSV.
                </h3>
                <p>
                    Column order doesn’t matter; we’ll sort it out in the next step. Use our sample template as a
                    guide!
                </p>
            </div>

            <Button design="btn-secondary-gray" onClick={Warranty.downloadExampleCsv} className="gap-2">
                <IconDownload/>
                Download sample CSV
            </Button>
        </div>
        <Upload
            value={file}
            onChange={handleFileChange}
            accept=".csv,text/csv"
            errors={errors.file}
            className="mt-8"
        >
            <div className="mt-3 text-center">
                {file
                    ? <p className="text-sm text-gray-500">
                        <span className="text-orange-700">{file.name}</span> uploaded successfully
                    </p>
                    : <>
                        <p className="text-sm text-gray-500">
                            <span className="text-orange-700">Click to upload</span> or drag and drop
                        </p>
                        <p className="text-xs mt-1 text-gray-500">(.csv files only, 10MB max. One file at a time)</p>
                    </>}
            </div>
        </Upload>

        <div className="flex flex-col lg:flex-row justify-end items-center gap-3 mt-6">
            <Button href="/warranties" design="btn-secondary-gray">
                Cancel
            </Button>
            <Button onClick={handleClickNext} onMouseOver={handleHoverNext} disabled={!file || errors.file}>
                Continue
            </Button>
        </div>
    </>
}

export default UploadStep
