import { Fragment } from 'react'

import { IconStep, IconStepPassed } from '@/components'

type StepsProps = {
    steps: string[]
    step: number
    onChange?: any
    className?: string
}

const Steps = ({ steps, step, className }: StepsProps) =>
    <div className={`steps ${className ? className : ''}`}>
        {steps.map((item, index) =>
            <Fragment key={index}>
                {index > 0 && <div className={`steps-line ${step >= index ? 'passed' : ''}`}/>}
                <div className={`steps-button ${step === index ? 'active' : ''} ${step > index ? 'passed' : ''}`}>
                    {step > index && <IconStepPassed
                        size="xxl"
                    />}
                    {step <= index && <IconStep
                        className={step === index ? 'stroke-primary-700 stroke-primary-700' : 'stroke-gray-200'}
                        size="xxl"
                    />}
                    <span className="steps-button-title">{item}</span>
                </div>
            </Fragment>)}
    </div>

export default Steps
