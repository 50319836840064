import { useEffect, useState } from 'react'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'

import {
    Card,
    IconCertificate,
    PageHeader,
    Steps,
    EditDataStep,
    FinalStep,
    FAQ,
    UploadStep,
    Button,
    IconArrowRight
} from '@/components'
import { WARRANTY_COLUMNS } from '@/components/bulk-upload/default-coolumns'
import { AuthLayout } from '@/containers'
import { useAnalytics, useAppDispatch, useAppSelector, useToastNotifications } from '@/hooks'
import { User, Warranty } from '@/models'
import type { JSONResult } from '@/services'
import { fetchNotifications, showNotifications } from '@/store'

const breadcrumb = [
    <IconCertificate key={1} className="stroke-gray-500"/>,
    <Link key={2} to="/warranties">Warranties</Link>,
    <Link key={2} to="/warranties/create">Add a New Warranty</Link>,
    'Bulk Upload'
]

const WarrantiesUpload = () => {
    const { trackEvent } = useAnalytics()
    const uploadAs = useLoaderData() as User | null
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { success } = useToastNotifications()
    const auth = useAppSelector(({ auth }) => auth)
    const [step, setStep] = useState(0)
    const [processing, setProcessing] = useState(false)
    const [data, setData] = useState<JSONResult>([])
    const [columns, setColumns] = useState(
        WARRANTY_COLUMNS.filter(column => !column.admin || auth.user?.isAdminOrStaff)
    )
    const [errors, setErrors] = useState({})
    const [form, setForm] = useState<{ send_emails: boolean, warranties: JSONResult }>({
        send_emails: false,
        warranties: []
    })
    useEffect(() => {
        const user = uploadAs || auth.user
        const savedColumns = user?.preferences?.warranty_prefs
        const res = savedColumns ? columns.map(item => {
            const pref = savedColumns.find(({ value }: any) => value === item.value)
            return {
                ...item, ...pref,
                show: item.readonly || pref?.show || false
            }
        }) : columns

        setColumns(res)
    }, [])

    useEffect(() => {
        setForm({
            ...form,
            warranties: data.map((item: any) => {
                const values = Object.values(item)
                return columns.reduce((acc, {
                    value,
                    index,
                    show
                }) => {
                    if (!show) return acc
                    return {
                        ...acc,
                        [value]: values[index]
                    }
                }, {})
            })
        })
    }, [data, columns])

    const handleChange = ({
        target: {
            name,
            checked
        }
    }: any) => {
        setForm({
            ...form,
            [name]: checked
        })
    }

    const handleSubmit = async () => {
        trackEvent('click_bulk_upload_step_2_save', 'User Interaction', 'Bulk Upload Save Button')
        const ROWS_COUNT = 200
        setProcessing(true)
        setErrors({})
        const user = uploadAs || auth.user
        for (let i = 0; i < form.warranties.length; i += ROWS_COUNT) {
            const warranties = form.warranties.slice(i, i + ROWS_COUNT)
            const payload = {
                warranties,
                send_emails: form.send_emails,
                chunk_index: i / ROWS_COUNT,
                user_id: uploadAs ? user?.id : undefined
            }
            try {
                await Warranty.upload(payload)
                dispatch(fetchNotifications)
                const toast = success('Bulk upload is being processed. You can track the progress in the notifications center.')
                toast.onClose = () => dispatch(showNotifications())
                setStep(2)
            } catch (err: any) {
                if (err.errors) {
                    setErrors(errors => ({ ...errors, ...err.errors }))
                }
            } finally {
                setProcessing(false)
            }
        }
    }

    const handleComplete = () => {
        navigate('/warranties')
    }

    return <AuthLayout
        heading={
            <PageHeader
                title={<div className="w-full flex justify-between items-center mt-7.5">
                    Bulk Upload

                    <div className="flex gap-4 text-base">
                        <span className="text-gray-700">Need to register a 20-Yr Battery Warranty?</span>
                        <Button design="btn-link" className="!text-primary-800 !stroke-primary-800 gap-2" href="/warranties/create">
                            <IconArrowRight/>
                            Go to Single Warranty Registration
                        </Button>
                    </div>
                </div>}
                backUrl="/warranties/create"
            />
        }
        breadcrumb={breadcrumb}
    >
        <Card className="!p-8">
            <Steps
                className="mt-4 mx-auto max-w-3xl"
                step={step}
                steps={[
                    'Upload',
                    'Verify Columns',
                    'Finalize Upload'
                ]}
            />

            <div className="mt-8">
                {step === 0 && <UploadStep
                    onChange={setData}
                    onNext={() => setStep(1)}
                />}
                {step === 1 && <>
                    <EditDataStep
                        form={form}
                        errors={errors}
                        columns={columns}
                        headers={Object.keys(data[0])}
                        onNext={handleSubmit}
                        onPrev={() => setStep(0)}
                        onChange={handleChange}
                        onColumnsChange={setColumns}
                        processing={processing}
                    />
                </>}

                {step === 2 && <FinalStep
                    onNext={handleComplete}
                />}
            </div>
        </Card>

        {step === 0 && <FAQ className="mt-8"/>}
    </AuthLayout>
}

export default WarrantiesUpload
