import { DetailedHTMLProps, FormHTMLAttributes, ReactNode } from 'react'

import { useConfirmRouteChange } from '@/hooks'

interface FormProps extends DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
    children: ReactNode
    confirmation?: boolean
    isDirty?: boolean
}

const Form = ({ children, confirmation = false, isDirty = false, ...props }: FormProps) => {
    const confirmationPopup = useConfirmRouteChange(isDirty && confirmation, props.onSubmit)
    return <form {...props}>
        {children}
        {confirmationPopup}
    </form>
}

export default Form
