import { ChangeEvent } from 'react'

import { Input } from '@/components'
import { Company, Warranty } from '@/models'

type PolicyRatesFormProps = {
    form: any
    warranty: Warranty
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    errors?: any
    company: Company
    inputClassName?: string
}
const PolicyRatesForm = ({ form, inputClassName, warranty, errors, company, ...props }: PolicyRatesFormProps) =>
    <>
        <Input
            id="policy-rate"
            name="policy_rate"
            label="Policy rate*"
            placeholder="Policy rate"
            onChange={props.onChange}
            value={form.policy_rate}
            errors={errors.policy_rate}
            mask={{
                numeral: true,
                numeralDecimalScale: 2,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none'
            }}
        />

        <Input
            id="battery-rate"
            name="battery_rate"
            label={`${company.batteryEligibility.title} Warranty Rate*`}
            placeholder="Battery rate"
            onChange={props.onChange}
            value={form.battery_rate}
            errors={errors.battery_rate}
            mask={{
                numeral: true,
                numeralDecimalScale: 2,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none'
            }}
        />
    </>

export default PolicyRatesForm
