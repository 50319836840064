import { useEffect, useState } from 'react'

import { Button, Popup } from '@/components'
import { Company, Product, WarrantyProductType } from '@/models'

type BatteryWarrantyPreviewProps = {
    form: any
    company: Company

    onClose: any
    onNext: any
}

const BatteryWarrantyPreview = ({ form, company, ...props }: BatteryWarrantyPreviewProps) => {
    const [batteryProducts, setBatteryProducts] = useState<Product[]>()
    const fetchProducts = async () => {
        const res = await Product.onlyBatteries()
        setBatteryProducts(res)
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    const getRows = () => {
        const policyRate = company.policy_rate as number
        const batteryRate = company.isEligibleFor20YearBatteries
            ? company.battery_20_year_rate as number
            : company.battery_30_year_rate as number
        const systemTitle = `${form.size_kw} kW Solar System`
        return form.batteries.reduce((acc: any[], item: any) => {
            const product = batteryProducts
                ?.find(({ id }) => id?.toString() === item.product_id?.toString())
            const title = `${product?.model_name} (${parseFloat(item.size_kw).format()} kWh)`
            const index = acc.findIndex(item => item.title === title)
            if (index === -1) {
                return [
                    ...acc,
                    {
                        title,
                        price: batteryRate * parseFloat(item.size_kw) * 1000,
                        qty: 1
                    }
                ]
            }

            acc[index].qty += 1
            return acc
        }, [{
            isPanel: true,
            title: systemTitle,
            price: policyRate * form.size_kw * 1000,
            qty: 1
        }])
    }

    const getTotal = () => getRows()
        .reduce((acc: number, { price, qty }: { price: number, qty: number }) => acc + (price * qty), 0)

    return <div className="battery-warranty-preview font-body">
        <Popup open={true} onClose={props.onClose}>
            <h4 className="font-semibold">Continue with a {company.batteryEligibility.title} Warranty?</h4>
            <p className="text-gray-500 mt-1">Adding a {company.batteryEligibility.title} Warranty will increase the total price, but we believe it{'\''}s worth it for the peace of mind it provides homeowners.</p>

            {getRows().map((item: any) =>
                <div className="flex mt-4 gap-4 text-xs" key={item.title}>
                    <div className="bg-gray-50 w-16 h-16 flex justify-center items-center">
                        {item.isPanel
                            ? WarrantyProductType.solar.icon('sm')
                            : company.batteryEligibility.icon('sm')}
                    </div>
                    <div className="leading-[18px]">
                        {item.isPanel
                            ? <h5 className="font-semibold mt-0.5">{WarrantyProductType.solar.title}</h5>
                            : <h5 className="font-semibold mt-0.5">{company.batteryEligibility.title} Warranty</h5>}
                        <p className="text-gray-500">{item.title}</p>
                        <div className="mt-1.5">
                            <span className="text-gray-700">{item.price.money()}</span>
                            <span className="text-gray-400 ml-4">x{item.qty}</span>
                        </div>
                    </div>
                </div>)}

            <div className="flex justify-between mt-8.5">
                <span className="text-gray-500">Total</span> <span className="font-semibold">{getTotal().money()}</span>
            </div>

            <Button size="btn-sm" className="w-full mt-8" onClick={props.onNext}>
                Continue & Generate Certificate
            </Button>
            <Button size="btn-sm" design="btn-link" className="w-full mt-3" onClick={props.onClose}>
                Back to Registration
            </Button>
        </Popup>
    </div>
}

export default BatteryWarrantyPreview
