import { saveAs } from 'file-saver'

export type JSONResultRow = {
    [key: string]: string | number
}
export type JSONResult = JSONResultRow[]

export default {
    parse(file: File): Promise<JSONResult> {
        return new Promise((resolve, reject) => {
            if (!file) return
            const reader = new FileReader()
            reader.onload = async (e: any) => {
                try {
                    const XLSX = await import('xlsx')
                    const workbook = XLSX.read(e.target.result, { type: 'binary', raw: true, cellDates: true })
                    workbook.SheetNames.forEach((sheetName: string) => {
                        const [header, ...data]: any =
                            XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })
                        const parsed = data.map((row: any) => header.reduce((acc: any, key: string, index: number) => {
                            const value = row[index]
                            return {
                                ...acc,
                                [key]: this.parseDate(value, key)
                            }
                        }, {}))
                        resolve(parsed)
                    })
                } catch (err) {
                    reject(err)
                }
            }
            reader.onerror = reject

            reader.readAsBinaryString(file)
        })
    },

    parseDate: (item: any, key: string) => {
        if (key.toLowerCase().includes('date')) {
            const date = new Date(item)
            const dateWithYear = new Date(`${item}/${new Date().getFullYear()}`)

            if (date.isValid()) {
                if (date.getFullYear() === 2001 && !(item || '').includes('2001')) {
                    date.setFullYear(new Date().getFullYear())
                    return date.format()
                }

                return date.format()
            } else if (dateWithYear.isValid()) {
                return dateWithYear.format()
            }
        }
        return item
    },

    async export(data: any, name = `export-${new Date().toISODate()}`, format = 'csv') {
        const XLSX = await import('xlsx')
        if (format === 'csv') {
            const ws = XLSX.utils.json_to_sheet(data)
            const csv = XLSX.utils.sheet_to_csv(ws)

            const s2ab = (s: any) => {
                const buf = new ArrayBuffer(s.length)
                const view = new Uint8Array(buf)
                for (let i = 0; i !== s.length; i = i + 1) view[i] = s.charCodeAt(i) & 0xFF
                return buf
            }

            saveAs(new Blob([s2ab(csv)], { type: 'application/octet-stream' }), `${name}.csv`)
        } else if (format === 'json') {
            saveAs(new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }), `${name}.json`)
        }
    }
}
