import { createPopper } from '@popperjs/core'
import maxSize from 'popper-max-size-modifier'
import { ReactNode, useEffect, useRef, useState } from 'react'

import { Button, IconChevronDown, IconClose } from '@/components'

type DropdownProps = {
    children: ReactNode
    button: ReactNode
    trigger?: 'click' | 'hover' | 'manual'
    show?: boolean
    className?: string
    disabled?: boolean
    withChevron?: boolean
    persistent?: boolean
    options?: any
    withCloseIcon?: boolean
    placement?: string
    offset?: any
    onChange?: any
}
const Dropdown = ({ children, className, trigger = 'click', placement = 'bottom-start', offset, button, withChevron = false, disabled = false, persistent = false, withCloseIcon = false, options = {}, ...props }: DropdownProps) => {
    const [show, setShow] = useState(false)
    const buttonRef = useRef<HTMLDivElement>(null)
    const dropdownContentRef = useRef<HTMLDivElement>(null)
    const [instance, setInstance] = useState<any>(null)

    const showDropDown = () => {
        if (disabled || !instance || show) return
        setShow(true)
        instance.setOptions((options: any) => ({
            ...options,
            modifiers: [
                ...options.modifiers,
                { name: 'eventListeners', enabled: true }
            ]
        }))
    }
    const hideDropdown = () => {
        setShow(false)
        if (!instance || !show) return
        instance.setOptions((options: any) => ({
            ...options,
            modifiers: [
                ...options.modifiers,
                { name: 'eventListeners', enabled: false }
            ]
        }))
    }

    useEffect(() => {
        if (props.onChange) props.onChange({ open: show })
    }, [show])

    const handleEvent = (e: any) => {
        if (trigger === 'manual') return
        if (disabled) {
            hideDropdown()
            return
        }
        if (trigger === 'click' && e.type === 'click') {
            if (show) hideDropdown()
            else showDropDown()
        }
        if (trigger === 'hover') {
            if (e.type === 'mouseenter' || e.type === 'focus' || (e.type === 'keydown' && e.key === 'Enter')) {
                showDropDown()
            }

            if ((e.type === 'mouseleave' || e.type === 'blur') && !persistent) {
                hideDropdown()
            }
        }
    }

    const handleDocumentKeyDown = (e: any) => {
        if (e.key === 'Escape') {
            hideDropdown()
        }
    }

    const handleDocumentClick = (e: any) => {
        const isClickedButton = buttonRef.current === e.target || buttonRef.current?.contains(e.target)
        const isClickedDropdown = dropdownContentRef.current === e.target ||
            dropdownContentRef.current?.contains(e.target)
        if (!isClickedButton && (!persistent || !isClickedDropdown)) {
            hideDropdown()
        }
    }

    useEffect(() => {
        if (typeof props.show !== 'undefined') {
            if (props.show) showDropDown()
            else hideDropdown()
        }
    }, [props.show])

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick)
        document.addEventListener('keydown', handleDocumentKeyDown)
        return () => {
            document.removeEventListener('click', handleDocumentClick)
            document.removeEventListener('keydown', handleDocumentKeyDown)
        }
    }, [])

    useEffect(() => {
        if (buttonRef.current && dropdownContentRef.current) {
            setInstance(createPopper(buttonRef.current, dropdownContentRef.current, {
                placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: { offset: offset || [0, 4] }
                    },
                    {
                        name: 'eventListeners',
                        options: {
                            scroll: true,
                            resize: true
                        }
                    },
                    maxSize,
                    {
                        name: 'applyMaxSize',
                        enabled: true,
                        phase: 'beforeWrite',
                        requires: ['maxSize'],
                        fn({ state }) {
                            const { height } = state.modifiersData.maxSize
                            state.styles.popper.maxHeight = `${height - 8}px`
                        }
                    },
                    {
                        name: 'preventOverflow',
                        options: { padding: 8 }
                    }
                ],
                ...options
            }))
        }
    }, [children])

    return <div className={`dropdown ${show ? 'open' : ''} ${className || ''}`}>
        <div
            role="button"
            tabIndex={-1}
            className="dropdown-trigger"
            ref={buttonRef}
            onClick={handleEvent}
            onMouseEnter={handleEvent}
            onMouseLeave={handleEvent}
            onKeyDown={handleEvent}
            onFocus={handleEvent}
            onBlur={handleEvent}
        >
            {button}
            {withChevron && <IconChevronDown className="chevron-icon stroke-gray-500"/>}
        </div>
        <div
            role="tooltip"
            data-show={show}
            className="dropdown-content"
            ref={dropdownContentRef}
        >
            {withCloseIcon && <Button design="btn-link" className="absolute top-4 right-4" onClick={hideDropdown}>
                <IconClose className="stroke-gray-500"/>
            </Button>}
            {children}
        </div>
    </div>
}

export default Dropdown
