import { BatteriesForm, Button, InverterForm, PanelForm } from '@/components'
import { Company, WarrantyProductType } from '@/models'

type WarrantyCreateSystemStepProps = {
    form: any
    onChange: any
    errors?: any
    onPrevious: any
    processing: boolean
    company?: Company
}
const WarrantyCreateSystemStep = ({ form, errors, company, processing, ...props }: WarrantyCreateSystemStepProps) =>
    <div className="grid lg:grid-cols-2 gap-6 mt-12.5" data-test="warranty-create-step-2">
        <h3 className="font-semibold lg:col-span-2">{WarrantyProductType.solar.title}</h3>

        <h3 className="font-semibold lg:col-span-2">Panel Information</h3>
        <PanelForm form={form} errors={errors} onChange={props.onChange}/>

        <hr className="lg:col-span-2"/>

        <h3 className="font-semibold lg:col-span-2">Inverter Information</h3>
        <InverterForm form={form} errors={errors} onChange={props.onChange} mode="create"/>

        {company?.isEligibleForBatteries && <>
            <hr className="lg:col-span-2"/>
            <h3 className="font-semibold lg:col-span-2">Battery Information</h3>
            <BatteriesForm form={form} errors={errors} onChange={props.onChange} company={company}/>
        </>}

        <div className="flex flex-col sm:flex-row gap-3 justify-between items-center lg:col-span-2">
            <p className="text-sm">
                {company?.isEligibleForBatteries && <>
                    <a href="https://www.solarinsure.com/battery" target="_blank" rel="noreferrer" className="text-orange-700 mr-1.5">Learn More</a>
                    <span className="text-xs text-gray-500">about our Battery Storage Warranty Product</span>
                </>}
            </p>
            <div className="flex justify-end gap-3">
                <Button design="btn-secondary-gray" type="button" onClick={props.onPrevious} processing={processing}>
                    Back
                </Button>
                <Button processing={processing} data-test="warranty-create-step-2-continue-button">
                    Save & Generate Certificate
                </Button>
            </div>
        </div>
    </div>

export default WarrantyCreateSystemStep
