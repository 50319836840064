import { useEffect, useState } from 'react'

import {
    Button, ClickToCopy,
    EditEnphaseMaintainerForm,
    EnphaseForm, IconCopy02, IconEye,
    IconEyeOff,
    InputError,
    IntegrationSetupConfirmedBanner, IntegrationSetupNotConfirmedBanner
} from '@/components'
import { useAuth } from '@/hooks'
import { Company, Integration, InverterManufacturer } from '@/models'

type EditEnphaseFormProps = {
    company: Company
    integration?: Integration
    onChange: () => void
    onAdded: () => void
}

const EditEnphaseForm = ({ company, integration, ...props }: EditEnphaseFormProps) => {
    const auth = useAuth()
    const [mode, setMode] = useState<'view' | 'edit'>(integration?.isValid || integration?.isUsesMaintainer ? 'view' : 'edit')
    const [showPassword, setShowPassword] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<any>({})
    const [warnings, setWarnings] = useState<any>({
        user: integration?.isInvalid ? integration.credentialStatus.message : '',
        secret: integration?.isInvalid ? integration.credentialStatus.message : ''
    })
    const getForm = () => ({
        company_id: company.id,
        provider: InverterManufacturer.enphase.key,
        user: integration?.user || '',
        secret: integration?.secret || ''
    })
    const [form, setForm] = useState(getForm())

    useEffect(() => {
        setForm(getForm())
    }, [company])

    const handleChange = ({ target: { name, value } }: any) => setForm({ ...form, [name]: value })

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        setErrors({})
        setWarnings({})

        try {
            if (integration) {
                await integration.update(form)
            } else {
                await Integration.store(form)
            }
            setMode('view')
            await props.onChange()
            props.onAdded()
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    return <div className="flex flex-col gap-6">
        {mode === 'edit'
            ? <>
                <form onSubmit={handleSubmit} className="max-w-[39rem]">
                    <div className="mb-6">
                        <div className="flex gap-x-6">
                            <EnphaseForm
                                errors={errors}
                                warnings={warnings}
                                form={form}
                                onChange={handleChange}
                            />
                        </div>

                        {integration?.isMissing && <InputError errors={integration.credentialStatus.message}/>}
                    </div>

                    <div className="flex gap-2">
                        <Button design="btn-secondary-gray" hasError onClick={() => setMode('view')}>
                            Cancel
                        </Button>
                        <Button processing={processing} disabled={!form.user || !form.secret}>
                            Submit Credentials
                        </Button>
                    </div>
                </form>
            </>
            : <div className="flex justify-between">
                <div className="flex gap-6 text-sm leading-5">
                    <div className="flex flex-col gap-0.5">
                        <div className="font-semibold text-gray-800">Enphase Username</div>
                        <div className="flex gap-1 items-center">
                            {integration?.user
                                ? <>
                                    {integration.user}
                                    <ClickToCopy content={integration.user}>
                                        <IconCopy02 className="stroke-gray-500" size="sm"/>
                                    </ClickToCopy>
                                </>
                                : '-'}
                        </div>
                    </div>
                    <div className="flex flex-col gap-0.5">
                        <div className="font-semibold text-gray-800">Enphase Password</div>
                        <div className="flex gap-1 items-center">
                            {integration?.secret
                                ? <>
                                    {integration?.secret?.split('')
                                        .map((item: string) => showPassword ? item : '•')}
                                    <button onClick={() => setShowPassword(state => !state)}>
                                        {showPassword
                                            ? <IconEyeOff className="stroke-gray-500" size="sm"/>
                                            : <IconEye className="stroke-gray-500" size="sm"/>}
                                    </button>
                                    <ClickToCopy content={integration?.secret}>
                                        <IconCopy02 className="stroke-gray-500" size="sm"/>
                                    </ClickToCopy>
                                </>
                                : '-'}
                        </div>
                    </div>
                </div>
                {!integration?.isUsesMaintainer && <Button design="btn-link" className="!text-primary-800" onClick={() => setMode('edit')}>
                    Edit Credentials
                </Button>}
            </div>}

        {auth.user?.isAdminOrStaff && <>
            <hr className="lg:col-span-2"/>

            <h2 className="text-xl font-normal">
                Enphase Monitoring System Data Integration Setup
            </h2>

            <EditEnphaseMaintainerForm
                company={company}
                integration={integration}
                onChange={props.onChange}
                onAdded={props.onAdded}
                onModeChange={setMode}
            />
        </>}

        {integration && <IntegrationSetupConfirmedBanner integration={integration}/>}

        {integration && <IntegrationSetupNotConfirmedBanner integration={integration}/>}
    </div>
}

export default EditEnphaseForm
