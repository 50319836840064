import { useMemo } from 'react'

import { Pagination, Select } from '@/components'

type TFooterProps = {
    total: number,
    page: number
    perPage: number
    onPageChange: any
    onPerPageChange: any
    showPerPage?: boolean
    'data-test'?: string
}

const PER_PAGE_OPTIONS = [
    5,
    10,
    15,
    25,
    35,
    50
]

const TFooter = ({
    total,
    perPage,
    showPerPage = true,
    page,
    'data-test': dataTest,
    ...props
}: TFooterProps) => {
    const perPageOptions = useMemo(() => PER_PAGE_OPTIONS.includes(perPage)
        ? PER_PAGE_OPTIONS
        : [...PER_PAGE_OPTIONS, perPage].sort((a, b) => a - b), [perPage])
    if (total <= perPageOptions[0]) return null

    return <div className="datatable-footer">
        <Pagination
            current={page}
            perPage={perPage}
            total={total}
            onChange={props.onPageChange}
            data-test={dataTest}
        />
        {showPerPage &&
            <Select
                id="per-page-select"
                size="select-sm"
                options={perPageOptions.map(item => ({
                    value: item,
                    title: item
                }))}
                value={perPage}
                onChange={(e: any) => props.onPerPageChange('per_page', e.target.value)}
                data-test={`${dataTest}-per-page-select`}
            />}
    </div>
}

export default TFooter
