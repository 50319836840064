import { ReactNode } from 'react'

import { Card } from '@/components'

type ReportBoxProps = {
    className?: string
    icon: ReactNode
    children?: ReactNode
    title: string
    value: number
    isMoney?: boolean
    'data-test'?: string
}

const ReportBox = ({ className, icon, title, value, children, isMoney, 'data-test': dataTest }: ReportBoxProps) =>
    <Card className={`${className} flex flex-col gap-6`} data-test={dataTest}>
        <h3 className="flex flex-col justify-center gap-4">
            <div className="flex items-center justify-start gap-4">
                <div className="w-14 h-14 rounded-full bg-warning-50 flex justify-center items-center" data-test={`${dataTest}-icon`}>
                    <div className="w-10 h-10 rounded-full bg-warning-100 flex justify-center items-center stroke-orange-600">
                        {icon}
                    </div>
                </div>
                <div>
                    <span data-test={`${dataTest}-title`}>{title}</span>
                    <p className="text-2xl font-semibold" data-test={`${dataTest}-value`}>{isMoney ? value.money() : value}</p>
                </div>
            </div>
        </h3>

        <div className="flex items-center justify-start">
            {children}
        </div>
    </Card>

export default ReportBox
