import { useEffect, useMemo, useState } from 'react'

import { Table } from '@/components'
import { useAppSelector } from '@/hooks'
import { api, WarrantySalesItemType } from '@/services'

type SalesTrackingByMonthProps = {
    className?: string
    onChange: (key: string) => void
    processing: boolean
}

const SalesTrackingByMonth = ({ className, processing, ...props }: SalesTrackingByMonthProps) => {
    const auth = useAppSelector(({ auth }) => auth)
    const [data, setData] = useState<Array<WarrantySalesItemType>>([])
    const [abortController, setAbortController] = useState<AbortController | null>(null)

    const fetchData = async () => {
        try {
            const controller = new AbortController
            setAbortController(controller)
            const res = await api.dashboard.warrantySales({
                start_date: new Date().subtract('year', 1).toISODate(),
                end_date: new Date().toISODate(),
                sortBy: 'month'
            }, { signal: controller.signal })

            setData(res)
        } finally {
            props.onChange('salesTrackingByMonth')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    useEffect(() =>
        () => {
            abortController?.abort('canceled')
        }, [abortController])

    const months = useMemo(() => {
        const res = []
        const start = new Date().startOf('month')
        const end = new Date().subtract('months', 11).startOf('month')

        while (start >= end) {
            res.push(start.clone())
            start.subtract('month', 1)
        }
        return res
    }, [])

    const rows = useMemo(() => months.map((item: Date) => ({
        id: item.toISODate(),
        month: item.format({ day: null }),
        warranties: data.reduce((acc, { warranties_sold: warrantiesSold, date }) =>
            new Date(`${date} 00:00`).toISODate() === item.toISODate() // Adding " 00:00" to ignore timezone
                ? acc + warrantiesSold
                : acc, 0).format(),
        sales: data.reduce((acc,
            {
                power_production_only: powerProductionOnly,
                production_with_20_year_separate_storage: productionWith20YearSeparateStorage,
                production_with_30_year_separate_storage: productionWith30YearSeparateStorage,
                date
            }) =>
            new Date(`${date} 00:00`).toISODate() === item.toISODate() // Adding " 00:00" to ignore timezone
                ? acc +
                powerProductionOnly +
                productionWith20YearSeparateStorage +
                productionWith30YearSeparateStorage
                : acc, 0)
            .money()
    })), [data])

    return <div className={`${className || ''}`}>
        <Table
            className="shadow-sm"
            useQueryParams={false}
            sortable={false}
            searchable={false}
            defaultPerPage={auth.user?.isContractor ? 5 : 6}
            showPerPage={!auth.user?.isContractor}
            columns={[
                { field: 'month', title: 'Month', cellClassName: '!py-5' },
                { field: 'warranties', title: 'Total Warranties', cellClassName: '!py-5' },
                { field: 'sales', title: 'Total Sales', cellClassName: '!py-5' }
            ]}
            rows={rows}
            data-test="dashboard-sales-tracking-table"
        />
    </div>
}

export default SalesTrackingByMonth
