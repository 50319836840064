import { useEffect, useMemo, useState } from 'react'
import { Link, useLoaderData, useParams } from 'react-router-dom'

import {
    Button, Card, IconBatteryCharging,
    IconCertificate,
    IconCurrencyDollarCircle, IconDownload,
    IconEdit,
    IconHome, IconMail,
    IconX,
    Table,
    IconPin, IconGlobe06,
    IconSun, IconBuilding07, IconHelpCircle,
    Tooltip, Feature, CustomSelect, CompanyHeader, CompanyIntegrationsSection, Badge, CompanyAffiliateChangePrompt
} from '@/components'
import { AuthLayout } from '@/containers'
import { useAuth } from '@/hooks'
import { Company, User, CompanyBatteryEligibility } from '@/models'
import { feature, sessionStorage } from '@/services'

const CompaniesShow = () => {
    const { affiliateId, id } = useParams()
    const auth = useAuth()
    const data = useLoaderData() as Company

    const [company, setCompany] = useState<Company>(data)
    const [affiliates, setAffiliates] = useState<Company[]>([])
    const [form, setForm] = useState({
        warranty_match_method: company.warranty_match_method || '',
        conglomerate_id: company.conglomerate?.id || '',
        update_policy: '',
        battery_eligibility: company.batteryEligibility.key
    })

    const breadcrumb = useMemo(() => {
        if (affiliateId) {
            return [
                <IconBuilding07 key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/companies">Companies</Link>,
                <Link key={2} to={`/companies/${affiliateId}`}>Company Details</Link>,
                'Subcontractor Details'
            ]
        }
        return [
            <IconBuilding07 key={1} className="stroke-gray-500"/>,
            <Link key={2} to="/companies">Companies</Link>,
            'Company Details'
        ]
    }, [affiliateId, id])

    const fetchCompany = async () => {
        const res = await Company.show(id as string) as Company
        res.activity = await res.activities()
        setCompany(res)
    }

    const fetchAffiliates = async () => {
        const data = await Company.onlyAffiliates()
        setAffiliates(data)
    }

    useEffect(() => {
        if (auth.user?.isAdminOrStaff) {
            fetchAffiliates()
        }
        setCompany(data)
        setForm({
            warranty_match_method: data.warranty_match_method || '',
            conglomerate_id: data.conglomerate?.id || '',
            update_policy: '',
            battery_eligibility: company.batteryEligibility.key
        })
    }, [id])

    const getShowCompanyCompanyAffiliateChangePrompt = () =>
        company?.conglomerate?.id !== form.conglomerate_id &&
        !!affiliates.find(({ id }) => id === form.conglomerate_id)?.isFullSubcontractorMatch &&
        !form.update_policy

    const handleCompanyUpdate = async (payload: any) => {
        await company.updateSingleField(payload)
        fetchCompany()
    }

    const handleChange = ({ target: { name, value, type, checked } }: any) => {
        setForm((form: any) => {
            const data: any = { [name]: type === 'checkbox' ? checked : value }
            if (name === 'conglomerate_id') {
                const newAffiliate = affiliates.find(({ id }) => id?.toString() === value?.toString())
                if (!newAffiliate?.isFullSubcontractorMatch) {
                    handleCompanyUpdate({ ...data, update_policy: 'future_only' })
                }
            } else {
                if (name === 'update_policy') {
                    data.conglomerate_id = form.conglomerate_id
                }
                handleCompanyUpdate(data)
            }
            return {
                ...form,
                ...data,
                ...name === 'conglomerate_id' ? { update_policy: '' } : {}
            }
        })
    }

    return <AuthLayout
        breadcrumb={breadcrumb}
        heading={<CompanyHeader
            company={company}
            backUrl={(affiliateId ? `/companies/${affiliateId}` : '/companies') + sessionStorage.get('preservedQuery.companies')}
        />}
    >
        {auth.user?.isAdminOrStaffOrAdvisor && <div className="flex justify-between flex-col lg:flex-row mb-8">
            <div className="flex mb-2 lg:mb-0 gap-3 flex-wrap">
                <Feature name="onboarding">
                    <CustomSelect
                        name="create-warranties"
                        id="create-warranties"
                        label="Add New Warranties"
                        className="grow lg:grow-0"
                        options={[
                            { value: '', title: 'Disabled' }
                        ]}
                        value=""
                        size="select-sm"
                        onChange={(e: any) => e}
                    />
                </Feature>
                {company.isAffiliate &&
                    <CustomSelect
                        name="warranty_match_method"
                        id="warranty-match-methods"
                        label="Warranty Match Method"
                        className="grow lg:grow-0"
                        options={Company.warrantyMatchMethods}
                        value={form.warranty_match_method}
                        size="select-sm"
                        onChange={handleChange}
                    />}
                {company.isContractor && <>
                    <CustomSelect
                        name="conglomerate_id"
                        id="conglomerate-id"
                        label="Affiliate"
                        className="grow lg:grow-0"
                        options={[
                            { value: '', title: 'None' },
                            ...affiliates.map(item => ({
                                value: item.id,
                                title: item.name
                            }))
                        ]}
                        value={form.conglomerate_id}
                        size="select-sm"
                        onChange={handleChange}
                        disabled={!auth.user?.isAdminOrStaff}
                    />
                    <CompanyAffiliateChangePrompt
                        open={getShowCompanyCompanyAffiliateChangePrompt()}
                        onChange={handleChange}
                        company={company}
                    />
                </>}
                <CustomSelect
                    name="battery_eligibility"
                    id="battery-eligibility-filter"
                    label="Battery Product Eligibility"
                    className="grow lg:grow-0"
                    disabled={!feature('battery-eligibility')}
                    showChevron={feature('battery-eligibility')}
                    options={[
                        { value: '', title: 'Any Battery Eligibility' },
                        ...CompanyBatteryEligibility.all.map(item => ({
                            value: item.key,
                            title: <div className="flex gap-2 items-center h-5">
                                {item.icon()} {item.title}
                            </div>
                        }))
                    ]}
                    value={form.battery_eligibility}
                    onChange={handleChange}
                    size="select-sm"
                />
            </div>

            <div className="flex justify-between sm:justify-end gap-3">
                <Tooltip content="Edit Company">
                    <Button
                        square
                        disabled={!auth.user?.isAdminOrStaff}
                        href={affiliateId ? `/companies/${affiliateId}/subsidiaries/${id}/edit` : `/companies/${id}/edit`}
                        design="btn-secondary-gray"
                    >
                        <IconEdit/>
                    </Button>
                </Tooltip>
                <Feature name="company-verification">
                    <Tooltip content="Mark as Not Verified">
                        <Button square design="btn-secondary-gray">
                            <IconX/>
                        </Button>
                    </Tooltip>
                </Feature>
                <Tooltip content="Download System Data">
                    <Button square design="btn-secondary-gray" onClick={() => company.downloadSystemData()}>
                        <IconDownload/>
                    </Button>
                </Tooltip>
            </div>
        </div>}

        <div className="grid grid-cols-1 font-light gap-6 mb-6 lg:grid-cols-4">
            <Card className="lg:col-span-2">
                <h2 className="text-lg font-normal mb-4">Company Info</h2>
                <div className="flex flex-col lg:flex-row text-gray-500 text-sm">
                    <div className="lg:w-1/2 flex flex-col items-start">

                        <Tooltip content="Company Name">
                            <div className="flex mb-4">
                                <IconBuilding07 className="stroke-gray-500 mr-2"/>
                                {company.name}
                            </div>
                        </Tooltip>
                        {auth.user?.isAdminOrStaffOrAdvisor && !company.isAffiliate && <Tooltip content="Policy Rate">
                            <div className="flex mb-4">
                                <IconCurrencyDollarCircle className="stroke-gray-500 mr-2"/>
                                {company.policy_rate ? company.policy_rate.money() : '-'}
                            </div>
                        </Tooltip>}

                        <Tooltip content="Salesforce Unique ID">
                            <div className="flex mb-4">
                                <div className="text-gray-500 font-semibold mr-2">ID</div>
                                {company.unique_id || '-'}
                            </div>
                        </Tooltip>

                        <Tooltip content="Monthly Sales Quota">
                            <div className="flex mb-4">
                                <IconHome className="stroke-gray-500 mr-2"/>
                                {company.monthly_sales_quota ? `${company.monthly_sales_quota} warranties/month` : '-'}
                            </div>
                        </Tooltip>

                        {company.isSoftwareAffiliate && <Tooltip
                            content={Company.eligibleCommissionTooltipContent}
                            interactive
                            hideOnClick
                        >
                            <>
                                <div className="flex mb-4">
                                    <IconSun className="stroke-gray-500 mr-2"/>
                                    {company.currentEligibleCommission?.solar_commission_rate?.format(undefined, { minimumFractionDigits: 4 }) || '-'}
                                </div>
                                <div className="flex mb-4">
                                    <IconBatteryCharging className="stroke-gray-500 mr-2"/>
                                    {company.currentEligibleCommission?.battery_commission_rate?.format(undefined, { minimumFractionDigits: 4 }) || '-'}
                                </div>
                            </>
                        </Tooltip>}
                    </div>
                    <div className="lg:w-1/2 flex flex-col items-start">
                        <Tooltip content="Email">
                            <div className="flex mb-4">
                                <IconMail className="stroke-gray-500 mr-2"/>
                                {company.email || '-'}
                            </div>

                        </Tooltip>
                        {auth.user?.isAdminOrStaffOrAdvisor && !company.isAffiliate &&
                            <div className="flex justify-between gap-8 mb-4">
                                <div className="flex gap-4 items-center">
                                    {CompanyBatteryEligibility.battery20.icon()}
                                    {company?.battery_20_year_rate ? company?.battery_20_year_rate.money() : 'N/A'}
                                </div>

                                <div className="flex gap-4 items-center">
                                    {CompanyBatteryEligibility.battery30.icon()}
                                    {company?.battery_30_year_rate ? company?.battery_30_year_rate.money() : 'N/A'}
                                </div>
                            </div>}

                        <Tooltip content="Address">
                            <div className="flex mb-4">
                                <IconPin className="stroke-gray-500 mr-2"/>
                                {company.address || '-'}
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </Card>
            <Card className="lg:col-span-2">
                <h2 className="text-lg mb-4">Activity</h2>
                <div className="text-gray-500 text-sm grid 2xl:grid-cols-2">
                    <Tooltip content="Last Online" className="flex mb-4 justify-self-start">
                        <IconGlobe06 className="stroke-gray-500 mr-2"/>
                        {company.activity.last_online || '-'}
                    </Tooltip>

                    <Tooltip content="Average # of Warranties Registered Per Month" className="flex mb-4 justify-self-start">
                        <IconHome className="stroke-gray-500 mr-2"/>
                        {company.activity.average_warranties_per_month.format()} warranties/month
                    </Tooltip>

                    <Tooltip content="Last Warranty Submission Date" className="flex mb-4 justify-self-start">
                        <IconCertificate className="stroke-gray-500 mr-2"/>
                        {company.activity.last_warranty_submitted_at ? new Date(company.activity.last_warranty_submitted_at).format() : '-'}
                    </Tooltip>

                    <Tooltip content="Lifetime # of Warranties" className="flex mb-4 justify-self-start">
                        <IconSun className="stroke-gray-500 mr-2"/>
                        {company.activity.warranties_registered_lifetime.format()} lifetime warranties
                    </Tooltip>
                </div>
            </Card>
        </div>

        {auth.user?.isAdminOrStaffOrAdvisor && <Card className="mb-4">
            <CompanyIntegrationsSection company={company}/>
        </Card>}

        {company.isAffiliate && <>
            <h2 className="text-lg mb-6">Affiliate Subcontractors</h2>
            <Table
                useQueryParams={false}
                searchable={false}
                className="mb-6"
                columns={[
                    { field: 'name', title: 'Company Name' },
                    { field: 'email', title: 'Main Company Email' },
                    { field: 'last_online', title: 'Last Online' },
                    { field: 'warranties_this_month', title: 'Warranties This Month' },
                    {
                        field: 'all_warranties_count',
                        title: 'Lifetime Warranties'
                    },
                    { field: 'monitoring_status', title: 'Monitoring Status', showIf: feature('monitoring-status') }
                ]}
                rows={company.subsidiaries.map((item: Company) => {
                    item = new Company(item)
                    const activities = company.activity.company_projections?.find(({ id }: any) => item.id === id)
                    return {
                        id: item.id,
                        name: item.name,
                        email: item.email,
                        last_online: activities?.last_online || '-',
                        warranties_this_month: activities?.warranties_registered_this_month?.format(),
                        all_warranties_count: activities?.warranties_registered_lifetime?.format(),
                        status: item.statusBadge,
                        monitoring_status: <div className="w-10 h-10">{item.monitoringStatus.getBadge()}</div>,
                        monitoring_status_raw: item.monitoringStatus.index,
                        _path: `/companies/${id}/subsidiaries/${item.id}`
                    }
                })}
            />
        </>}

        <h2 className="text-lg mb-6">Company Users</h2>
        <Table
            useQueryParams={false}
            className="mb-6"
            searchable={false}
            columns={[
                { field: 'name', title: 'User Name' },
                { field: 'email', title: 'Login  Email' },
                { field: 'role', title: 'Employee Role', showIf: feature('onboarding') },
                {
                    field: 'preferred_features',
                    title: <span className="flex gap-2">
                        Preferred Features
                        <Tooltip content="Preferred features were optionally defined by the <br/> user at Onboarding as frequently used features.">
                            <IconHelpCircle className="stroke-gray-400" size="sm"/>
                        </Tooltip>
                    </span>,
                    showIf: feature('onboarding') && !auth.user?.isAdminOrStaffOrAdvisor
                },
                { field: 'last_online', title: 'Last Online' },
                { field: 'warranties_this_month', title: 'Warranties This Month' },
                { field: 'all_warranties_count', title: 'Lifetime Warranties' },
                { field: 'status', title: 'Status' }
            ]}
            rows={company.users.map((item: User) => {
                const activities = company.activity.user_projections?.find(({ id }: any) => item.id === id)
                return {
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    role: item.role,
                    preferred_features: item.preferredFeaturesBadges,
                    last_online: activities?.last_online || '-',
                    warranties_this_month: activities?.warranties_registered_this_month?.format(),
                    all_warranties_count: activities?.warranties_registered_lifetime?.format(),
                    status: item.deleted_at
                        ? <Badge className="bg-error-100 text-error-700">Disabled</Badge>
                        : <Badge className="bg-success-100 text-success-700">Active</Badge>,
                    _path: affiliateId
                        ? `/companies/${affiliateId}/subsidiaries/${id}/users/${item.id}`
                        : `/companies/${id}/users/${item.id}`
                }
            })}
        />
    </AuthLayout>
}

export default CompaniesShow
