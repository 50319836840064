import { ReactEventHandler, ReactNode } from 'react'

import InputError from './InputError'
import { IconChevronDown } from '@/components'

type OptionProps = {
    value: string | number,
    title: string | number
}
type SelectProps = {
    label?: string
    id: string
    className?: string
    name?: string
    options: Array<OptionProps | string | number>
    value?: string | number
    errors?: string | string[]
    hint?: string
    onChange?: ReactEventHandler
    preIcon?: ReactNode
    postIcon?: ReactNode
    size?: 'select-md' | 'select-sm'
    disabled?: boolean
    readonly?: boolean
    'data-test'?: string
}

const Select = ({
    label,
    id,
    options,
    hint,
    errors = [],
    className = '',
    preIcon,
    postIcon,
    size = 'select-md',
    'data-test': dataTest,
    ...props
}: SelectProps) => {
    const classNames = () => {
        const arr = ['select']
        if (errors.length) arr.push('has-error')
        if (className) arr.push(className)
        if (size) arr.push(size)
        return arr.join(' ')
    }

    dataTest = dataTest || id

    return <div className={classNames()} data-test={dataTest}>
        {label && <label data-test={`${dataTest}-label`} htmlFor={id}>{label}</label>}
        <div className={`select-container ${preIcon ? 'has-pre-icon' : ''} ${postIcon ? 'has-post-icon' : ''}`}>
            {preIcon && <label className="select-icon select-pre-icon" data-test={`${dataTest}-pre-icon`} htmlFor={id}>{preIcon}</label>}
            <select id={id} {...props} data-test={`${dataTest}-select`}>
                {options.map((item: OptionProps | string | number) =>
                    typeof item === 'object'
                        ? <option key={item.value} value={item.value}>{item.title}</option>
                        : <option key={item} value={item}>{item}</option>)}
            </select>
            <IconChevronDown size="lg" className="stroke-gray-500 chevron-icon"/>
            {postIcon && <label className="select-icon select-post-icon" data-test={`${dataTest}-post-icon`} htmlFor={id}>{postIcon}</label>}
        </div>

        {hint && <div data-test={`${dataTest}-hint`} className="text-gray-500 text-sm mt-1.5">{hint}</div>}

        <InputError data-test={`${dataTest}-error`} errors={errors}/>
    </div>
}
export default Select
