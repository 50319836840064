import { useState } from 'react'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'

import { Button, Card, IconCube, PageHeader, ProductForm } from '@/components'
import { AuthLayout } from '@/containers'
import { useFormData, useToastNotifications } from '@/hooks'
import { Product } from '@/models'
import { sessionStorage } from '@/services'

const ProductsEdit = () => {
    const product = useLoaderData() as Product
    const navigate = useNavigate()
    const { success } = useToastNotifications()
    const { convert } = useFormData()

    const [errors, setErrors] = useState<any>({})
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState<any>({
        model_name: product.model_name,
        pdf_template_id: product.pdf_template_id,
        product_type_id: product.product_type_id,
        ...product.properties.reduce((acc: any, item: any) => ({
            ...acc,
            [`properties[${item.property_id}]`]: item.property.data_type === 'Boolean' ? !!parseInt(item.value) : item.value
        }), {})
    })

    const handleChange = ({ target: { name, value, checked, type } }: any) => {
        setForm({ ...form, [name]: type === 'checkbox' ? checked : value })
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        setErrors({})
        try {
            const clone = { ...form }
            delete clone.pdf_template_id
            await product.update(convert(clone))
            success('Product successfully updated')
            navigate('/products')
        } catch (err: any) {
            if (err.errors) setErrors(err.errors)
            else throw err
        } finally {
            setProcessing(false)
        }
    }

    const breadcrumb = [
        <IconCube key={1} className="stroke-gray-500"/>,
        <Link key={2} to="/products">Products</Link>,
        'Edit Product'
    ]
    return <AuthLayout heading={<PageHeader title="Edit Product" backUrl={`/products${sessionStorage.get('preservedQuery.products')}`}/>} breadcrumb={breadcrumb}>
        <Card>
            <form onSubmit={handleSubmit} className="grid lg:grid-cols-2 gap-6">
                <div className="flex flex-col gap-6">
                    <ProductForm
                        form={form}
                        errors={errors}
                        onChange={handleChange}
                    />
                </div>
                <div className="flex justify-end lg:col-span-2 gap-3">
                    <Button design="btn-secondary-gray" hasError href="/products">
                        Cancel
                    </Button>
                    <Button type="submit" processing={processing}>
                        Save
                    </Button>
                </div>
            </form>
        </Card>
    </AuthLayout>
}

export default ProductsEdit
