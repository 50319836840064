import {
    Button,
    Tooltip,
    IconXCircle,
    WarrantyReviewCancellationReinstatementRequestPopup
} from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyDetailsReinstatementCancellationBannerProps = {
    warranty: Warranty
    onChange?: any
}

const WarrantyDetailsReinstatementCancellationBanner = ({
    warranty,
    onChange
}: WarrantyDetailsReinstatementCancellationBannerProps) => {
    const auth = useAuth()

    const getCancellationRequest = () => warranty.pending_cancellation_requests[0]

    const handleCancelRequest = async () => {
        const cancellationRequestId = warranty.pending_cancellation_requests[0]?.id
        await Warranty.revertCancellationRequest(cancellationRequestId)
        if (onChange) onChange()
    }

    const isCancelRequestVisible = () => {
        const requestDate = getCancellationRequest()?.created_at
        return requestDate ? new Date(requestDate) > new Date().subtract('hours', 24) : false
    }

    return <div>
        {warranty.isCancellationRequested && !warranty.deleted_at &&
            <div className="flex w-full justify-between items-center rounded border border-error-200 shadow-md mb-6 py-2 px-4 bg-white">
                <div className="flex items-center">
                    <div className="w-13 h-13 rounded-full bg-error-50 flex justify-center items-center">
                        <div className="w-9 h-9 rounded-full bg-error-100 flex justify-center items-center stroke-error-700">
                            <IconXCircle size="lg"/>
                        </div>
                    </div>
                    <p className="leading-5 ml-4">
                        <span className="font-medium text-black mr-2">
                            A cancellation was requested on {getCancellationRequest()?.created_at
                                ? new Date(getCancellationRequest()?.created_at).format()
                                : null}.
                        </span>
                        {auth.user?.isContractor && <>
                            <br/>
                            <span className="mt-1 text-gray-600 block">
                                A Solar Insure representative will reach out shortly to review the request.
                            </span>
                        </>}
                    </p>
                </div>
                {auth.user?.isAdminOrStaff && <div className="flex gap-2 justify-end">
                    {(getCancellationRequest()?.created_at || warranty.isCancellationRequested) &&
                        !warranty.deleted_at &&
                        <WarrantyReviewCancellationReinstatementRequestPopup warranty={warranty} onChange={onChange}>
                            <Tooltip content="See Details">
                                <Button className="whitespace-nowrap" type="button" design="btn-secondary">See Details</Button>
                            </Tooltip>
                        </WarrantyReviewCancellationReinstatementRequestPopup>}
                </div>}


                {auth.user?.isContractor && <div className="flex gap-2 justify-end">
                    {(getCancellationRequest()?.created_at || warranty.isCancellationRequested) &&
                        isCancelRequestVisible() &&
                        <Tooltip content="Cancel Request">
                            <Button className="whitespace-nowrap" type="button" design="btn-secondary" onClick={handleCancelRequest}>Cancel Request</Button>
                        </Tooltip>}
                </div>}
            </div>}

        {warranty.isReinstatementRequested && warranty.deleted_at &&
            <div className="flex flex-col lg:flex-row w-full justify-between items-center rounded border border-error-200 shadow-md mb-6 p-2 bg-white">
                <div className="flex items-center">
                    <div className="w-13 h-13 rounded-full bg-error-50 flex justify-center items-center">
                        <div className="w-9 h-9 rounded-full bg-error-100 flex justify-center items-center stroke-error-700">
                            <IconXCircle size="lg"/>
                        </div>
                    </div>
                    <p className="font-medium leading-5 sm:leading-3 ml-4">
                        A reinstatement was requested on {new Date(getCancellationRequest()?.created_at).format()}.
                    </p>
                </div>
                {auth.user?.isAdmin && <div className="flex justify-end gap-3 mt-4 lg:mt-0">
                    <WarrantyReviewCancellationReinstatementRequestPopup warranty={warranty} onChange={onChange}>
                        <Tooltip content="Approve Reinstatement Request">
                            <Button size="btn-lg" design="btn-secondary">
                                See Details
                            </Button>
                        </Tooltip>
                    </WarrantyReviewCancellationReinstatementRequestPopup>
                </div>}

                {auth.user?.isContractor && <div className="flex gap-2 justify-end">
                    {(getCancellationRequest()?.created_at || warranty.isCancellationRequested) &&
                        isCancelRequestVisible() &&
                        <Tooltip content="Cancel Request">
                            <Button className="whitespace-nowrap" type="button" design="btn-secondary" onClick={handleCancelRequest}>Cancel Request</Button>
                        </Tooltip>}
                </div>}
            </div>}
    </div>
}

export default WarrantyDetailsReinstatementCancellationBanner
