import {
    Card,
    Tooltip,
    Button,
    IconClose,
    IconCertificate,
    WarrantyCancelPopup,
    WarrantyMarkUniquePopup
} from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyPotentialDuplicateCardsProps = {
    warranty: Warranty
    activeIndex: number
    onChange: any
    onIndexChange: any
}

const WarrantyPotentialDuplicateCards = ({ warranty, activeIndex, ...props }: WarrantyPotentialDuplicateCardsProps) => {
    const auth = useAuth()

    const getActiveDuplicate = () => warranty.all_duplicates[activeIndex]

    return <div className="grid lg:grid-cols-2 gap-6 mb-6">
        <div>
            {warranty.all_duplicates.length > 1 && <span className="text-lg text-lg font-body">Current Warranty</span>}
            <Card className={warranty.all_duplicates.length > 1 ? 'mt-2 h-32' : 'mt-2'}>
                <div className="flex justify-between items-center h-full">
                    {warranty.all_duplicates.length === 1 && <>
                        <div className="p-2 flex gap-2 items-center">
                            <span className="text-lg text-gray-900">Current Warranty</span>
                            <span className="text-primary-700 bg-primary-50 text-xs rounded-full px-2 py-1">{new Date(warranty.created_at).format()}</span>
                        </div>
                    </>}
                    {warranty.all_duplicates.length > 1 && <>
                        <div className="p-2 flex flex-col items-start">
                            <span className="text-xs text-gray-500">Plan ID</span>
                            <span>{warranty.policy_num}</span>
                        </div>
                    </>}
                    {(auth.user?.isAdminOrStaff || auth.user?.isContractor) && <div className="flex justify-end gap-3">
                        <WarrantyCancelPopup warranty={warranty} onChange={props.onChange}>
                            <Tooltip content="Cancel Warranty" disabled={warranty.deleted_at && !auth.user?.isAdmin}>
                                <Button square design="btn-secondary-gray" hasError>
                                    <IconClose/>
                                </Button>
                            </Tooltip>
                        </WarrantyCancelPopup>
                        <WarrantyMarkUniquePopup warranty={warranty} onChange={props.onChange}>
                            <Tooltip content="Mark Warranty as Unique" disabled={warranty.deleted_at && !auth.user?.isAdmin}>
                                <Button square design="btn-secondary-gray" className="flex gap-2">
                                    <IconCertificate/>
                                </Button>
                            </Tooltip>
                        </WarrantyMarkUniquePopup>
                    </div>}
                </div>
            </Card>
        </div>
        <div>
            {warranty.all_duplicates.length > 1 && <span className="text-lg text-lg font-body">Potential Duplicates</span>}
            <Card className={warranty.all_duplicates.length > 1 ? 'mt-2 h-32' : 'mt-2'}>
                <div className="flex justify-between items-center h-full">
                    {warranty.all_duplicates.length === 1 && <>
                        <div className="p-2 flex gap-2 items-center">
                            <span className="text-lg text-gray-900">Potential Duplicate</span>
                            <span className="text-primary-700 bg-primary-50 text-xs rounded-full px-2 py-1">{new Date(warranty.created_at).format()}</span>
                        </div>
                    </>}
                    {warranty.all_duplicates.length > 1 &&
                        <div className="flex gap-2 items-center max-w-[67%] overflow-auto">
                            {warranty.all_duplicates.map((warranty: Warranty, index: number) =>
                                <Button design="btn-link" className={`flex flex-col !p-2 ${activeIndex === index ? 'bg-primary-50' : ''}`} key={index} onClick={() => props.onIndexChange(index)}>
                                    <span className="text-xs text-gray-500 self-baseline">Plan ID</span>
                                    <span className="text-gray-900">{warranty.policy_num}</span>
                                </Button>)}
                        </div>}
                    {(auth.user?.isAdminOrStaff || auth.user?.isContractor) && <div className="flex justify-end gap-3">
                        <WarrantyCancelPopup warranty={getActiveDuplicate()} onChange={props.onChange}>
                            <Tooltip content="Cancel Warranty" disabled={getActiveDuplicate()?.deleted_at && !auth.user?.isAdmin}>
                                <Button square design="btn-secondary-gray" hasError>
                                    <IconClose/>
                                </Button>
                            </Tooltip>
                        </WarrantyCancelPopup>
                        <WarrantyMarkUniquePopup warranty={getActiveDuplicate()} onChange={props.onChange}>
                            <Tooltip content="Mark Warranty as Unique" disabled={getActiveDuplicate()?.deleted_at && !auth.user?.isAdmin}>
                                <Button square design="btn-secondary-gray" className="flex gap-2">
                                    <IconCertificate/>
                                </Button>
                            </Tooltip>
                        </WarrantyMarkUniquePopup>
                    </div>}
                </div>
            </Card>
        </div>
    </div>
}

export default WarrantyPotentialDuplicateCards
