import { ReactNode } from 'react'

type BadgeProps = {
    className?: string,
    children: ReactNode
}

const Badge = ({ className = 'bg-gray-100 text-gray-700', children, ...props }: BadgeProps) =>
    <span className={`badge ${className}`} {...props}>
        {children}
    </span>

export default Badge
