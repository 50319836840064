import { useEffect, useState } from 'react'

import { Button } from '@/components'
import { Warranty } from '@/models'

type WarrantyCreateFinalizeStepProps = {
    warranty?: Warranty | null
}
const WarrantyCreateFinalizeStep = ({ warranty }: WarrantyCreateFinalizeStepProps) => {
    const [pdf, setPdf] = useState<any>(null)

    const fetchPdf = async () => {
        const res = await warranty?.stream()
        setPdf(res)
    }

    useEffect(() => {
        fetchPdf()
    }, [])

    const getPreviewUrl = () => {
        if (!pdf) return ''
        return `${URL.createObjectURL(pdf)}#toolbar=0`
    }

    return <div className="mt-12.5" data-test="warranty-create-step-4">
        <div className="my-8">
            <iframe title="Warranty PDF" src={getPreviewUrl()} className="h-screen" width="100%"/>
        </div>

        <div className="flex justify-end gap-3">
            <Button design="btn-secondary-gray" type="button" onClick={() => warranty?.download()}>
                Download Certificate PDF
            </Button>
            <Button design="btn-secondary-gray" type="button" onClick={() => warranty?.send()}>
                Email Certificate to Homeowner
            </Button>
            <Button type="button" href={`/warranties/${warranty?.id}/warranty`} data-test="warranty-create-step-3-exit-button">
                Exit
            </Button>
        </div>
    </div>
}

export default WarrantyCreateFinalizeStep
