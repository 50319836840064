import { ReactElement } from 'react'

import { IconBatteryCharging, IconSlashCircle01 } from '@/components'
import { IconProps } from '@/components/icons/Icon'

export enum BatteryEligibilityTypeEnum {
    NO_BATTERY = 'no_battery',
    BATTERY_20_YEAR = 'battery_20_year',
    BATTERY_30_YEAR = 'battery_30_year'
}

export const BATTERY_ELIGIBILITY_TYPESS: {
    key: BatteryEligibilityTypeEnum
    title: string
    color: 'gray' | 'purple' | 'success'
    IconComponent: (props: IconProps) => ReactElement
}[] = [
    {
        key: BatteryEligibilityTypeEnum.BATTERY_20_YEAR,
        title: '20-Year Battery',
        color: 'purple',
        IconComponent: IconBatteryCharging
    },
    {
        key: BatteryEligibilityTypeEnum.BATTERY_30_YEAR,
        title: '30-Year Battery',
        color: 'success',
        IconComponent: IconBatteryCharging
    },
    {
        key: BatteryEligibilityTypeEnum.NO_BATTERY,
        title: 'Not Eligible to Sell Battery',
        color: 'gray',
        IconComponent: IconSlashCircle01
    }
]
