import { ReactEventHandler, ReactNode } from 'react'

type SwitchProps = {
    id?: string
    label?: ReactNode
    className?: string
    disabled?: boolean
    checked?: boolean
    defaultChecked?: boolean
    value?: string | number
    name?: string
    onChange?: ReactEventHandler
    size?: 'switch-md' | 'switch-sm'
}
const Switch = ({ label, className, disabled, checked, defaultChecked, size = 'switch-md', ...props }: SwitchProps) =>
    <label className={`switch ${className || ''} ${disabled ? 'disabled' : ''} ${checked || defaultChecked ? 'checked' : ''} ${size}`}>
        <label>
            <input
                type="checkbox"
                disabled={disabled}
                checked={checked}
                defaultChecked={defaultChecked}
                {...props}
            />
        </label>
        {label}
    </label>

export default Switch
