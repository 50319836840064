import handleError from './handleError'
import handleSuccess from './handleSuccess'
import { convert } from '@/hooks/useFormData'
import { cookies } from '@/services'

const BASE_PATH = import.meta.env.VITE_API_URL

export type Params = {
    [key: string]: string | number | boolean | null | undefined | (string | number)[]
}

export type Payload = FormData | {
    [key: string]: any
}

export type Options = RequestInit

export default new class Http {
    request(resource: URL, options: Options) {
        const headers = new Headers({
            Accept: 'application/json',
            Authorization: `Bearer ${cookies.get('api_token')}`,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        })
        if (options.body instanceof FormData) {
            headers.delete('Content-Type')
        }
        return fetch(new Request(resource, {
            headers,
            ...options
        }))
            .then(handleSuccess)
            .catch(handleError)
    }

    getUrl(path: string, params?: Params): URL {
        const uri = path.startsWith('http') ? path : `${BASE_PATH}/${path}`
        const url = new URL(uri)
        if (typeof params !== 'undefined') {
            const convertedParams = convert(params, {})
            Object.keys(convertedParams)
                .forEach((key: string) => {
                    const value = convertedParams[key]
                    if (value || value === false || value === 0) {
                        url.searchParams.append(key, convertedParams[key] as string)
                    }
                })
        }
        return url
    }

    get(path: string, params?: Params, options?: Options) {
        return this.request(this.getUrl(path, params), {
            ...options,
            method: 'GET'
        })
    }

    post(path: string, payload?: Payload, params?: Params, options?: Options) {
        return this.request(this.getUrl(path, params), {
            ...options,
            body: payload instanceof FormData ? payload : JSON.stringify(payload),
            method: 'POST'
        })
    }

    put(path: string, payload?: Payload, params?: Params, options?: Options) {
        return this.request(this.getUrl(path, params), {
            ...options,
            body: payload instanceof FormData ? payload : JSON.stringify(payload),
            method: 'PATCH'
        })
    }

    delete(path: string, params?: Params, options?: Options) {
        return this.request(this.getUrl(path, params), {
            ...options,
            method: 'DELETE'
        })
    }
}()
