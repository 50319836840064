import { Navigate } from 'react-router-dom'

import { toastNotifications } from '@/services'
import store, { setRedirectUrl, logout } from '@/store'

const getFileInfo = (res: any) => {
    const [,fileName] = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(res.headers.get('content-disposition')) || []
    return {
        name: fileName?.replaceAll('"', ''),
        type: res.headers.get('content-disposition')?.split(';')[0]
    }
}

export default async (res: any) => {
    const contentType = res.headers.get('content-type')
    let response = res
    if (contentType?.includes('application/json')) {
        response = await res.json()
    } else if (
        contentType?.includes('text/csv') ||
        contentType?.includes('text/json') ||
        contentType?.includes('application/zip') ||
        contentType?.includes('application/pdf')
    ) {
        response = await res.blob()
        const { type, name } = getFileInfo(res)
        if (type === 'attachment') {
            const a = document.createElement('a')
            a.setAttribute('href', URL.createObjectURL(response))
            a.setAttribute('download', name)
            document.body.appendChild(a)
            a.click()
            a.remove()
        }
    }
    if (res.ok) {
        if (response.data?.message) {
            toastNotifications.success(response.data.message)
        }
        return response
    }
    if (res.status === 401) {
        await store.dispatch(logout())
        if (!location.pathname.includes('login')) {
            store.dispatch(setRedirectUrl(`${location.pathname}${location.search}`))
            return <Navigate to="/login"/>
        }
    }
    throw response
}
