import { ReactNode } from 'react'

import { Image, Logo } from '@/components'
import { InverterManufacturerEnum, INVERTER_MANUFACTURERS } from '@/enums'

export const logoSizes: any = {
    md: 'w-5 h-5',
    lg: 'w-8 h-8'
}

export default class InverterManufacturer {
    key: InverterManufacturerEnum

    logo?: InverterManufacturerEnum

    title: string

    useOptimizers: boolean

    useMicroInverters: boolean

    isSupported: boolean

    isUnsupported: boolean

    isUnknown: boolean

    isAlternative: boolean

    systemIdGuide?: ReactNode

    constructor(manufacturer: {
        key: InverterManufacturerEnum,
        logo?: InverterManufacturerEnum,
        title?: string,
        useOptimizers?: boolean,
        useMicroInverters?: boolean,
        isSupported?: boolean,
        isUnsupported?: boolean,
        isUnknown?: boolean,
        isAlternative?: boolean
        systemIdGuide?: ReactNode
    }) {
        this.key = Object.values(InverterManufacturerEnum).includes(
            manufacturer.key) ? manufacturer.key : InverterManufacturerEnum.OTHER
        this.logo = manufacturer.logo
        this.title = manufacturer.title || ''
        this.useOptimizers = !!manufacturer.useOptimizers
        this.useMicroInverters = !!manufacturer.useMicroInverters
        this.isSupported = !!manufacturer.isSupported
        this.isUnsupported = !!manufacturer.isUnsupported
        this.isUnknown = !!manufacturer.isUnknown
        this.isAlternative = !!manufacturer.isAlternative
        this.systemIdGuide = manufacturer.systemIdGuide
    }

    getLogo(size:'md' | 'lg' = logoSizes.md) {
        return this.logo
            ? <Logo type={this.logo} className={size}/>
            : <Image className="w-6 rounded-full mr-2" src={`https://ui-avatars.com/api/?name=${this.title.replace(/ /g, '')}&color=7F9CF5&background=EBF4FF`}/>
    }

    get isEnphase() {
        return this.key === InverterManufacturerEnum.ENPHASE
    }

    get isSolaredge() {
        return this.key === InverterManufacturerEnum.SOLAREDGE
    }

    get isGenerac() {
        return this.key === InverterManufacturerEnum.GENERAC
    }

    static find(key: InverterManufacturerEnum): InverterManufacturer {
        const manufacturer = INVERTER_MANUFACTURERS.find(item => item.key === key)
        return manufacturer ? new InverterManufacturer(manufacturer) : InverterManufacturer.other
    }

    static get enphase() {
        return InverterManufacturer.find(InverterManufacturerEnum.ENPHASE)
    }

    static get solaredge() {
        return InverterManufacturer.find(InverterManufacturerEnum.SOLAREDGE)
    }

    static get generac() {
        return InverterManufacturer.find(InverterManufacturerEnum.GENERAC)
    }

    static get ambiguous() {
        return InverterManufacturer.find(InverterManufacturerEnum.AMBIGUOUS)
    }

    static get other() {
        return InverterManufacturer.find(InverterManufacturerEnum.OTHER)
    }

    static get all() {
        return INVERTER_MANUFACTURERS.map(item => new InverterManufacturer(item))
    }

    static get allKnown() {
        return InverterManufacturer.all.filter(item => !item.isUnknown)
    }

    static get allUnknown() {
        return InverterManufacturer.all.filter(item => item.isUnknown)
    }

    static get allSupported() {
        return InverterManufacturer.all.filter(item => item.isSupported)
    }

    static get allUnsupported() {
        return InverterManufacturer.all.filter(item => item.isUnsupported)
    }

    getEnphaseUrl(id?: string) {
        return `https://enlighten.enphaseenergy.com/${id ? `systems/${id}` : ''}`
    }

    getSolarEdgeUrl(id?: string) {
        return `https://monitoring.solaredge.com/${id ? `solaredge-web/p/site/${id}` : ''}`
    }

    getGeneracUrl(id?: string) {
        return `https://pwrfleet.generac.com/${id ? `sites/${id}` : ''}`
    }

    getUrl(id?: string) {
        if (this.isEnphase) {
            return this.getEnphaseUrl(id)
        } else if (this.isSolaredge) {
            return this.getSolarEdgeUrl(id)
        } else if (this.isGenerac) {
            return this.getGeneracUrl(id)
        }
        return undefined
    }
}
