import { Input } from '@/components'

type HomeownerFormProps = {
    form: any
    onChange: any
    errors?: any
}
const HomeownerForm = ({ form, errors, ...props }: HomeownerFormProps) =>
    <>
        <Input
            id="first_name"
            name="first_name"
            errors={errors.first_name}
            label="First Name*"
            placeholder="Name"
            onChange={props.onChange}
            value={form.first_name}
        />
        <Input
            id="last_name"
            name="last_name"
            errors={errors.last_name}
            label="Last Name*"
            placeholder="Name"
            onChange={props.onChange}
            value={form.last_name}
        />
        <Input
            id="email"
            name="email"
            type="email"
            errors={errors.email}
            label="Email*"
            placeholder="email@email.com"
            onChange={props.onChange}
            value={form.email}
        />
        <Input
            id="phone"
            name="phone"
            errors={errors.phone}
            label="Phone"
            placeholder="(###) ### - ####"
            onChange={props.onChange}
            value={form.phone}
            mask={{
                numericOnly: true,
                blocks: [0, 3, 3, 4],
                delimiters: ['(', ') ', '-']
            }}
        />
    </>

export default HomeownerForm
