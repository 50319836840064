import { useEffect, useState, useRef } from 'react'

import { Button, IconRefreshCw01, Tooltip } from '@/components'
import { useToastNotifications } from '@/hooks'
import { ToastNotification, Warranty } from '@/models'

type RefreshMonitoringButtonProps = {
    warranty: Warranty
    onChange: () => void
}

const RefreshMonitoringButton = ({ warranty, ...props }: RefreshMonitoringButtonProps) => {
    const { success } = useToastNotifications()
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)
    const [notification, setNotification] = useState<ToastNotification | null>(null)
    const [lastUpdatedDate, setLastUpdatedDate] = useState<Date | null>(null)
    const isMounted = useRef(true)

    const pingWarrantyRefresh = () => {
        const id = setInterval(props.onChange, 5000)
        setIntervalId(id)
    }

    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
            notification?.close()
            if (intervalId) clearInterval(intervalId)
        }
    }, [])

    useEffect(() => () => {
        const newLastUpdatedDate = warranty.homeowner?.monitoring_data?.updated_at
            ? new Date(warranty.homeowner.monitoring_data.updated_at)
            : null

        if (lastUpdatedDate && newLastUpdatedDate &&
            lastUpdatedDate.toISODateTime() !== newLastUpdatedDate.toISODateTime()) {
            if (intervalId) clearInterval(intervalId)
            notification?.close()
            if (isMounted.current) {
                success('System successfully refreshed.')
            }
        }
    }, [warranty])

    const handleRefreshWarranty = async () => {
        const lastUpdatedDate = warranty.homeowner?.monitoring_data?.updated_at
            ? new Date(warranty.homeowner?.monitoring_data?.updated_at)
            : new Date()

        await warranty.refresh()
        const notification = success('Update Queued: Your request for fresh data is being processed. The page will automatically refresh with the latest information shortly.', undefined, 'permanent')
        setNotification(notification)
        props.onChange()

        if (lastUpdatedDate && lastUpdatedDate?.isValid()) {
            pingWarrantyRefresh()
            setLastUpdatedDate(lastUpdatedDate)
        }
    }

    return <Tooltip
        disabled={warranty.refreshAllowed}
        interactive
        content={warranty.refreshAllowed ? '' : <div>
            Refresh Limit Reached: Users are allowed a single daily refresh of system
            data to optimize system performance and manage monitoring expenses. This
            daily quota resets every 24 hours. Should you require additional data updates
            within the same day, please view the most recent data in the system history or
            log into the manufacturer&lsquo;s portal for real-time information. For further
            assistance, contact your account manager or <a href="mailto:monitoring@solarinsure.com">monitoring@solarinsure.com.</a>
        </div>}>
        <Button design="btn-secondary-gray" onClick={handleRefreshWarranty} disabled={!warranty.refreshAllowed} className="gap-2">
            <IconRefreshCw01/> Refresh Monitoring
        </Button>
    </Tooltip>
}

export default RefreshMonitoringButton
