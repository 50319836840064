import { Feature, Input } from '@/components'

type ContactFormProps = {
    form: any
    errors?: any
    onChange: any
}
const ContactForm = ({ form, errors = {}, ...props }: ContactFormProps) =>
    <>
        <Input
            id="name"
            name="name"
            label="Username*"
            placeholder="Username"
            onChange={props.onChange}
            value={form.name}
            errors={errors.name}
        />
        <Input
            id="email"
            name="email"
            type="email"
            label="Email*"
            placeholder="Email"
            onChange={props.onChange}
            value={form.email}
            errors={errors.email}
        />
        <Feature name="onboarding">
            <Input
                id="job_title"
                name="job_title"
                label="Employee Role/Job Title"
                placeholder="Employee Role/Job Title"
                onChange={props.onChange}
                value={form.job_title}
                errors={errors.job_title}
            />
        </Feature>
        <Feature name="onboarding">
            <Input
                id="phone"
                name="phone"
                label="Phone Number"
                placeholder="Phone Number"
                onChange={props.onChange}
                value={form.phone}
                errors={errors.phone}
                mask={{
                    numericOnly: true,
                    blocks: [0, 3, 3, 4],
                    delimiters: ['(', ') ', '-']
                }}
            />
        </Feature>
    </>

export default ContactForm
