import { MouseEventHandler, ReactNode } from 'react'

import Button from './Button'
import { IconAlertCircle, IconAlertTriangle, IconCertificate, IconCheckCircle, IconClose } from '../icons'

type AlertProps = {
    type?: 'custom' | 'info' | 'default' | 'error' | 'warning' | 'purple' | 'success',
    children: ReactNode,
    icon?: ReactNode,
    className?: string,
    onClose?: MouseEventHandler
}

const Alert = ({ type = 'default', children, className, icon, ...props }: AlertProps) => {
    const getIcon = () => {
        if (icon) return icon
        switch (type) {
            case 'warning': {
                return <IconAlertTriangle/>
            }
            case 'success': {
                return <IconCheckCircle/>
            }
            case 'purple': {
                return <IconCertificate/>
            }
            case 'default': {
                return <IconAlertCircle/>
            }
        }
    }

    return <div className={`alert ${type} ${className}`} {...props}>
        {getIcon() && <div className="alert-icon">{getIcon()}</div>}
        {props.onClose && <Button design="btn-link" className="alert-close" onClick={props.onClose}>
            <IconClose size="lg"/>
        </Button>}
        <div className="alert-body">
            {children}
        </div>
    </div>
}

export default Alert
