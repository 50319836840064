import { useEffect, useState } from 'react'

import {
    Button,
    ClickToCopy,
    EditSolarEdgeMaintainerForm,
    IconCopy02,
    IconEye,
    IconEyeOff,
    SolarEdgeForm,
    IntegrationSetupConfirmedBanner
} from '@/components'
import { Company, Integration, InverterManufacturer } from '@/models'

type EditSolarEdgeFormProps = {
    company: Company
    integration?: Integration
    onChange: () => void
    onAdded: () => void
}

const EditSolarEdgeForm = ({ integration, company, ...props }: EditSolarEdgeFormProps) => {
    const [mode, setMode] = useState<'view' | 'edit'>(integration?.isValid || integration?.isUsesMaintainer ? 'view' : 'edit')
    const [processing, setProcessing] = useState(false)
    const [showApiKey, setShowApiKey] = useState(false)
    const [errors, setErrors] = useState<any>({ api_key: integration?.isMissing ? integration.credentialStatus.message : '' })
    const [warnings, setWarnings] = useState<any>({ api_key: integration?.isInvalid ? integration.credentialStatus.message : '' })
    const getForm = () => ({
        company_id: company.id,
        provider: InverterManufacturer.solaredge.key,
        api_key: integration?.api_key || ''
    })
    const [form, setForm] = useState(getForm)

    useEffect(() => {
        setForm(getForm())
    }, [company])

    const handleChange = ({ target: { name, value } }: any) => setForm({ ...form, [name]: value })

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        setErrors({})
        setWarnings({})

        try {
            if (integration) {
                await integration.update(form)
            } else {
                await Integration.store(form)
            }
            await props.onChange()
            props.onAdded()
            setMode('view')
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    return <div className="flex flex-col gap-6">
        {mode === 'edit'
            ? <>
                <h2 className="text-xl font-normal">
                    API Key Setup
                </h2>

                <h4 className="text-sm py-1">Please enter your API key to begin the integration process</h4>

                <form onSubmit={handleSubmit} className="flex gap-6 items-start">
                    <SolarEdgeForm
                        errors={errors}
                        warnings={warnings}
                        form={form}
                        onChange={handleChange}
                        inputClassName="w-100 max-w-full"
                    />

                    <Button processing={processing} className="mt-8">
                        Submit API Key
                    </Button>
                </form>
            </>
            : <div className="flex justify-between">
                <div className="flex flex-col gap-1.5">
                    <h4 className="text-xl font-normal">SolarEdge API Key</h4>
                    <div className="flex gap-1 items-center">
                        {integration?.api_key
                            ? <>
                                {integration?.api_key?.split('')
                                    .map((item: string) => showApiKey ? item : '•')}
                                <button onClick={() => setShowApiKey(state => !state)}>
                                    {showApiKey
                                        ? <IconEyeOff className="stroke-gray-500" size="sm"/>
                                        : <IconEye className="stroke-gray-500" size="sm"/>}
                                </button>
                                <ClickToCopy content={integration?.api_key}>
                                    <IconCopy02 className="stroke-gray-500" size="sm"/>
                                </ClickToCopy>
                            </>
                            : '-'}
                    </div>
                </div>
                {!integration?.isUsesMaintainer && <Button design="btn-link" className="!text-primary-800" onClick={() => setMode('edit')}>
                    Edit Credentials
                </Button>}
            </div>}
        <hr className="lg:col-span-2"/>

        <h2 className="text-xl font-normal">
            SolarEdge Monitoring System Data Integration Setup
        </h2>

        <h4 className="text-sm py-1">
            Set up your fleet account and authorize with your digital signature by following our quick guid
        </h4>

        <EditSolarEdgeMaintainerForm
            company={company}
            integration={integration}
            onChange={props.onChange}
            onModeChange={setMode}
        />
        {integration && <IntegrationSetupConfirmedBanner integration={integration}/>}
    </div>
}

export default EditSolarEdgeForm
