import { Fragment, ReactNode, useEffect, useRef, useState } from 'react'

import InputError from './InputError'
import { Dropdown, IconCheck } from '@/components'

export type OptionProps = {
    value: string | number,
    title: string | number | ReactNode,
    className?: string,
    group?: boolean
    readonly?: boolean
}
type SelectProps = {
    label?: string
    id: string
    className?: string
    name?: string
    options: Array<OptionProps>
    value: string | number
    errors?: string | string[]
    hint?: string
    onChange: any
    preIcon?: ReactNode
    postIcon?: ReactNode
    size?: 'select-md' | 'select-sm'
    disabled?: boolean
    readonly?: boolean
    persistent?: boolean
    selectedOptionOverride?: any
    showChevron?: boolean
    'data-test'?: string
}

const Select = ({
    label,
    id,
    options,
    hint,
    errors = [],
    className = '',
    preIcon,
    disabled,
    postIcon,
    persistent,
    size = 'select-md',
    showChevron = true,
    selectedOptionOverride = (title: any) => title,
    'data-test': dataTest,
    ...props
}: SelectProps) => {
    const dropdownRef = useRef<HTMLUListElement>(null)
    const [activeIndex, setActiveIndex] = useState<number>(0)

    const handleKeyDown = (e: any) => {
        const lastIndex = options.length - 1
        if (e.code === 'ArrowUp') {
            e.preventDefault()
            const index = activeIndex > 0 ? activeIndex - 1 : lastIndex
            if (dropdownRef.current) {
                dropdownRef.current.children[index].scrollIntoView()
            }
            setActiveIndex(index)
        }
        if (e.code === 'ArrowDown') {
            e.preventDefault()
            const index = activeIndex < lastIndex ? activeIndex + 1 : 0
            if (dropdownRef.current) {
                dropdownRef.current.children[index].scrollIntoView({ block: 'end' })
            }
            setActiveIndex(index)
        }
        if (e.code === 'Enter') {
            e.preventDefault()
            props.onChange({ target: { value: options[activeIndex]?.value, name: props.name } })
        }
    }

    const getSelectedOptionIndex = () => options
        .findIndex((item: any) => props.value?.toString() === item.value?.toString() || (!props.value && !item.value))
    const selectedOption = options[getSelectedOptionIndex()]
    useEffect(() => {
        setActiveIndex(getSelectedOptionIndex())
    }, [props.value])

    const classNames = () => {
        const arr = ['custom-select']
        if (errors.length) arr.push('has-error')
        if (className) arr.push(className)
        if (size) arr.push(size)
        if (disabled) arr.push('disabled')
        return arr.join(' ')
    }

    dataTest = dataTest || id

    return <div className={classNames()} data-test={dataTest}>
        {label && <label data-test={`${dataTest}-label`} htmlFor={id}>{label}</label>}
        <div className={`custom-select-container ${preIcon ? 'has-pre-icon' : ''} ${postIcon ? 'has-post-icon' : ''}`}>
            {preIcon && <label className="select-icon select-pre-icon" data-test={`${dataTest}-pre-icon`} htmlFor={id}>{preIcon}</label>}
            <Dropdown
                withChevron={showChevron}
                trigger="click"
                disabled={disabled}
                persistent={persistent}
                button={<button
                    id={id}
                    type="button"
                    disabled={disabled}
                    className={`custom-select-button ${selectedOption?.value === '' ? 'placeholder' : ''}`}
                    onKeyDown={handleKeyDown}
                    data-test={`${dataTest}-select-button`}
                >
                    {selectedOptionOverride(selectedOption?.title) || 'Select an option'}
                </button>}
            >
                <ul ref={dropdownRef}>
                    {options.map((item: OptionProps, index) => <Fragment key={item.value}>
                        {item.group && <li className="w-full px-4 py-2">
                            <hr/>
                        </li>}
                        <li data-test={`${dataTest}-option-${item.value}`} className={item.className}>
                            <button
                                onClick={() => {
                                    if (!item.readonly) {
                                        props.onChange({
                                            target: {
                                                value: item.value,
                                                name: props.name
                                            }
                                        })
                                    }
                                }}
                                name={props.name}
                                type="button"
                                className={`${selectedOption?.value === item.value || index === activeIndex ? 'active' : ''} ${item.readonly ? 'readonly' : ''}`}
                                value={item.value}>
                                {item.title}
                                {selectedOption?.value === item.value &&
                                    <IconCheck className="stroke-primary-700 ml-2"/>}
                            </button>
                        </li>
                    </Fragment>)}
                </ul>
            </Dropdown>
            {postIcon && <label className="select-icon select-post-icon" data-test={`${dataTest}-post-icon`} htmlFor={id}>{postIcon}</label>}
        </div>

        {hint && <div data-test={`${dataTest}-hint`} className="text-gray-500 text-sm mt-1.5">{hint}</div>}

        <InputError data-test={`${dataTest}-error`} errors={errors}/>

        <select className="hidden" name={props.name} onChange={props.onChange} value={props.value} data-test={`${dataTest}-select`}>
            {options.map(item => <option key={item.value} value={item.value}></option>)}
        </select>
    </div>
}
export default Select
