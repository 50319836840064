import { Tooltip } from '@/components'
import { IconProps, IconSize, BadgeSizes } from '@/components/icons/Icon'
import { BATTERY_ELIGIBILITY_TYPESS, BatteryEligibilityTypeEnum } from '@/enums'

const COLOR_MAP = {
    gray: 'bg-gray-100 stroke-gray-600',
    purple: 'bg-purple-100 stroke-purple-600',
    success: 'bg-success-100 stroke-success-600'
}

export class CompanyBatteryEligibility {
    key: BatteryEligibilityTypeEnum

    title: string

    color: 'gray' | 'purple' | 'success'

    IconComponent: (props: IconProps) => JSX.Element

    constructor(props: any) {
        this.key = props.key
        this.title = props.title
        this.color = props.color
        this.IconComponent = props.IconComponent
    }

    static get all() {
        return BATTERY_ELIGIBILITY_TYPESS.map(item => new CompanyBatteryEligibility(item))
    }

    static find(key: BatteryEligibilityTypeEnum) {
        return CompanyBatteryEligibility.all.find(item => item.key === key) ||
            CompanyBatteryEligibility.defaultType as CompanyBatteryEligibility
    }

    static get defaultType() {
        return CompanyBatteryEligibility.all.find(item => item.key === BatteryEligibilityTypeEnum.NO_BATTERY)
    }

    static get noBattery() {
        return CompanyBatteryEligibility.find(BatteryEligibilityTypeEnum.NO_BATTERY) as CompanyBatteryEligibility
    }

    static get battery20() {
        return CompanyBatteryEligibility.find(BatteryEligibilityTypeEnum.BATTERY_20_YEAR) as CompanyBatteryEligibility
    }

    static get battery30() {
        return CompanyBatteryEligibility.find(BatteryEligibilityTypeEnum.BATTERY_30_YEAR) as CompanyBatteryEligibility
    }

    icon(size: IconSize = 'xs') {
        const Icon = this.IconComponent
        return <Tooltip content={this.title}>
            <div className={`rounded-full flex justify-center border border-white items-center ${COLOR_MAP[this.color]} ${BadgeSizes[size]}`}>
                <Icon size={size}/>
            </div>
        </Tooltip>
    }
}
