import { useEffect, useState } from 'react'
import stringSimilarity from 'string-similarity'

import { Button, CustomSelect, Table } from '@/components'
import { Warranty } from '@/models'
import { loadingBar } from '@/services'

type ColumnMappingStepProps = {
    form: any
    onCancel: any
    onNext: any
    onChange: any
}

const defaultColumns = [
    { key: 'company_unique_id', title: 'Company ID', index: 0 },
    { key: 'company_name', title: 'Company', index: 1 },
    { key: 'policy_num', title: 'Policy Number', index: 2 },
    { key: 'first_name', title: 'First Name', index: 3 },
    { key: 'last_name', title: 'Last Name', index: 4 },
    { key: 'street_address', title: 'Street Address', index: 5 }
]

const ColumnMappingStep = ({ form, ...props }: ColumnMappingStepProps) => {
    const [processing, setProcessing] = useState(false)
    const getFileColumns = () => Object.keys(form.data[0]).map((item: string, index) => ({ value: index, title: item }))

    const [columns, setColumns] = useState(defaultColumns.map(item => {
        const res = stringSimilarity
            .findBestMatch(item.title.kebabCase(), getFileColumns().map(item => item.title.kebabCase()))

        return {
            ...item,
            index: res.bestMatch.rating > 0.5 ? res.bestMatchIndex : null
        }
    }))

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        loadingBar.start()
        setProcessing(true)
        try {
            const res = await Warranty.validateMatch(form.payload)
            props.onChange({ target: { name: 'response', value: res.data } })
            props.onNext()
        } finally {
            setProcessing(false)
            loadingBar.stop()
        }
    }

    const handleChange = (e: any) => {
        const clone = columns.map(item => item.key === e.target.name
            ? { ...item, index: e.target.value }
            : item)
        setColumns(clone)
    }

    useEffect(() => {
        const payload = form.data.map((item: any) => ({
            affiliate_id: form.affiliate.id,
            ...columns.reduce((acc, column) => {
                if (column.index === null) return acc
                const key = getFileColumns()[column.index].title
                return { ...acc, [column.key]: item[key] }
            }, {})
        }))
        props.onChange({
            target: {
                name: 'payload',
                value: payload
            }
        })
    }, [columns])

    return <form onSubmit={handleSubmit}>
        <h4 className="text-primary-700 py-4 mb-2">Column Mapping</h4>
        <p className="mb-4 text-sm">Please make sure the column headers match the required fields in the right column.</p>

        <Table
            className="stripped-table"
            searchable={false}
            pagination={false}
            sortable={false}
            useQueryParams={false}
            columns={[
                {
                    title: '#',
                    field: 'number',
                    cellClassName: (row: any) => row.index === null ? 'bg-warning-50' : '',
                    className: 'w-13.5'
                },
                { title: 'Uploaded File Headers', field: 'uploaded_file_headers', cellClassName: 'text-base' },
                { title: 'Solar Insure Headers', field: 'solar_insure_headers', cellClassName: 'text-base' }
            ]}
            rows={columns.map((item: any, index: number) => ({
                id: item.key,
                index: item.index,
                number: index + 1,
                uploaded_file_headers: <CustomSelect
                    className="min-w-[250px]"
                    id={`file-header-${item.key}`}
                    name={item.key}
                    options={getFileColumns()}
                    value={item.index}
                    onChange={handleChange}
                />,
                solar_insure_headers: item.title
            }))}
        />

        <div className="flex justify-end gap-3 mt-4">
            <Button processing={processing} design="btn-secondary-gray" onClick={props.onCancel} type="button">
                Previous
            </Button>

            <Button processing={processing} disabled={columns.some(item => item.index === null)}>
                Next
            </Button>
        </div>
    </form>
}

export default ColumnMappingStep
