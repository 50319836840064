import { useEffect, useState } from 'react'

import ReportBox from './ReportBox'
import { IconCertificate, IconCurrencyDollar, Tooltip } from '@/components'
import { useAuth } from '@/hooks'
import { WarrantyProductType } from '@/models'
import { api, feature } from '@/services'


type ReportBoxesProps = {
    className?: string
    onChange: any
    processing: boolean
}

const ReportBoxes = ({ className, processing, ...props }: ReportBoxesProps) => {
    const auth = useAuth()
    const [warrantiesForMonth, setWarrantiesForMonth] = useState({
        power_production_only: 0,
        production_with_20_year_separate_storage: 0,
        production_with_30_year_separate_storage: 0
    })
    const [salesForMonth, setSalesForMonth] = useState({
        power_production_only: 0,
        production_with_20_year_separate_storage: 0,
        production_with_30_year_separate_storage: 0
    })
    const [salesForYear, setSalesForYear] = useState({
        power_production_only: 0,
        production_with_20_year_separate_storage: 0,
        production_with_30_year_separate_storage: 0
    })

    const [abortController, setAbortController] = useState<AbortController | null>(null)

    useEffect(() => () => {
        abortController?.abort('canceled')
    }, [abortController])

    const fetchWarrantiesForMonth = async (controller: AbortController) => {
        const startDate = new Date().startOf('month').toISODate()
        const endDate = new Date().toISODate()
        const res = await api.dashboard.totalWarrantyCounts({
            start_date: startDate,
            end_date: endDate
        }, { signal: controller.signal })
        setWarrantiesForMonth(res)
    }
    const fetchSalesForMonth = async (controller: AbortController) => {
        const [curMonth] = await api.dashboard.warrantySales({
            start_date: new Date().startOf('month').toISODate(),
            end_date: new Date().toISODate(),
            sortBy: 'month'
        }, { signal: controller.signal })

        setSalesForMonth(curMonth)
    }
    const fetchSalesForYear = async (controller: AbortController) => {
        const [curYear] = await api.dashboard.warrantySales({
            start_date: new Date().startOf('year').toISODate(),
            end_date: new Date().toISODate(),
            sortBy: 'year'
        }, { signal: controller.signal })
        setSalesForYear(curYear)
    }

    const fetchData = async () => {
        try {
            const controller = new AbortController
            await setAbortController(controller)
            await Promise.all([
                fetchWarrantiesForMonth(controller),
                fetchSalesForMonth(controller),
                fetchSalesForYear(controller)
            ])
        } finally {
            props.onChange('reportBoxes')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    const boxes = [
        {
            key: 'sales-this-month',
            icon: <IconCurrencyDollar/>,
            title: 'Sales This Month',
            className: 'col-span-12 lg:col-span-6 xl:col-span-4',
            value: salesForMonth.power_production_only +
                salesForMonth.production_with_20_year_separate_storage +
                salesForMonth.production_with_30_year_separate_storage,
            isMoney: true,
            rows: [
                {
                    key: 'solar',
                    value: salesForMonth.power_production_only.money(),
                    title: '30-Year Solar Only',
                    icon: WarrantyProductType.solar.icon()
                },
                {
                    key: 'battery-20',
                    value: salesForMonth.production_with_20_year_separate_storage.money(),
                    title: '20-Year Battery',
                    icon: WarrantyProductType.battery20.icon()
                },
                ...feature('contractors-30-year-battery') || auth.user?.isAdminOrStaffOrAdvisor ? [{
                    key: 'battery-30',
                    value: salesForMonth.production_with_30_year_separate_storage.money(),
                    title: '30-Year Battery',
                    icon: WarrantyProductType.battery30.icon()
                }] : []
            ]
        },
        {
            key: 'sales-this-year',
            icon: <IconCurrencyDollar/>,
            title: 'Sales This Year',
            className: 'col-span-12 lg:col-span-6 xl:col-span-4',
            value: salesForYear.power_production_only +
                salesForYear.production_with_20_year_separate_storage +
                salesForYear.production_with_30_year_separate_storage,
            isMoney: true,
            rows: [
                {
                    key: 'solar',
                    value: salesForYear.power_production_only.money(),
                    title: '30-Year Solar Only',
                    icon: WarrantyProductType.solar.icon()
                },
                {
                    key: 'battery-20',
                    value: salesForYear.production_with_20_year_separate_storage.money(),
                    title: '20-Year Battery',
                    icon: WarrantyProductType.battery20.icon()
                },
                ...feature('contractors-30-year-battery') || auth.user?.isAdminOrStaffOrAdvisor ? [{
                    key: 'battery-30',
                    value: salesForYear.production_with_30_year_separate_storage.money(),
                    title: '30-Year Battery',
                    icon: WarrantyProductType.battery30.icon()
                }] : []
            ]
        },
        {
            key: 'warranties-this-month',
            icon: <IconCertificate/>,
            title: 'Warranties This Month',
            value: warrantiesForMonth.power_production_only +
                warrantiesForMonth.production_with_20_year_separate_storage +
                warrantiesForMonth.production_with_30_year_separate_storage,
            className: 'col-span-12 xl:col-span-8 flex !flex-row flex-wrap lg:flex-nowrap',
            rows: [
                {
                    key: 'power_production_only',
                    value: warrantiesForMonth.power_production_only,
                    title: '30-Year Solar Only',
                    icon: WarrantyProductType.solar.icon()
                },
                {
                    key: 'production_with_20_year_separate_storage',
                    value: warrantiesForMonth.production_with_20_year_separate_storage,
                    title: '30-Year Solar + 20-Year Battery',
                    icon: <div className="flex">
                        {WarrantyProductType.solar.icon()}
                        {WarrantyProductType.battery20.icon()}
                    </div>
                },
                ...feature('contractors-30-year-battery') || auth.user?.isAdminOrStaffOrAdvisor ? [{
                    key: 'production_with_30_year_separate_storage',
                    value: warrantiesForMonth.production_with_30_year_separate_storage,
                    title: '30-Year Solar + 30-Year Battery',
                    icon: <div className="flex">
                        {WarrantyProductType.solar.icon()}
                        {WarrantyProductType.battery30.icon()}
                    </div>
                }] : []
            ]
        }
    ]

    return <>
        {boxes.map(item =>
            <ReportBox
                key={item.key}
                className={`${className} ${item.className}`}
                icon={item.icon}
                title={item.title}
                value={item.value}
                isMoney={item.isMoney}
                data-test={`dashboard-report-box-${item.key}`}
            >
                <div className="flex flex-col gap-1 text-sm leading-6">
                    {item.rows.map(row => <Tooltip
                        key={row.key}
                        content={`${row.value} ${row.title}`}
                        className="grid grid-cols-4 gap-2 justify-start font-semibold"
                        data-test={`dashboard-report-box-${item.key}-${row.key}-data`}
                    >
                        <div className="flex items-center col-span-1">{row.icon}</div>
                        <div className="col-span-3 truncate">
                            <span
                                data-test={`dashboard-report-box-${item.key}-${row.key}-value`}
                            >{row.value}</span> <span
                                className="font-light"
                                data-test={`dashboard-report-box-${item.key}-${row.key}-title`}
                            >{row.title}</span>
                        </div>
                    </Tooltip>)}
                </div>
            </ReportBox>)}
    </>
}

export default ReportBoxes
