import { toastNotifications } from '@/services'

export default (err: any) => {
    let message = 'Something went wrong!'
    if (err === 'canceled' || err.message?.includes('abort')) {
        // do nothing
    } else {
        if (err.message) {
            ({ message } = err)
        } else if (err.error?.message) {
            ({ message } = err.error)
        } else if (err.errors) {
            if (typeof err.errors === 'string') {
                message = err.errors
            } else if (err.errors?.message) {
                ({ message } = err.errors)
            }
        }
        toastNotifications.error(message, err.status)
    }
    throw err
}
