import { useState } from 'react'

import { Button, Input, Popup } from '@/components'
import { Invoice } from '@/models'

type InvoiceSendPopupProps = {
    isOpen: boolean
    invoice: Invoice
    onClose: any
}

const InvoiceSendPopup = ({ isOpen, invoice, ...props }: InvoiceSendPopupProps) => {
    const [form, setForm] = useState<any>({ email: '' })
    const [errors, setErrors] = useState<any>({})
    const [processing, setProcessing] = useState<boolean>(false)

    const handleChange = (e: any) => setForm({ ...form, [e.target.name]: e.target.value })

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setErrors({})
        setProcessing(true)
        try {
            await invoice.email(form.email)
            props.onClose()
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            }
        } finally {
            setProcessing(false)
        }
    }

    return <Popup
        open={isOpen}
        onClose={props.onClose}
        className="w-96"
    >
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 text-gray-900 mt-10" noValidate>
            <div className="input-form relative">
                <Input
                    id="email"
                    name="email"
                    label="Send invoice to this email"
                    type="email"
                    placeholder="Email"
                    onChange={handleChange}
                    value={form.email}
                    errors={errors.email}
                />
            </div>
            <div className="lg:col-span-2 flex justify-end">
                <Button className="btn align-top w-32 btn-primary mr-2 xl:mr-3" processing={processing}>
                    Send
                </Button>
            </div>
        </form>
    </Popup>
}

export default InvoiceSendPopup
