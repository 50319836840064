type ImageProps = {
    alt?: string,
    src: string,
    src2x?: string,
    src3x?: string,
    className?: string
    pictureClassName?: string
}

const Image = ({ alt, src, src2x, src3x, className, pictureClassName, ...props }: ImageProps) =>
    <picture className={pictureClassName}>
        {src3x && <source srcSet={src3x} media="(min-width: 834px)"/>}
        {src2x && <source srcSet={src2x} media="(min-width: 640px)"/>}
        <source srcSet={src}/>
        <img alt={alt} className={className} {...props}/>
    </picture>

export default Image
